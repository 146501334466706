//** DEFAULT Styles **//
.emp-metricDefault-root {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 25px 15px 25px;
    margin-top: 17px;

    @include theme-breakpoint('up', 960) {
        padding: 33px 25px 20px 25px;
    }

}

.emp-metricDefault-metricWrap {
    & .defaultMetricLink {
        color: #57595E;
    }

    & .chevronIcon {
        width: 36px;
        height: 36px;
    }

    &:hover .chevronIcon {
        transition: all .2s ease-in-out;
        transform: scale(1.15) translateX(4px);
    }
}

.emp-metricDefault-iconTextContainer {
    display: grid;
    grid-template-columns: auto 1fr;
    place-items: top;
}

.emp-metricDefault-icon {
    height: 5rem;
    width: 5rem;
    margin-right: 8px;
}

.emp-metricDefault-content {}

.emp-metricDefault-noPadding {
    flex-basis: 0;
    height: 100%;
    padding: 0 !important;
    margin: 0;
}

.emp-metricDefault-metricTimeFrame {
    color: grey;
    font-size: 10px;
    font-family: Source Sans Pro, sans-serif;
    line-height: 1;
    margin-top: 5px;
}

.emp-metricDefault-metricTitle {
    word-wrap: break-word;
    color: grey;
    font-size: 12px;
    letter-spacing: 0.5px;
    margin-top: 12px;
    font-family: Source Sans Pro, sans-serif;
    margin-bottom: -2px;
    line-height: 1;

    @include theme-breakpoint('up', 1700) {
        font-size: '17px';
    }

}

.emp-metricDefault-metricValue {
    color: #57595F;
    font-size: 38px;
    font-family: Source Sans Pro, sans-serif;
    font-stretch: 100%;
    letter-spacing: -3.025px;
    font-weight: 400;

    @include theme-breakpoint('up', 1700) {
        font-size: 55px;
        margin-bottom: 18px;
    }
}

.emp-metricDefault-altLink {}

.emp-metricDefault-altLinkText {}

.emp-metricDefault-altIcon {}


//** ALT Styles **//
.emp-metricAlt-root {
    min-height: 110px;
    display: grid;
    grid-template-columns: 1fr auto;
    padding: 10px 10px 0px 10px;
    align-items: center;
}

.emp-metricAlt-metricWrap {
    position: relative;
}

.emp-metricAlt-iconTextContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.emp-metricAlt-icon {
    position: absolute;
    top: 0;
    left: -10px;
    color: #fafafa;
    opacity: 0.8;
}

.emp-metricAlt-content {
    min-width: 150px;
    min-height: 65px;
    text-align: right;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
}

.emp-metricAlt-noPadding {
    flex-basis: 0;
    height: 100%;
    padding: 0;
    margin: 0;
}

.emp-metricAlt-metricTimeFrame {
    color: #AFAFAF;
    font-size: 10px;
}

.emp-metricAlt-metricTitle {
    white-space: nowrap;
    color: #666666;
    font-size: 12px;
    letter-spacing: .5px;
    margin-bottom: -4px;
    font-weight: 600;
}

.emp-metricAlt-metricValue {
    color: #666666;
    margin-bottom: theme-spacing(0.8px);
    font-weight: 600;
    font-size: 23px;
}

.emp-metricAlt-altLink {
    display: flex;
    justify-content: space-between;
    padding: 0px 10px 5px 10px;
}

.emp-metricAlt-altLinkText {
    font-size: 12.8px;
    color: #666666;
}

.emp-metricAlt-altIcon {
    font-size: 16px;
    color: #666666;
}