//** Alerts By Card Type Chart **//
#alertsReportAlertsByCardTypeChart {

    & .recharts-pie-sector>g>path {
        filter: drop-shadow(3px 3px 2px rgb(0 0 0 / 0.30));
    }

    & .recharts-pie>g>path {
        filter: drop-shadow(3px 3px 2px rgb(0 0 0 / 0.30));
    }

    .emp-noDataMessaging {
        display: grid;
        place-items: center;
        font-weight: 500;
        font-size: 14px;
    }

    .emp-loadingContainer {
        display: grid;
        place-items: center;
    }

    .emp-tooltipContainer {
        background-color: $white-color;
        border: 0.25px solid #cccc;
        opacity: .9;
        box-shadow:
            0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%), 0 1px 3px 0 rgb(0 0 0 / 20%);
    }

    .emp-tooltipTableRow {
        padding: 0px 4px;
    }

    .emp-valueContainer {
        border: 1px solid #e6e6e6;
        margin: 15px 5px 0px 5px;
        padding: 0px theme-spacing(0.5px);
        text-align: center;
        color: #57595F;
        display: flex;
        flex-wrap: wrap;
        column-gap: theme-spacing(1.5px);
        justify-content: center;
    }
}