.emp-status-valid {
    color: #43a047;

    // &> {
    //     color: #1b5e20;
    // }
}

.emp-status-invalid {
    color: #e53935;

    // &> {
    //     color: #b71c1c;
    // }
}

.emp-status-statusText {
    font-size: larger;
}