//** API Documentation **//
#resourceApiDocumentationView {
    .emp-noApiDocsContainer {
        text-align: center;
        margin-top: 30px;
    }

    .emp-loadingIndicatorContainer {
        text-align: center;
        margin-top: 30px;
    }
}