//** DEFAULT Styles **//
.emp-sidebarNavDefault-root {
    font-size: 14px;
    overflow: hidden;
}

.emp-sidebarNavDefault-item {
    padding: 8px 8px 8px 12px;
    color: #bdbdbd;
    border-bottom: solid 1px #616161;
    text-decoration: none !important;

    &:hover {
        background-color: #3f4143;

        & * {
            color: $white-color !important;
        }
    }
}

.emp-sidebarNavDefault-icon {
    flex: 0;
    color: #bdbdbd;
    font-size: 16px;
    width: 16px;
    margin-right: -22px;
}

.emp-sidebarNavDefault-active {
    background-color: red !important; // important used to keep active state over hover.
    font-weight: 500;

    & * {
        color: $white-color !important;
    }
}

.emp-sidebarNavDefault-merchant {
    width: 100%;
    display: flex;
    padding: 12px 8px 12px 15px;
}

.emp-sidebarNavDefault-nested {
    padding: 0;

    &>a {
        border: none;
        padding-left: theme-spacing(4px);

        &:hover {
            background-color: #333436;
        }
    }
}

.emp-sidebarNavDefault-text {
    color: #bdbdbd;
}

.emp-sidebarNavDefault-sublinkDiv {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: theme-spacing(1px);
}

//** STRIPE Styles **//
.emp-sidebarNavStripe-root {
    font-size: 14px;
    overflow: hidden;
}

.emp-sidebarNavStripe-item {
    padding: 8px 8px 8px 12px;
    color: #bdbdbd;
    border-left: 5px solid #242526;
    border-bottom: solid 1px #616161;
    text-decoration: none !important;

    &:hover {
        background-color: #3f4143;

        & * {
            color: $white-color !important;
        }
    }
}

.emp-sidebarNavStripe-icon {
    flex: 0;
    color: #bdbdbd;
    font-size: 16px;
    width: 16px;
    margin-right: -22px;
}

.emp-sidebarNavStripe-active {
    background-color: red !important; // important used to keep active state over hover.
    font-weight: 500;

    & * {
        color: $white-color !important;
    }
}

.emp-sidebarNavStripe-merchant {
    width: 100%;
    display: flex;
    padding: 12px 8px 12px 15px;
}

.emp-sidebarNavStripe-nested {
    padding: 0;

    &>a {
        border-bottom: none;
        padding-left: theme-spacing(4px);

        &:hover {
            background-color: #333436;
        }
    }
}

.emp-sidebarNavStripe-text {
    color: #bdbdbd;
}

.emp-sidebarNavStripe-sublinkDiv {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: theme-spacing(1px);
}

//** ALT Styles **//
.emp-sidebarNavAlt-root {
    font-size: 14px;
    overflow: hidden;
}

.emp-sidebarNavAlt-item {
    padding: 8px 8px 8px 12px;
    color: #bdbdbd;
    border-bottom: solid 1px #616161;
    text-decoration: none !important;

    &:hover {
        background-color: #3f4143;

        & * {
            color: $white-color !important;
        }
    }
}

.emp-sidebarNavAlt-icon {
    flex: 0;
    color: #bdbdbd;
    font-size: 16px;
    width: 16px;
    margin-right: -22px;
}

.emp-sidebarNavAlt-active {
    background-color: red !important; // important used to keep active state over hover.
    font-weight: 500;

    & * {
        color: $white-color !important;
    }
}

.emp-sidebarNavAlt-merchant {
    width: 100%;
    display: flex;
    padding: 12px 8px 12px 15px;
}

.emp-sidebarNavAlt-nested {
    padding: 0;

    &>a {
        border: none;
        padding-left: theme-spacing(4px);

        &:hover {
            background-color: #333436;
        }
    }
}

.emp-sidebarNavAlt-text {
    color: #bdbdbd;
}

.emp-sidebarNavAlt-sublinkDiv {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: theme-spacing(1px);
}