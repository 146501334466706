//** Merchant Login Activity Grid **//
#monitoringMerchantLoginActivityGrid {
    & .ag-root-wrapper-body {
        height: 325px;
    }

    & .ag-ltr .ag-cell {
        text-align: center;
        border-right: 1px solid #ccc;
    }
}