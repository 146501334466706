//** Main Layout **//
.emp-mainLayout-root {
    height: 100%;
    padding-top: 56px;

    @include theme-breakpoint('up', $lg-screen-size) {
        display: flex;
    }
}

.emp-mainLayout-nav {
    padding: 0;
}