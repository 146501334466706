//** Outcome Cell **//
#alertsOutcomeCell {
    .emp-cell {
        color: $white-color;
        padding: 2px 5px;
        border-radius: 2px;
        text-transform: capitalize;
        font-size: 12px;
        font-weight: 600,
    }

    .emp-default {
        color: none;
    }

    .emp-success {
        background-color: #5cb85c;
    }

    .emp-yellow {
        background-color: #bed400;
    }

    .emp-warning {
        background-color: #f0ad4e;
    }
}