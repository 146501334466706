//** Monitoring **//
#reportsMonitoringView {

    .emp-accentAreaContainer {
        padding: 20px !important;
        padding-top: 40px !important
    }

    // Located in the folllowing files:
    // - CaseReportingByCycleFilters.tsx
    // - CaseSubmissionActivityFilters.tsx
    // - MerchantLoginActivityFilters.tsx
    .emp-searchableText {
        margin: 2px 10px;
    }
}