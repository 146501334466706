//** Template Library AG **//
#templateLibraryNewView {
    .emp-root {
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr;
        gap: theme-spacing(2px);

        @include theme-breakpoint('up', $md-screen-size) {
            grid-template-rows: 1fr;
            grid-template-columns: 1fr 1fr;
        }
    }

    .emp-accentArea {
        padding: theme-spacing(1px) !important;
        padding-top: theme-spacing(4px) !important;
    }

}