.emp-accountSetup-dialogContentContainer {
    display: grid;
    overflow: hidden;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 5px;

    @include theme-breakpoint('up', $md-screen-size) {
        grid-template-columns: repeat(4, 1fr);
    }
}

.emp-accountSetup-dialogContent {
    border: solid 3px #eeeeee;
    width: '100%';
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    color: blue;
    margin-top: theme-spacing(4px);
    margin-bottom: theme-spacing(4px);
    transition: .3s;
    border-radius: 2px;

    &>img {
        width: 150px;
    }

    &:first-child {
        padding: 8px 24px; // Overrides default .MuiDialog-container paddingTop: 20
    }

    &:hover {
        border: solid 3px #a6a6a6;
        transition: .2s;
    }
}

.emp-accountSetup-alert {
    width: 300px;
    margin-top: 24px;
}