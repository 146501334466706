//** Flag Cell **//
#caseMgmtAGFlagCell {
    .emp-actionIcon {
        width: 18px;
        height: 18px;
        margin-bottom: 5px;
    }

    .emp-disabledIcon {
        @include disable-icon;
        cursor: default;
    }
}

//** Assign Cell **//
#caseMgmtAGAssignCell {
    .emp-assigned {
        background: #D9EDF8;
        text-transform: capitalize;
        color: #3d6a91;
        padding: 0px 4px;
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: pointer;
        border-radius: 4px;
        line-height: 1.75;
        margin-top: 3px;
    }

    .emp-disabled {
        background-color: #e3e3e3;
        color: #333;
        cursor: default;
    }

    .emp-disabledIcon {
        @include disable-icon;
        cursor: default;
    }

    .emp-actionIcon {
        width: 18px;
        height: 18px;
        margin: 0;
        margin-bottom: 10px;
        padding: 12px;
    }
}

//** Actions Cell **//
#caseMgmtAGActionsCell {
    display: flex;
    align-items: center;
    justify-content: center;
}