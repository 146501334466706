.emp-addEditMid-root {
    padding: 0px !important;
}

.emp-addEditMid-heading {
    text-transform: capitalize;
    font-size: 14px;
    color: #546e7a;
    font-weight: 600;

}