.emp-dataGridEditable-tableContainer {
    & .MuiTableCell-root {
        padding: 5px;
    }

    width: 100%;
}

.emp-dataGridEditable-tableHeader {
    text-align: left;
    background-color: $white-color;
}

.emp-dataGridEditable-headerRow {
    border-bottom: 1px solid #eeeeee;
}

.emp-dataGridEditable-headerTitle {
    font-weight: 600;
    font-size: 14px;
    color: #000;
}

.emp-dataGridEditable-loadingContainer {
    margin-top: theme-spacing(1px);
    text-align: center;
    width: 100%;
}

.emp-dataGridEditable-valid {
    padding: 5px;
    margin: 0;
    border: 0;
    text-align: center;
}

.emp-dataGridEditable-notValid {
    padding: 5px;
    margin: 0px;
    border-radius: 3px;
    text-align: center;
}

.emp-dataGridEditable-validSelect {
    height: 29px;
    max-width: 150px;

    &.MuiOutlinedInput-root {
        margin: 0 4px;
    }

    border: 1px solid rgba(0, 0, 0, 0.54);
    border-radius: 3px;
    background-color: white;

    & .MuiSelect-select:focus {
        background-color: $white-color;
    }
}

.emp-dataGridEditable-notValidSelect {
    height: 29px;
    max-width: 150px;

    &.MuiOutlinedInput-root {
        margin: 0 4px;
    }

    border-radius: 3px;
    background-color: $white-color;

    & .MuiSelect-select:focus {
        background-color: $white-color;
    }
}

.emp-dataGridEditable-deleteRow {
    background-color: hsla(0, 0%, 80%, 0.1);
    text-decoration: line-through;
    font-style: italic;
}

.emp-dataGridEditable-selectedRow {
    background-color: #42a5f533;
}