.emp-verifiRDRConfig-modalContainer {
    & .MuiDialog-paperFullWidth {
        max-width: 700px;
    }
}

.emp-verifiRDRConfig-subheadingContainer {
    font-weight: 600;
}

.emp-verifiRDRConfig-loaderContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 130px;
}

.emp-verifiRDRConfig-mainContainer {
    padding: theme-spacing(1px);
    margin-top: theme-spacing(1.5px);
    background-color: #f3f5f7;
}

.emp-verifiRDRConfig-addButton {
    text-transform: capitalize;

    &>svg {
        margin-right: theme-spacing(1px);
    }
}

.emp-verifiRDRConfig-conditionsContainer {
    &>div {
        display: grid;
        gap: theme-spacing(0.5px) theme-spacing(1px);
        grid-template-columns: 1fr 1fr 1fr 25px;
        margin-bottom: theme-spacing(0.5px);

        &>div:last-of-type>div:last-of-type {
            display: flex;
            align-items: center;
        }

        &>div>p {
            font-weight: 600;
        }

        & .MuiOutlinedInput-root input {
            padding: 7px;
            background-color: #fff;
        }

        & .maskedInput {
            border: 1px solid rgba(0, 0, 0, 0.23);
            box-sizing: border-box;
            width: 100%;
            line-height: 1.3em;
        }

        @include theme-breakpoint('down', $xs-screen-size) {
            grid-template-columns: 1fr;

            &>div:last-of-type {
                &>div:first-of-type {
                    display: none;
                }

                &>div:last-of-type {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    padding-bottom: theme-spacing(0.5px);
                    border-bottom:
                        1px solid #cfcfcf;
                }
            }
        }
    }

    &>:last-child {
        margin-bottom: theme-spacing(1px);
    }
}