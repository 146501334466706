//** Help **//
#helpView {
    .emp-documents {
        margin-bottom: 30px;
    }

    .emp-link {
        font-size: 14px;
        color: #000;
        text-decoration: underline;

        &:hover {
            color: #c80000 !important;
        }

        &:visited {
            color: #000;
        }
    }

    .emp-icon {
        display: flex;
        align-items: center;
    }

    .emp-marginRight {
        margin-right: 5px;
    }
}
