//** MTD Reports Grid **//
#mtdReportsGrid {

    .emp-picker {
        max-width: 200px;
    }

    .emp-tooltipPopperGrid {
        background: #020202;
        color: $white-color;
    }

    .emp-tooltipPopperArrowGrid {
        &::before {
            background: #020202;
        }
    }

    .emp-advSearch {
        display: flex;
        align-items: center;
        max-width: 100vw;

        & h5 {
            color: #666;
            font-size: 14px;
            font-weight: 600;
        }
    }

    .emp-advSearchBox {
        background-color: #f7f7f7;
        width: 100%;
        padding: 30px 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-end;

        & h5 {
            color: #666;
            font-size: 14px;
            font-weight: 600;
        }

        @include theme-breakpoint('down', $lg-screen-size) {
            justify-content: center;
        }

        @include theme-breakpoint('down', $sm-screen-size) {
            flex-direction: column;
        }
    }

    .emp-advSearchBoxItem {
        display: flex;
        flex-direction: column;
        padding: 0px 3px;
    }

    .emp-advSearchBoxItemTitle {
        margin-bottom: 5px;
        margin-left: 7px;
    }

    .emp-advSearchBoxbtn {
        padding: 10px 10px;
    }

    .emp-advSearchBtn {
        padding: theme-spacing(0.4px) theme-spacing(1px);
        font-weight: 500;
        margin-left: 5px;
    }

    .emp-advSearchIcon {
        margin-right: 6px;
    }

    .emp-clearSearchBtn {
        background-color: #D5D5D5;
        color: #4f4f4f;
        text-transform: capitalize;

        &:hover {
            background-color: #D5D5D5;
        }
    }

    .emp-usdIcon {
        background-color: #757575;
        color: $white-color;
        padding: 3px 6px;
        border-radius: 10%;
        margin-right: 3px;
    }

    .emp-dataGridActions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 35px;
        position: relative;
    }

    .emp-advSearchPaper {
        box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
        position: absolute;
        z-index: 1000;
        top: 40px;
        left: 0;
    }

}