.emp-uploadRepresentments-modalClose {
    position: absolute;
    top: 14px;
    right: 14px;
    cursor: pointer;
}

.emp-uploadRepresentments-uploadIcon {
    margin-right: 5px;
    font-size: 20px;
}

.emp-uploadRepresentments-isQCReviewContainer {
    position: relative;
    display: block;
    width: 100%;
    margin-top: theme-spacing(0.5px);
    padding: theme-spacing(0.5px) theme-spacing(2px);
    font-weight: 600;
    color: #333;
    background-color: #e5f6fd;
    border-radius: 2px;

    & .MuiFormControlLabel-label {
        font-weight: 600;
    }
}

.emp-uploadRepresentments-isQCReviewHelp {
    position: absolute;
    top: -110px;
    left: 15px;
    width: 255px;
    padding: theme-spacing(1px);
    background-color: white;
    border: 1px solid #42a5f5;
    z-index: 999999;
}

.emp-uploadRepresentments-errorContainer {
    width: 100%;
    margin-bottom: theme-spacing(3px);
    padding: theme-spacing(2px);
    border: 1px solid #c00;
    border-radius: 4px;

    & h3 {
        margin: 0 0 theme-spacing(2px);
        font-size: 14px;
    }
}