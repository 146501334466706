@font-face {
    font-family: 'quatro_worldpaymedium';
    src: url('./fonts/quatroworldpay-medium.woff2') format('woff2'),
        url('./fonts/quatroworldpay-medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'quatro_worldpayregular';
    src: url('./fonts/quatroworldpay-regular.woff2') format('woff2'),
        url('./fonts/quatroworldpay-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'quatro_worldpaylight';
    src: url('./fonts/quatroworldpay-light.woff2') format('woff2'),
        url('./fonts/quatroworldpay-light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
