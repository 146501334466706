//** Template Library **//
#templateLibraryView {
    .emp-root {
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr;
        gap: theme-spacing(2px);

        @include theme-breakpoint('up', $md-screen-size) {
            grid-template-rows: 1fr;
            grid-template-columns: 1fr 1fr;
        }
    }

}