//** Topic **//
#helpTopic {
    .emp-well-container {
        min-height: 1.25rem;
        padding: 1rem;
        padding-top: 0;
        background-color: #f5f5f5;
        border: thin solid #e3e3e3;
        border-radius: 0.25rem;
    }

    .emp-video {
        max-width: 100%;
    }

    .emp-typography {
        min-height: 6rem;
    }
}