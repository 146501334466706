.emp-confirmActionModal-dialogHeaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    padding-bottom: 10px;
}

.emp-confirmActionModal-exlamationIcon {
    width: 95px;
    height: 95px;
    border-radius: 50%;
    border: solid .25rem #facea8;
    color: #facea8;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4.75em;
}

.emp-confirmActionModal-dialogTitle {
    margin-top: theme-spacing(1px);
    color: #595959;
    font-size: 2rem;
    text-align: center;
}

.emp-confirmActionModal-dialogTitleSlim {
    @extend .emp-confirmActionModal-dialogTitle;
    font-size: 24px;
    font-weight: 600;
}

.emp-confirmActionModal-dialogContent {
    padding: 20px;
    padding-top: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    row-gap: 20px;
}

.emp-confirmActionModal-dialogContentText {
    text-align: center;
    color: #57595E;
    font-size: 1.15rem;
}

.emp-confirmActionModal-dialogContentTextSlim {
    @extend .emp-confirmActionModal-dialogContentText;
    font-size: 16px;
}

.emp-confirmActionModal-dialogActions {
    padding: 20px;
    padding-top: 10px;
    justify-content: center;
    align-items: center;
    column-gap: theme-spacing(1px);
}