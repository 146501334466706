//** Edit Invalid Chargeback Cell **//
#invalidChargebacksEditInvalidChargebackCell {
    display: flex;
    justify-content: center;
    align-items: center;


    .emp-editBtn {
        padding: 6px 12px;
        display: flex;
        font-size: 0.75rem;
        min-width: 40px;
    }

}

//** Invalid Cell **//
#invalidChargebacksInvalidCell {
    .emp-field {
        color: #f00;

        & .fas {
            margin-left: theme-spacing(.5px);
        }
    }
}