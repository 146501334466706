.emp-uploadOnboarding-modalClose {
    position: absolute;
    top: 14px;
    right: 14px;
    cursor: pointer;
}

.emp-uploadOnboarding-root {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.emp-uploadOnboarding-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.emp-uploadOnboarding-fileDnD {
    width: 100%;
    margin-bottom: theme-spacing(1.5px);
}

.emp-uploadOnboarding-btnContainer {
    display: flex;
    margin-top: 20px;
    justify-content: space-evenly;
    margin-left: theme-spacing(1px);
    margin-right: theme-spacing(1px);
}

.emp-uploadOnboarding-btn {
    text-transform: capitalize;
    margin: 0px 5px;
    text-align: center;
}

.emp-uploadOnboarding-selectContainer {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-left: theme-spacing(1px);
    margin-right: theme-spacing(1px);
}

.emp-uploadOnboarding-switcher {
    margin: 0px 5px;
}

.emp-uploadOnboarding-required {
    margin: 0px 0px 0px 5px;
    font-size: 10px;
}

.emp-uploadOnboarding-uploadIcon {
    margin-right: 5px;
    font-size: 20px;
}

.emp-uploadOnboarding-uploadBtn {
    margin-top: 8px;
}