//** Webhooks **//
#webhooksView {
    .emp-accentAreaContainer {
        padding: 0 !important;
        padding-top: theme-spacing(2px) !important;
        padding-bottom: 0 !important;
    }

    .emp-createWebhookTextBtn {
        margin-left: theme-spacing(1px);
    }

    .emp-alertTokenContainer {
        margin: 20px 0px;
        text-align: center;
    }

    .emp-alertToken {
        color: #545454;
        border: 1px solid #BABFC7;
        padding: 5px 10px;
        border-radius: 4px;
        background-color: #fafafa;
        font-weight: 600,
    }

    .emp-alertCopyTokenContainer {
        border-left: 1px solid;
        margin-left: 10px;
        padding-left: 10px;
    }

    .emp-copyTokenIcon {
        cursor: pointer;

        &:hover {
            color: #BABFC7;
        }
    }
}