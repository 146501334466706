//** Alert Actions **//
.emp-merchantSettings-alertActions-modalContainer {
    & .MuiDialog-paperFullWidth {
        width: 480px;
    }
}

#merchantSettingsAlertActions {
    .emp-messageContainer {
        & .MuiAlert-root {
            font-weight: 600;
            padding: 0px 16px;
            font-family: inherit;
        }
    }

    .emp-loadingContainer {
        text-align: center;
        margin: 40px 0px 20px 0px;
    }

    .emp-errorContainer {
        text-align: center;
        margin: 40px 0px 20px 0px;
        font-weight: 600;
        color: #A94442;
    }


    .emp-alertActionsContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: theme-spacing(2px) 0;

        &>:last-child {
            margin-bottom: 0px;
        }
    }

    .emp-alertContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: theme-spacing(1.5px);

        &>label {
            font-weight: 600;
        }
    }

    .emp-pendingActivationContainer {
        background-color: #fff6e9;
        padding: 2px 4px;
        border-radius: 3px;
    }

    .emp-confirmActionsContainer {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-weight: 600,
    }
}