//** Actions Bar **//
#merchantSettingsActionsBar{
    display: flex;
    column-gap: theme-spacing(0.5px);
    
    .emp-actionBtns {
        min-width: 40px;
        padding: 3px 10px;
        box-shadow: none;
    }
}