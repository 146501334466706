//** Resolve **//
#ertAGResolve {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 14px;

    &>:last-child {
        margin-bottom: 0px !important;
    }

    .emp-inputContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-bottom: theme-spacing(1.5px);

        &>label {
            width: 15%;
            margin-right: theme-spacing(1px);
        }

        &>div {
            width: 75%;
        }

        & .MuiOutlinedInput-root {
            padding: 7px;
        }
    }

    .emp-selectedFile {
        margin-top: theme-spacing(0.5px);
        color: #155C93;
    }

    .emp-fileInputHelpText {
        margin-top: theme-spacing(0.5px);
        font-size: 12px;
        color: #666;
        font-style: italic;
    }
}