.emp-messageModal-dialogTitle {
    padding: theme-spacing(1.5px);
    font-size: 24px;
    font-weight: 600;
    color: #546e7a;
}

.emp-messageModal-modalClose {
    position: absolute;
    top: 7px;
    right: 9px;
    cursor: pointer;
    font-size: 15px;
    color: #546e7a;
}

.emp-messageModal-modalHeader {
    text-align: center;
    margin-top: theme-spacing(1px);
    color: #c80000;
    font-size: 16px;
    font-weight: 700;
}

.emp-messageModal-modalSubHeader {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: #546e7a;
}

.emp-messageModal-modalMessage {
    text-align: center;
    font-size: 14px;
}

.emp-messageModal-dialogContent {
    padding: 20px !important;
    padding-top: 8px !important;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.emp-messageModal-dialogActions {
    padding: 20px;
    padding-top: theme-spacing(1.5px);
    justify-content: center;
    align-items: center;
    column-gap: theme-spacing(1px);
}

.emp-messageModal-circularProgress {
    width: 15px !important;
    height: 15px !important;
    vertical-align: middle;
    color: $white-color;
}