//** Onboarding Grid **//
#onboardingGrid {
    .emp-cssOutlinedInput {
        padding: theme-spacing(1px);
        border-radius: 3px;
        text-align: left;
        outline: none;
        border: solid 1px #a8a8a8;
        color: #545454;

        @include theme-breakpoint('down', $lg-screen-size) {
            margin-bottom: 10px;
        }
    }
}