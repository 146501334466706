//** Case Details **//
#ertAGCaseDetails {

    .emp-modalContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        font-size: 14px;
    }

    .emp-errorLoadingModalContainer {
        padding: theme-spacing(2px);
    }

    .emp-infoContainer {
        width: 100%;
        list-style: none;
        padding: 0;
        margin: 0;

        &>li {
            display: flex;
            justify-content: space-between;
        }
    }
}