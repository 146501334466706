//** Downloads **//
.emp-downloads-accentArea {
    text-align: center;
}

.emp-downloads-mainContainer {
    margin-top: 20px;

    @include theme-breakpoint('down', $md-screen-size) {
        margin-bottom: 20px;
    }
}

.emp-downloads-loadingContainer {
    margin-top: 20px;
}