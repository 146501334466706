//** Dashboard **//
#merchantSettingsView {
    .emp-mainLayoutContainer {
        display: grid;
        grid-template-columns: auto;
        grid-gap: 16px;
        height: 100%;

        @include theme-breakpoint('up', $md-screen-size) {
            grid-template-columns: 375px auto;
        }
    }

    .emp-treeContainer {
        padding: 0 theme-spacing(1px) !important;
        height: 100%;

        @include theme-breakpoint('up', $md-screen-size) {
            padding: 22px 8px 8px 8px !important;
        }
    }

    .emp-leftLayoutContainer {
        display: flex;
        flex-direction: column;
        row-gap: theme-spacing(2px);
    }

    .emp-detailsContainer {
        padding: 0 theme-spacing(1px) !important;

        @include theme-breakpoint('up', $md-screen-size) {
            padding: 20px 8px 0px 8px !important;
        }
    }

    .emp-dataGridContainer {
        padding: 0 theme-spacing(1px) !important;

        @include theme-breakpoint('up', $md-screen-size) {
            padding: 22px 8px 8px 8px !important;
        }
    }

    .emp-tabsHeader {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: theme-spacing(0.5px);
        flex-wrap: wrap;
    }

    .emp-tab {
        text-transform: none;
        font-weight: 600;
        min-width: 70px;

        &:hover {
            opacity: 1;
        }
    }

    .emp-switchArrowBtn {
        float: right;
        min-width: 40px;
        padding: 3px 10px;
        color: #263238;
    }

    .emp-switchArrowIcon {
        margin-right: 5px;
    }

    .emp-gridActionBarContainer {
        margin: 0 theme-spacing(1px);
    }

}