.emp-ert-truncatedContentCheckbox-container {
    font-size: .85rem;
    color: #797979;
    font-weight: 600;
    margin-left: 8px;
    display: flex;
    align-items: center;

    &>label {
        margin-left: 2px;
    }

    &>input[type=checkbox] {
        transform: scale(1.3);
        margin: 4px;
    }
}