//** MID Health Grid **//
#midHealthGrid {
    .emp-searchbarContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 10px;

        @include theme-breakpoint('up', $md-screen-size) {
            flex-direction: row;
            justify-content: space-between;
        }

        & .MuiInputBase-input:focus {
            border-color: #a8a8a8;
            border-radius: 4px;
            z-index: 100;
        }
    }

    .emp-cssOutlinedInput {
        padding: theme-spacing(1px);
        border-radius: 3px;
        text-align: left;
        outline: none;
        border: solid 1px #a8a8a8;
        color: #545454;
    }
}