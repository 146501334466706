//** View File Cell **//
#caseUpdaterAGViewFileCell {
    .emp-fileIconBtn {
        width: 18px;
        height: 18px;
        margin: 0;
        padding: 14px;
    }

    .emp-fileIcon {
        font-size: 18px;
    }
}