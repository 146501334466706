//** Actions Cell **//
#adminReviewAGActionsCell {
    display: flex;
    justify-content: center;
    align-items: center;

    .emp-actionIcon {
        width: 18px;
        height: 18px;

        &:hover {
            cursor: pointer;
        }
    }

    .emp-disabledIcon {
        @include disable-icon;

        &:hover {
            cursor: default;
        }
    }
}


//** Assign Cell **//
#adminReviewAGAssignCell {
    display: flex;
    justify-content: center;
    align-items: baseline;

    .emp-assigned {
        background: #D9EDF8;
        text-transform: capitalize;
        color: #3d6a91;
        padding: 0px 2px;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 130px;
        cursor: pointer;
    }

    .emp-disabled {
        background-color: #e3e3e3;
        color: #333;
        cursor: default;
    }

    .emp-disabledIcon {
        @include disable-icon;
        cursor: default;
    }

    .emp-assignIcon {
        width: 18px;
        height: 18px;
        margin: 0px;
        margin-bottom: theme-spacing(1px);
    }
}
