//** File DnD **//
.emp-fileDnDNew-root {
    background-repeat: no-repeat;
    background-position: center 10px;
    min-height: 300px;
    background-size: 230px;
    display: grid;
    place-items: end center;
    padding: theme-spacing(1px);
    border-style: dashed;
    border-width: 2px;
    border-color: #bdbdbd;
    transition: 0.5s;
    width: 100%;

    & .MuiTypography-body1 {
        color: #A09E9E;
    }

    & .MuiTypography-body1:nth-child(1) {
        font-size: 16px;
    }
}

.emp-fileDnDNew-disabledRoot {
    opacity: 0.5;

    &:hover {
        border-color: #bdbdbd;
        background-color: $white-color;
        background-size: 230px;

        & .MuiTypography-body1 {
            color: #A09E9E;
        }
    }
}

.emp-fileDnDNew-customFileDnDFeatureContainer {
    padding: theme-spacing(1px);
    border-style: dashed;
    border-width: 2px;
    border-color: #bdbdbd;
    min-height: 400px;
}

.emp-fileDnDNew-label {
    text-align: center;
}

.emp-fileDnDNew-fileUploadCriteria {
    margin: 0px;
    font-size: 14px;
}

//** File Upload **//
.emp-fileUploadNew-root {
    display: flex;
    flex-direction: column;
    row-gap: theme-spacing(1px)
}

.emp-fileUploadNew-templateTitle {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: theme-spacing(1px);
}

.emp-fileUploadNew-templateLibSelectContainer {
    display: flex;
    height: max-content;
    align-items: center;

    &>div:first-of-type {
        min-width: 320px;
        width: 100%;
    }

    &>button {
        margin-left: 10px;
    }
}

.emp-fileUploadNew-searchableSelectText {
    margin: 2px 8px;
}

.emp-fileUploadNew-loadingContainer {
    margin: theme-spacing(1px);
    text-align: center;
}

.emp-fileUploadNew-filesContainer {
    display: grid;
    column-gap: theme-spacing(1px);
    row-gap: theme-spacing(.5px);
    max-height: 135px;
    overflow-y: auto
}

.emp-fileUploadNew-fileContainer {
    display: flex;
    justify-content: space-between;
    background-color: #F8F8F8;
    border-radius: 4px;
    align-items: center;
    padding: theme-spacing(.5px) theme-spacing(1px);
}

.emp-fileUploadNew-fileInfoContainer {
    display: flex;
    align-items: center;
}

.emp-fileUploadNew-fileCheckIcon {
    color: #3cb4e5;
    font-size: 25px;
}

.emp-fileUploadNew-fileDetailsContainer {
    margin-left: theme-spacing(1px);

    &>p:first-child {
        font-weight: 600;
        line-height: 1.2;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    &>p:last-child {
        line-height: 1.2;
    }
}

.emp-fileUploadNew-fileUnstageIcon {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    font-size: 18px;
}