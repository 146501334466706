//** Edit Icon Action **//
#usersEditIconAction {
    .emp-disabledIcon {
        @include disable-icon;
    }
}

//** Toggle Lock Icon Action **//
#usersToggleLockIconAction {
    .emp-disabledIcon {
        @include disable-icon;
    }
}

//** Mid Permissions Icon Action **//
#usersMidPermissionsIconAction {
    .emp-disabledIcon {
        @include disable-icon;
    }
}

//** Set Site Permissions Action **//
#usersSitePermissionsIconAction {
    .emp-disabledIcon {
        @include disable-icon;
    }
}

//** Temp Password Icon Action **//
#usersTempPasswordRequestIconAction {
    .emp-disabledIcon {
        @include disable-icon;
    }
}

//** Login As Icon Action **//
#usersLoginAsIconAction {
    .emp-disabledIcon {
        @include disable-icon;
    }

    .emp-signInIcon {
        color: #2a7ea0;
        font-size: 20px;
    }
}