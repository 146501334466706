.emp-topBar-root {
    box-shadow: 0 0 4px #d2d2d2;
    background: $white-color;

    & .MuiToolbar-regular {
        min-height: 64px;
    }

    & .MuiToolbar-gutters {
        padding-left: 0px;
        padding-right: 12px;
    }
}

.emp-topBar-flexGrow {
    flex-grow: 1;
}

.emp-topBar-signOutBtn {
    margin-left: theme-spacing(1px);
    margin-top: theme-spacing(1px);
}

.emp-topBar-cb911Logo {
    max-height: 40px;

    @include theme-breakpoint('down', $lg-screen-size) {
        width: 100%;
        height: 100%;
    }
}

.emp-topBar-menuBarIcon {
    font-size: 25px;
}

.emp-topBar-logoLink {
    text-decoration: none;
    padding-top: 5px;
    padding-left: 17px;
}

.emp-topBar-logoLinkText {
    text-decoration: none;
}

.emp-topBar-menuItem {
    display: flex;
    padding: theme-spacing(1px) theme-spacing(2px);

    &>* {
        flex-grow: 1;
    }
}

.emp-topBar-menuLink {
    padding: theme-spacing(1px);

    & a:link,
    & a:visited {
        text-decoration: none;
    }
}

.emp-topBar-userSettings {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.emp-topBar-userSettingsRow {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.emp-topBar-username {
    font-weight: bold;
}

.emp-topBar-userSettingsLink {
    margin-left: theme-spacing(2px);
    font-size: 12.6px;

    &:hover {
        text-decoration: underline;
    }
}

.emp-topBar-userIcon {
    margin-right: 5px;
    font-size: 13.5px;
}

.emp-topBar-icon {
    display: flex;
    align-items: center;
}

.emp-topBar-pageTitleContainer {
    display: flex;
    align-items: center;
}

.emp-topBar-pageTitleText {
    color: $white-color;
    margin-left: 5px;
}