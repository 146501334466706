.emp-uploadIcon-root {
    cursor: pointer;
}

.emp-uploadIcon-small {
    width: 20px;
    height: 20px;
}

.emp-uploadIcon-medium {
    width: 24px;
    height: 24px;
}

.emp-uploadIcon-large {
    width: 32px;
    height: 32px;
}

.emp-uploadIcon-loadingIcon {
    margin-bottom: 10px;
}

.emp-uploadIcon-tooltip {
    white-space: pre-line;
}