.emp-alertsAutoRefundModal-root {
    margin: 0 0 1.5rem 0;
}

.emp-alertsAutoRefundModal-selectors {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;
}

.emp-alertsAutoRefundModal-badges {
    display: flex;
    padding: 0 0 1.5rem 0;
    margin: 1.5rem 0;
    min-height: 75px;
    border-bottom: solid 1px lightgray;
}

.emp-alertsAutoRefundModal-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.emp-alertsAutoRefundModal-lastScheduledJob {
    color: #777;
}

.emp-alertsAutoRefundModal-badgeRoot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 25px;
    padding: 1rem;
    border: solid 1px rgb(98, 194, 234);
    margin-right: 1.5rem;
}

.emp-alertsAutoRefundModal-badgeText {
    font-size: 16px;
    padding-right: 0.75rem;

    &>b {
        padding-right: 0.25rem;
    }
}

.emp-alertsAutoRefundModal-badgeCloseIcon {
    cursor: pointer;
}