.emp-accentAreaModal-dialogTitle {
    padding: theme-spacing(1.5px);
}

.emp-accentAreaModal-modalTitle {
    font-weight: 600;
    font-size: 20px;
}

.emp-accentAreaModal-dialogContent {
    padding: 20px !important;
}

.emp-accentAreaModal-dialogActions {
    padding: 20px;
    padding-top: 10px;
    justify-content: center;
    align-items: center;
    column-gap: theme-spacing(1px);
}

.emp-accentAreaModal-modalClose {
    position: absolute;
    top: 12px;
    right: 14px;
    cursor: pointer;
    font-size: 15px;
}

.emp-accentAreaModal-circularProgress {
    width: 15px !important;
    height: 15px !important;
    vertical-align: middle;
    color: #FFFFFF;
}