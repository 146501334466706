.emp-dataGridSlim-resizing {
    overflow-y: hidden;

    &>.table-outer-container {
        opacity: 0;
    }

    &>.resize-indicator {
        display: block;
    }
}

.emp-dataGridSlim-tableContainer {
    @include theme-breakpoint('up', $md-screen-size) {
        box-shadow: none;
    }

    & .MuiTableCell-root {
        padding: 6px 7px;
        border-bottom: none;
    }

    &._hideArrowAndCBColumns table {

        & thead>tr,
        & tbody>tr {

            &>th:nth-child(-n+2),
            &>td:nth-child(-n+2):not([colspan]) {
                display: none !important;
            }
        }
    }
}

.emp-dataGridSlim-expandableOverflow {
    overflow: initial
}

.emp-dataGridSlim-loadingContainer {
    margin-top: theme-spacing(1px);
    text-align: center;
    width: 100%;
}

.emp-dataGridSlim-loadingContainerFixed {
    position: fixed;
    margin-top: theme-spacing(1px);
    text-align: center;
    width: calc(100vw - 60px);

    @include theme-breakpoint('up', $lg-screen-size) {
        width: calc(100vw - 300px);
    }
}

.emp-dataGridSlim-stickyHeaders {
    & th {
        position: sticky;
        top: 60px;
        background-color: $white-color;
        z-index: 999;
    }
}

.emp-dataGridSlim-tableHeader {
    text-align: center;
    background-color: $white-color;
}

.emp-dataGridSlim-tableHeaderCell {
    padding: theme-spacing(1px);
    white-space: nowrap;
    color: $black-color;
    text-align: center;

    &:hover {
        cursor: pointer;
    }
}

.emp-dataGridSlim-tableHeaderCellWidth {
    width: 40px;
}

.emp-dataGridSlim-tooltip-popper {
    background: #020202;
    color: $white-color;
    font-size: 12px;
}

.emp-dataGridSlim-tooltipPopperArrow {
    &::before {
        background: #020202;
    }
}

.emp-dataGridSlim-resizeIndicator {
    display: none;
    min-height: 100vh;
    text-align: center;
    padding-top: 55px;
}

.emp-dataGridSlim-headerRow {
    border-bottom: 1px solid #eeeeee;
    z-index: 99;
}