.emp-csvMidUpload-root {
    display: flex;
    align-items: center;
    justify-content: center;
}

.emp-csvMidUpload-csvBtn {
    margin-right: theme-spacing(1px);
    text-transform: none;
    color: $white-color;
    background: #777777;
    font-weight: bold;
}

.emp-csvMidUpload-downloadTemplateLink {
    margin-right: theme-spacing(2px);
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}

.emp-csvMidUpload-fileInput {
    display: none;
}