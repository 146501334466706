.emp-midPermissions-permissionsWrapper {
    display: flex;
    flex-direction: column;
}

.emp-midPermissions-autocomplete {
    margin-top: theme-spacing(2px);
    margin-bottom: theme-spacing(2px);
}

.emp-midPermissions-btnGrp {
    display: flex;
    justify-content: flex-start;

    &>button {
        margin-right: theme-spacing(1px);
    }
}

.emp-midPermissions-btn {
    text-transform: none;
}

.emp-midPermissions-contentTextWrap {
    text-align: left;
    margin-top: theme-spacing(1px);
    margin-bottom: theme-spacing(1px);
}