//** new Alerts By Date Chart **//
#alertsReportNewAlertsByDateChart {
    .emp-root {
        & .recharts-layer>.recharts-layer>path:nth-child(2) {
            filter: drop-shadow(5px -1px 2px rgb(0 0 0 / 0.30));
        }

        & .recharts-area-dots {
            filter: drop-shadow(5px -1px 2px rgb(0 0 0 / 0.30));
        }
    }

    .emp-loadingContainer {
        display: grid;
        place-items: center;
    }

    .emp-tooltipContainer {
        background-color: $white-color;
        border: 0.25px solid #cccc;
        opacity: .9;
        box-shadow:
            0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%), 0 1px 3px 0 rgb(0 0 0 / 20%);
    }

    .emp-tooltipTableHeader {
        font-weight: 700;
        text-align: left;
        color: white;
        padding: 0px 4px;
    }

    .emp-tooltipTableRow {
        border-top: 1px solid #b3b3b3;
        padding: 0px 4px;
    }

    .emp-renderLegendContainer {
        display: flex;
        justify-content: center;
        flex-direction: column;
        border: 1px solid #e6e6e6;
        width: 143px;
        position: absolute;
    }
}