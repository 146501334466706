//** alerts **//
#alertsView, #preChargebacksView {
.emp-headerContent {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    @include theme-breakpoint('up', $md-screen-size) {
        flex-direction: row;
        justify-content: space-between;
    }
}

.emp-divider {
    margin-top: 10px;
    margin-bottom: 10px;
}}