.emp-searchableSelect-paginationContainer {
    & .MuiTablePagination-root {
        overflow-x: hidden;

        &>.MuiToolbar-root {
            justify-content: center;
            flex-wrap: wrap;
        }
    }

    & .MuiTablePagination-toolbar {
        padding: 0;

        // Hide "Rows per page:" caption.
        &>p:first-of-type {
            display: none;
        }

        &>.MuiTablePagination-selectRoot {
            margin-right: theme-spacing(0.5px);
        }

        & .MuiTablePagination-actions {
            margin-left: theme-spacing(0.5px);
            margin-right: theme-spacing(1px);
        }

        & .MuiIconButton-root {
            padding: 3px;
        }
    }
}

.emp-searchableSelect-loadingContainer {
    padding-left: 12px;
    margin: 10px 0px;
}

.emp-searchableSelect-loading {
    min-width: 150px;
    font-size: 2rem;
}