.emp-inputCaseBuilderDetailsStep1-sectionOneContainer {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;

    @include theme-breakpoint('up', $sm-screen-size) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.emp-inputCaseBuilderDetailsStep1-qualityNotesContainer {
    display: flex;
    align-items: center;
}

.emp-inputCaseBuilderDetailsStep1-sectionTwoContainer {
    margin-top: 20px;
}

.emp-inputCaseBuilderDetailsStep1-salesMethodBtnsContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    margin-top: 10px;

    @include theme-breakpoint('up', $sm-screen-size) {
        grid-template-columns: repeat(3, 1fr);
    }

    @include theme-breakpoint('up', $md-screen-size) {
        grid-template-columns: repeat(5, 1fr);
    }
}

.emp-inputCaseBuilderDetailsStep1-salesMethodBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
    text-align: center;
    border-radius: 5px;
    font-size: 14px;
}

.emp-inputCaseBuilderDetailsStep1-salesMethodIcon {
    font-size: 25px;
    margin-right: 15px;
}

.emp-inputCaseBuilderDetailsStep1-sectionThreeContainer {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
    margin-top: 20px;

    @include theme-breakpoint('up', $md-screen-size) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.emp-inputCaseBuilderDetailsStep1-transactionInfoContainer {
    margin-top: 10px;
}

.emp-inputCaseBuilderDetailsStep1-transactionQuestionContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.emp-inputCaseBuilderDetailsStep1-headerTypography {
    font-weight: bold;
}

.emp-inputCaseBuilderDetailsStep1-divider {
    margin: 5px 0px;
    height: 2px;
}

.emp-inputCaseBuilderDetailsStep1-alertTypography {
    color: #B91200;
}

.emp-inputCaseBuilderDetailsStep1-dueDateTypography {
    color: #B91200;
    text-decoration: underline;
}

.emp-inputCaseBuilderDetailsStep1-productContainer {
    border: 1px solid #a6a6a6;
    border-radius: 4px;
    flex: auto;
    padding: 10px;
    padding-top: 0;
    min-height: 275px;
}

.emp-inputCaseBuilderDetailsStep1-fieldContainer {
    & .MuiOutlinedInput-inputMarginDense {
        padding-left: 7px;
        padding-right: 7px;
    }

    & .MuiOutlinedInput-multiline {
        padding-left: 0px;
        padding-right: 0px;
    }

    & .MuiInputBase-input {
        padding: 0px;
    }

    & .MuiInputBase-inputMultiline {
        padding: 0px;
    }

    & .MuiOutlinedInput-root {
        padding: 8.5px 7px;
    }
}

.emp-inputCaseBuilderDetailsStep1-selectFieldContainer {
    height: 52px;
}

.emp-inputCaseBuilderDetailsStep1-exclamationCircleIcon {
    margin-right: theme-spacing(1.5px);
    color: #B91200;
    font-size: 25px;
}

.emp-inputCaseBuilderDetailsStep1-searchableSelectText {
    margin: 2px 8px;
}