.spaced {
    margin: auto;
    text-align: center;
    padding: 1rem;
}

.spaced-internal {
    text-align: left;
}

.t-header {
    padding-top: 0.54rem;
}

.bp3-card p,
.bp3-card h1 {
    color: rgb(10, 10, 10);
}

.centered {
    text-align: center;
    margin: auto;
}

.thick {
    margin: 1rem 0;
}
/* view link styling */
a,
a:visited {
    color: #098fe2;
    text-decoration: none;
}

/* a:link:hover,
a:visited:hover {
    text-decoration: underline;
} */

a.accented {
    color: #098fe2;
    text-decoration: none;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: relative;
    transition: 0.5s color ease;
}

a.accented:after {
    left: 0;
    bottom: -0.25em;
    content: '';
    transition: 0.5s all ease;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
}
