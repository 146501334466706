.emp-paginationAGGrid-root {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    background-color: $white-color;

    @include theme-breakpoint('down', 800) {
        flex-wrap: wrap;
    }
}

.emp-paginationAGGrid-rootBasicTheme {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: theme-spacing(.5px);
    row-gap: theme-spacing(.5px);
    width: 100%;
    height: 50px;
    margin: 0;
    background-color: $white-color;
}

.emp-paginationAGGrid-paginationBorder {
    border: 1px solid #BABFC7;
    border-top: none;
}

.emp-paginationAGGrid-pageInfoContainer {
    display: flex;
    align-items: center;
    padding: 0px 6px;
}

.emp-paginationAGGrid-pageInfoBasicThemeContainer {
    display: flex;
    align-items: center;
}

.emp-paginationAGGrid-pageResultsDisplay {
    padding: 0px 18px;
    display: flex;
    align-items: center;
}

.emp-paginationAGGrid-pageResultsBasicThemeDisplay {
    padding-left: 20px;
    display: flex;
    align-items: center;
}

.emp-paginationAGGrid-pageSizeDisplay {
    display: flex;
    align-items: center;
    padding: 0px 18px;

    &>select {
        width: 53px;
        margin-left: 4px;
        padding: 4px;
        border: 1px solid #dcdcdc;
        text-align: center;
        margin-top: 2px;
    }
}

.emp-paginationAGGrid-pageResultsTextWithoutDisplayingText {
    margin: 0px 4px 0px 0px;
    font-weight: 700;
}

.emp-paginationAGGrid-pageResultsText {
    margin: 0px 4px;
    font-weight: 700;
}

.emp-paginationAGGrid-pageResultsTextLast {
    margin-left: 4px;
    font-weight: 700;
}

.emp-paginationAGGrid-pageNavContainer {
    padding: 0px 18px;
    display: flex;
    align-items: center;
}

.emp-paginationAGGrid-pageNavBasicThemeContainer {
    padding-right: 18px;
    display: flex;
    align-items: center;
}

.emp-paginationAGGrid-pageNavBackwardControls {
    display: flex;
    align-items: center;
    padding-right: 4px;
    border-right: 1px solid #eeeeee;
}

.emp-paginationAGGrid-pageNavDisplayContainer {
    display: flex;
    align-items: center;
    padding: 0px 10px;
}

.emp-paginationAGGrid-pageNavForwardControls {
    display: flex;
    align-items: center;
    padding: 0px 4px;
    border-left: 1px solid #eeeeee;
    border-right: 1px solid #eeeeee;
}

.emp-paginationAGGrid-pageNavForwardControlsNoRefreshBtn {
    padding: 0px 0px 0px 4px;
    border-right: none;
}

.emp-paginationAGGrid-pageNavRefreshContainer {
    padding-left: 4px;
}

.emp-paginationAGGrid-iconActive {
    font-size: 16px;
    margin: 0px 6px;

    &:hover {
        cursor: pointer;
    }
}

.emp-paginationAGGrid-iconInactive {
    font-size: 16px;
    margin: 0px 6px;
    opacity: 0.5;
}

.emp-paginationAGGrid-tooltipContainer {
    margin-top: 2px;
}

.emp-paginationAGGrid-pageInput {
    font-weight: 700;
    margin: 0px 6px;
    min-width: 25px;
    max-width: 100px;
    text-align: center;
    padding: 4px;
    border: 1px solid #dcdcdc;
}

.emp-paginationAGGrid-boldText {
    font-weight: 700;
}

.emp-paginationAGGrid-paginationAlertContainer {
    margin: theme-spacing(1px);

    & .MuiAlert-root {
        padding: 0px 4px 0px 16px;
    }

    @include theme-breakpoint('up', 1460) {
        & .MuiAlert-root {
            padding: 0px 16px;
        }
    }
}