.emp-updateUser-root {
    margin-top: -10px;
}

.emp-updateUser-spaced {
    margin-top: theme-spacing(2px);
}

.emp-updateUser-textField {
    background-color: $white-color;
}

.emp-updateUser-searchableText {
    margin: 2px 10px;
    font-size: 14px;
}

.emp-updateUser-inputs {
    height: 50px;
}

.emp-updateUser-btnsContainer {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(5, 1fr);
    padding-top: 15px;
}

.emp-updateUser-circularProgress {
    width: 15px !important;
    height: 15px !important;
    vertical-align: middle;
    color: #FFFFFF;
}