.emp-lamp-small {
    border-radius: 4px;
    height: 8px;
    width: 8px;
    min-width: 8px;
    display: block;
}

.emp-lamp-medium {
    border-radius: 8px;
    height: 12px;
    width: 12px;
    min-width: 12px;
    display: block;
}

.emp-lamp-large {
    border-radius: 8px;
    height: 16px;
    width: 16px;
    min-width: 16px;
    display: block;
}