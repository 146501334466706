.emp-compellingEvidenceLauncherNew-root {}

.emp-compellingEvidenceLauncherNew-title {
    font-size: 16px;
    font-weight: 700;
}

.emp-compellingEvidenceLauncherNew-paragraphContainer {
    margin: theme-spacing(1px) 0 theme-spacing(2px) 0;
}

.emp-compellingEvidenceLauncherNew-paragraph {
    margin: theme-spacing(1px) 0;
    text-align: left;
}