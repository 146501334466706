.emp-assignUser-assignInput {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: theme-spacing(2px);
    margin-bottom: theme-spacing(2px);

    &>label {
        margin-right: theme-spacing(1px);
    }
}

.emp-assignUser-selectInput {
    width: 65%;
}

.emp-assignUser-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 400px;
    height: 125px;
}

.emp-assignUser-headerBanner {
    background: #E0E0E0;
    display: flex;
    justify-content: space-between;
    padding: 12px 18px;
    font-size: 1.2rem;
    color: #505050;
}

.emp-assignUser-btnGrp {
    display: flex;
    justify-content: center;
    margin-bottom: theme-spacing(1px),
}

.emp-assignUser-btn {
    border-radius: 3px;
    padding: 4px;
    text-transform: capitalize;
    margin: 4px;
}

.emp-assignUser-circularProgress {
    width: 15px !important;
    height: 15px !important;
    vertical-align: middle;
    color: $white-color;
}