//** Flag Case **//
#caseMgmtFlagCase {
    .emp-title {
        font-size: 40px;
        font-weight: 400;
        padding: 0;
        margin: 0;
    }

    .emp-subtitle {
        font-weight: 400;
        margin: 0 0 1.5rem 0
    }

    .emp-content {
        width: 100%;
        display: grid;
        text-align: center;
        padding: 20px 24px 8px;
    }

    .emp-actions {
        display: grid;
        grid-auto-flow: column;
        place-items: center;
        margin: 1.5rem 30%;
        gap: 1.5rem;
    }
}