//** merchantInfo **//
#merchantSettingsMerchantInfo {
    .emp-infoGridContainer {
        gap: 15px;

        &>div {
            gap: theme-spacing(.25px);
            flex-wrap: nowrap;
        }
    }

    .emp-merchantLabel {
        font-weight: 600;
    }

    .emp-value {
        padding: 0px !important;
    }

    .emp-loadingContainer {
        min-height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .emp-lampContainer {
        display: flex;
        align-items: center;
        column-gap: theme-spacing(1px);
    }
}