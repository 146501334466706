.emp-visaCEUndisputedTransactionDetails-transactionDetailsRoot {
    padding-bottom: theme-spacing(1px);
    margin-bottom: theme-spacing(1px);
    border-bottom: 1px solid #ccc;
}

.emp-visaCEUndisputedTransactionDetails-transactionDetailsGridContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    font-size: 11px;

    @include theme-breakpoint('up', $md-screen-size) {
        flex-direction: row;

        & > div {
            padding: 0 0 theme-spacing(.5px);
        }
    }

    & > div {
        width: 100%;
        padding: 0 theme-spacing(1px) theme-spacing(.5px);

        & > div:first-of-type {
            font-weight: 700;
        }
    }

    &.top {
        > div:first-of-type,
        > div:last-of-type {
            padding-top: theme-spacing(.5px);
            width: 80px;

            > div:first-of-type {
                display: none;
            }
        }
    }

    &.detail {
        @include theme-breakpoint('up', $md-screen-size) {
            padding-left: 38px;
        }
    }
}
