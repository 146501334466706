//** Actions Cell **//
#alertsActionsCell {

    .emp-root {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .emp-actionIcon {
        width: 24px;
        height: 24px;
    }

    .emp-cell {
        color: $white-color;
        padding: 2px 5px;
        border-radius: 2px;
        text-transform: capitalize;
        font-size: 12px;
        font-weight: 600;
        background-color: #5bc0de;
    }

    .emp-disabledIcon {
        @include disable-icon;
    }
}