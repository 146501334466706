//** Merchant Hierarchy **//
#merchantHierarchyView {
    .emp-editMerchantBtn {
        background-color: #777777;
        text-transform: capitalize;
        color: $white-color;

        &:hover {
            background-color: #D5D5D5;
        }
    }

    .emp-merchantTree {
        padding: 0;
        list-style: none;

        &>:first-child {
            border-top-right-radius: 0.25rem;
            border-top-left-radius: 0.25rem;
        }

        &>:last-child {
            border-bottom-right-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem;
        }
    }

    .emp-reloadBtn {
        margin-top: theme-spacing(0.5px);
    }

    .emp-searchIcon {
        color: #4C505B;
        margin-left: 5px;
    }

    .emp-searchTextField {
        & .MuiInput-underline:before {
            border-bottom: 1px solid #84878E;
        }
    }

    .emp-searchContainer {
        display: flex
    }

    .emp-selectSearchContainer {

        & .MuiOutlinedInput-input {
            padding: 8.5px;
            padding-right: 32px
        }
    }

    .emp-selectSearchItem {
        padding: 0 0.5rem;
    }

    .emp-switchArrowBtn {
        float: right;
        min-width: 40px;
        padding: 3px 10px;
        color: #263238;
    }

    .emp-switchArrowIcon {
        margin-right: 5px;
    }
}