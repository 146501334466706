//** Outcome Cell **//
#alertsAGOutcomeCell {
    display: flex;
    justify-content: center;

    .emp-default {
        color: none;
    }

    .emp-container {
        color: $white-color;
        padding: 2px 5px;
        border-radius: 2px;
        text-transform: capitalize;
        font-size: 12px;
        font-weight: 600;
    }

    .emp-success {
        background-color: #5cb85c;
    }

    .emp-yellow {
        background-color: #bed400;
    }

    .emp-warning {
        background-color: #f0ad4e;
    }

}