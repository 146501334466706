//** Chargebacks By Card Type **//
#dashboardChargebacksByCardType {

    .emp-root {
        height: 275px;

        @include theme-breakpoint('down', $lg-screen-size) {
            height: 250px;
        }
    }

    .emp-loading {
        display: grid;
        place-items: center;
        height: 275px;
    }
}