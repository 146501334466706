.emp-dataDropdownRows-tableBodyCell {
    white-space: nowrap;
    font-weight: 600;
    text-align: right;

    &>p {
        padding: 0;
        margin: 0;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    border-top: 1px solid #eeeeee;
}