//** Case Managementgmt AG **//
#caseManagementView {
    .emp-filterContainer {
        display: flex;
        justify-content: flex-start;
        column-gap: theme-spacing(.5px);
        row-gap: theme-spacing(1px);
        margin-bottom: theme-spacing(1px);
        flex-wrap: wrap;
    }

    .emp-accentAreaContainer {
        padding: theme-spacing(1px) !important;

        @include theme-breakpoint('up', $md-screen-size) {
            padding: theme-spacing(1px) !important;
            padding-top: theme-spacing(4px) !important;
        }
    }

    .emp-cssOutlinedInput {
        padding: theme-spacing(1px);
        border-radius: 3px;
        text-align: left;
        outline: none;
        border: solid 1px #a8a8a8;
        color: #545454;
    }

    .emp-advFilterContent {
        margin-bottom: theme-spacing(1px);
    }

    .emp-advSearchIcon {
        margin-right: 6px;
    }

    .emp-gridWrapperDefault {
        display: flex;
        align-items: flex-start;
        flex-wrap: nowrap;

        @include theme-breakpoint('down', $lg-screen-size) {
            align-items: center;
            flex-wrap: wrap;
        }
    }

    .emp-filterAlertContainer {
        margin-left: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .emp-upgradeAlertContainer {
        border: 1px solid #ebccd1;
        margin-bottom: theme-spacing(1px);
        background-color: #f2dede;
        color: #a94442;
        padding: 15px;
        border-radius: 4px;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .emp-upgradeAlertBtn {
        text-transform: capitalize;
        font-size: 12px;
        background-color: #c80000;

        &:hover {
            background-color: #af2222;
        }
    }

    .emp-exitAlertBtn {
        margin: 0;
        padding: 0;
    }

    .emp-autoAcceptBtn {
        text-transform: uppercase;
    }
}