//** Onboarding **//
#onboardingView {
    .emp-root {
        display: flex;
        justify-content: flex-end;
        margin: theme-spacing(1px) 0;

        @include theme-breakpoint('up', $md-screen-size) {
            margin-right: 15px;
        }
    }

    .emp-onboardingBtn {
        text-transform: capitalize;
    }

    .emp-dateRangePicker {
        width: 200px;
    }

    .emp-switchArrowIcon {
        margin-right: 5px;
    }

    .emp-switchArrowBtn {
        min-width: 40px;
        padding: 3px 10px;
        margin-right: 3px;
        color: #263238;
    }

}