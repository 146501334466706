.emp-expandableRow-tableBodyCell {
    white-Space: nowrap;
    text-Align: center;

    &>p {
        padding: 0;
        margin: 0;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    border-top: 1px solid #eeeeee;
}

.emp-expandableRow-tableBodyOverflowingRow {
    text-align: left;

    & [class*="ccImage"] {
        justify-content: left;
    }

    & [class*="assignedContainer"] {
        justify-content: left;
    }

    & [class*="assigned"] {
        width: inherit;
        max-width: 250px;
    }

    border-top:1px solid #eeeeee;
}

.emp-expandableRow-tableBodyCellSelected {
    border-bottom: none !important;
}

.emp-expandableRow-maxWidth {
    width: 150px;
}

.emp-expandableRow-extraDataTableHeader {
    /** color used to mirror MUI table row border */
    border-right: solid 1px #EEEEEE;
    font-size: .95rem;
    padding: theme-spacing(1px);
    white-space: nowrap;
    color: $black-color;
    text-align: right;
    border-top: 1px solid #eeeeee;
}

.emp-expandableRow-zebraStripe {
    background-color: hsla(0, 0%, 80%, 0.1);
}

.emp-expandableRow-selectionStripe {
    background-color: rgb(206, 223, 255);
}

.emp-expandableRow-styledTableRow {
    padding: 0;

    &:hover {
        background-color: rgba(0, 0, 0, 0.04);
    }
}

.emp-expandableRow-iconHover {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    font-size: 18px;
}

.emp-expandableRow-expandableTableRow {
    height: 18px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: right;

}

.emp-expandableRow-expandableRowHeader {
    font-weight: 600;
    color: #555;
}

.emp-expandableRow-tooltipPopper {
    background: #020202;
    color: $white-color;
    font-size: 12px;
}

.emp-expandableRow-tooltipPopperArrow {
    &::before {
        background: #020202;
    }
}

.emp-expandableRow-hoverWithExpand {
    display: block;
    padding: 0px 4px;
    margin: 0px 8px;

    &>.ellipsify-text>div {
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &>.expanded-text {
        display: block;
        white-space: normal;
        word-break: break-word;
    }

    &:hover {
        background-color: #ddd;
        border-radius: 5px;
        cursor: pointer;
    }

    & .copy-icon-container {
        display: none;
        position: absolute;
        top: 0px;
        right: -14px;
    }

    &:hover .copy-icon-container {
        display: block;
    }
}

.emp-expandableRow-isOpen {
    background: linear-gradient(to bottom,  #f0f0f0 0%,#dfdfdf 100%);
}

.emp-expandableRow-collapseContainer {
    background: linear-gradient(to bottom,  #f0f0f0 0%,#ffffff 100%);
    box-shadow: inset 0px 1px 5px 0px rgba(0,0,0,0.35);
    border: solid #f3f3f3;
    border-width: 1px 1px 1px 0;
}

.emp-expandableRow-expandableGridContainer {
    display: grid;
    gap: .6rem .25rem;
    margin: theme-spacing(1px) theme-spacing(4px);
    padding: theme-spacing(1px) theme-spacing(1.5px) theme-spacing(1px) 60px;

    & div {
        position: relative;
    }

    & svg {
        position: absolute;
        min-height: 18px;
        max-height: 18px;
    }

    & img {
        position: absolute;
        left: -theme-spacing(1px);
        max-height: 18px;
    }

    &>div {
        display: flex;

        &>div:first-of-type {
            padding-right: theme-spacing(1px);
        }
    }

    @include theme-breakpoint('up', $sm-screen-size) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include theme-breakpoint('up', $md-screen-size) {
        grid-template-columns: repeat(3, 1fr);
    }

    @include theme-breakpoint('up', $lg-screen-size) {
        grid-template-columns: repeat(3, 1fr);
    }
}

.emp-expandableRow-copyIcon {
    font-size: 14px;
    margin-right: 1px;

    &:hover {
        color: #BABFC7;
    }
}