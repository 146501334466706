.emp-accentArea-root {
    padding: theme-spacing(1px);

    @include theme-breakpoint('up', $md-screen-size) {
        padding: theme-spacing(4px);
    }
}

.emp-accentArea-header {
    display: flex;
    justify-content: space-between;
    max-width: 100vw;

    & h5 {
        color: #666;
        font-size: 14px;
        font-weight: 600;
    }
}

.emp-accentArea-headerAction {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @include theme-breakpoint('down', $md-screen-size) {
        margin-top: 6px;
    }
}

.emp-accentArea-actionsWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    @include theme-breakpoint('up', $md-screen-size) {
        flex-direction: row;
        margin-top: -20px;
    }
}

.emp-accentArea-rowReverse {
    flex-direction: 'row-reverse',
}

.emp-accentArea-divider {
    margin-top: 10px;
    margin-bottom: 10px;
}

.emp-accentArea-containsFullWidthGrid {
    width: 100%;
    overflow: initial;
}