//** Case Updater AG **//
#caseUpdaterView {
    .emp-accentArea {
        padding: theme-spacing(1px) !important;
        padding-top: theme-spacing(4px) !important;
    }

    .emp-uploadFileBtnText {
        margin-left: theme-spacing(1px);
    }
}