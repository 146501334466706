.emp-presetDateRange-container {
    position: relative;
}

.emp-presetDateRange-paper {
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    min-height: 305px;
    margin-top: 5px;
}

.emp-presetDateRange-clearIcon {
    color: rgba(0, 0, 0, 0.54);
    font-size: 16px;
    margin-top: 1px;

    &:hover {
        cursor: pointer;
    }
}

.emp-presetDateRange-popper {
    z-index: 1000;
    margin-top: 5px;

    @include theme-breakpoint('up', $sm-screen-size) {
        margin-left: 20px
    }
}

.emp-presetDateRange-calendarIcon {
    font-size: 14px;
    box-shadow:
        0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%), 0 1px 3px 0 rgb(0 0 0 / 20%);

    &:hover {
        cursor: pointer;
    }
}