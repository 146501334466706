//** Add ERT **//
#caseQCReviewAddErt {
    .emp-modalContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        font-size: 14px;

        &>:last-child {
            margin-bottom: 0px;
        }
    }

    .emp-inputContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-bottom: theme-spacing(1.5px);

        &>label {
            width: 15%;
            margin-right: theme-spacing(1px),
        }

        &>div {
            width: 75%;
        }

        & .MuiOutlinedInput-root {
            padding: 7px;
        }
    }
}