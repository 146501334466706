//** Webhooks Grid **//
#webhooksGrid {
    padding: theme-spacing(1px);
    padding-top: theme-spacing(1.5px);

    .emp-agGrid {
        & .ag-pinned-left-header>.ag-header-row>.ag-header-cell:first-child>.ag-header-cell-comp-wrapper>.ag-cell-label-container>.ag-header-cell-label {
            justify-content: center;
        }
    }
}