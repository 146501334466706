//** Review Cell **//
#qcReviewReviewCell {
    .emp-iconBtn {
        margin: 0;
        padding: 0;
    }

    .emp-actionIcon {
        width: 20px;
        height: 20px;
    }

    .emp-disabledIcon {
        @include disable-icon;
        cursor: default;
    }
}