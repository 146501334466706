.emp-caseBuilder-closeIcon {
    position: relative;
    right: 5px;
    cursor: pointer;
    font-size: 25px;
}

.emp-caseBuilder-topbarContainer {
    position: sticky;
    top: 0;
    background-color: $white-color;
    z-index: 99;
}

.emp-caseBuilder-topbarTitleContainer {
    display: flex;
    justify-content: space-between;
    padding: 15px 10px;
    color: $white-color;
    background-color: #B91200;
}

.emp-caseBuilder-topbarTitleTypographyContainer {
    display: flex;
    align-items: center;

    & .MuiTypography-h3 {
        color: $white-color;
    }
}

.emp-caseBuilder-topbarInfoTypographyContainer {
    display: flex;
    align-items: center;

    & .MuiTypography-h3 {
        color: $white-color;
    }
}

.emp-caseBuilder-topbarTitleTypography {
    font-weight: 600,
}

.emp-caseBuilder-topbarInfoTypography {
    font-weight: 600;
    margin-right: 25px;
}

.emp-caseBuilder-creditCardIcon {
    margin-right: 10px;
    font-size: 25px;
}

.emp-caseBuilder-topbarDetailsContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2px;

    @include theme-breakpoint('up', $sm-screen-size) {
        grid-template-columns: repeat(5, 1fr);
    }
}

.emp-caseBuilder-topbarDetailPanel {
    background-color: #EFEFEF;
    padding: 10px 5px;
    text-align: center;
}

.emp-caseBuilder-topbarDetailPanelTypography {
    font-weight: 600;
    line-height: 1.25;
}

.emp-caseBuilder-mainContainer {
    padding: 15px;
    height: 650px;

    @include theme-breakpoint('up', $md-screen-size) {
        padding: 10px 15px;
    }
}

.emp-caseBuilder-footerContainer {
    position: sticky;
    bottom: 0;
    background-color: $white-color;
    z-index: 99;
}

.emp-caseBuilder-footerBtnsContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0px 10px 10px 10px;
}

.emp-caseBuilder-divider {
    margin-bottom: 10px;
}

.emp-caseBuilder-backBtn {
    margin-right: 10px;
    font-weight: 700;
    text-transform: capitalize;
}

.emp-caseBuilder-saveContinueBtn {
    margin: 0px;
    font-weight: 700;
    text-transform: capitalize;
    background-color: #B91200;

    &:hover {
        background-color: #ab1000;
    }

    & svg:not([class^="MuiCircularProgress"]) {
        margin-left: theme-spacing(1px);
    }
}

.emp-caseBuilder-circularProgress {
    width: 15px !important;
    height: 15px !important;
    vertical-align: middle;
    color: $white-color;
}

.emp-caseBuilder-btnIcons {
    margin-right: 5px;
}

.emp-caseBuilder-loadingContainer {
    margin-top: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#caseBuilder-div-root > .MuiDialog-container > .MuiDialog-paperFullWidth {
    max-width: 1280px;
}