.emp-switchList-container {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 2.5rem;
}

.emp-switchList-compact {
    @include theme-breakpoint('up', $md-screen-size) {
        grid-template-columns: 2fr 2fr;
    }
}

.emp-switchList-formLabel {
    & .MuiTypography-body1 {
        text-align: left;
    }
}