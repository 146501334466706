//** Reports **//
#reportsView {
    .emp-reportsHeader {
        display: flex;
        align-items: center;
        align-content: center;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 30px;

        @include theme-breakpoint('up', $md-screen-size) {
            flex-direction: row;
        }


        @include theme-breakpoint('down', $md-screen-size) {
            align-items: center;
        }
    }

    .emp-inputs {
        min-width: 200px;
        margin: 5px;
    }

    .emp-dateRangePicker {
        width: 200px;
    }

    .emp-pieChartGrid {
        @include theme-breakpoint('up', 1575) {
            flex-wrap: nowrap;
        }

    }

    .emp-chartsGrid {
        display: grid;
        gap: 1;
        grid-template-rows: auto;
    }

    .emp-chart {
        grid-column: span 1;
        border: none;
        box-shadow: none;
    }

    .emp-loadingIndicator {
        margin-top: 80px;
        text-align: center;
        height: 500px;
    }

    .emp-searchableText {
        margin: 2px 10px;
        font-size: 14px;
    }
}