.emp-piePlot-root {
    & .visx-group>g:not(:last-child) {
        filter: drop-shadow(3px 3px 2px rgb(0 0 0 / 0.30));
    }
}

.emp-piePlot-pieHeader {
    font-family: Source Sans Pro, sans-serif;
    font-size: calc(1.5rem + 1.2vw);
    font-weight: 500;
    letter-spacing: 2px;

    @include theme-breakpoint('down', $md-screen-size) {
        font-size: 55px;
    }
}

.emp-piePlot-subPieHeader {
    font-family: Source Sans Pro, sans-serif;
    font-size: 'calc(0.2rem + 1.2vw)';
    font-weight: 500;

    @include theme-breakpoint('down', $md-screen-size) {
        font-size: 24px;
    }
}

.emp-piePlot-legend {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}