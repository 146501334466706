.emp-ertNotificationAlert-root {
    margin-right: 35px;
}

.emp-ertNotificationAlert-icon {
    color: #BDBDBD;
    font-size: 35px;

    &:hover {
        cursor: pointer;
    }
}

.emp-ertNotificationAlert-badge {
    & .MuiBadge-colorError {
        background-color: #cb2022;
    }
}