.emp-creditCardProcessorType-img {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.emp-creditCardProcessorType-small {
    width: 24px;
    height: 24px;
}

.emp-creditCardProcessorType-medium {
    width: 26px;
    height: 26px;
}

.emp-creditCardProcessorType-large {
    width: 40px;
    height: 32px;
    font-size: 18px;
}

.emp-creditCardProcessorType-icon {
    margin: 1px 0;
}