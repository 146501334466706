//** remove MID Access Confirmation **//
#merchantSettingsRemoveMidAccessConfirmation {
    .emp-modalHeader {
        padding: 0;
    }

    .emp-divider {
        border-bottom: 3px solid #eeeeee;
        margin: theme-spacing(2px) 0;
        width: 100%;
    }

    .emp-dialogContent {
        padding-top: theme-spacing(3px) !important;
        padding-bottom: theme-spacing(3px);
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
    }

    .emp-btnGrp {
        &>button {
            margin: 0 theme-spacing(1px);
        }
    }
}