//** Authed User Context **//
#authedUserContext {
    height: 100vh;
    background-color: #f5f5f5;


    .emp-authContainer {
        width: 100%;
        height: 400px;
    }

    .emp-authContainerMain {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        top: 60px;
    }

    .emp-authContainerInner {
        width: 448px;
        padding-top: 20px;
        border-radius: 5px;
        border: #bdbdbd solid 1px;
        box-shadow: 0 15px 50px #d8d8d8;
        background-color: $white-color;
        z-index: 10;
    }

    .emp-authContainerLogo {
        background-color: $white-color;
        text-align: center;

        &>img {
            max-width: 250px;
        }
    }

    .emp-authContainerLogoText {
        margin: theme-spacing(1.5px);
        font-size: 50px;
        font-weight: 600;
    }

    .emp-snackbar {
        & .MuiSnackbarContent-root {
            font-size: 14px;
            padding: 10px;
            background-color: #31465F;
        }

        & .MuiSnackbarContent-message {
            padding: 8px,
        }
    }

    .emp-snackbarContainer {
        width: 95vw;
        display: flex;
        justify-content: space-between;
    }

    .emp-timesIcon {
        &:hover {
            color: #dd3f5b;
        }
    }
}