//** <erchant Users Grid **//
#merchantSettingsMerchantUsersGrid {
    .emp-actionIcon {
        cursor: pointer;
        font-size: 14px;
        padding: 0;
        margin-bottom: 5px;
    }

    .emp-lampWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .emp-lamp {
        width: 8px !important;
        height: 8px !important;
        margin-right: theme-spacing(1px);
    }

    .emp-disabledIcon {
        @include disable-icon;
    }
}