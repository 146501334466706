//** MID History **//
#merchantSettingsMidHistory {
    .emp-notAvailable {
        text-align: center;
        font-weight: 600;
    }

    .emp-historyGridContainer {
        & .ag-root-wrapper-body {
            max-height: 275px;
        }
    }
}