.emp-profile-thick {
    padding: theme-spacing(4px);
}

.emp-profile-thin {
    padding: theme-spacing(1px) theme-spacing(2px);
}

.emp-profile-accountParts {
    padding-top: theme-spacing(2px) 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.emp-profile-accountOwner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: space-between;
    padding-left: theme-spacing(2px);
}

.emp-profile-username {
    text-transform: lowercase;

}

.emp-profile-avatar {
    color: $white-color;
    width: 4rem;
    height: 4rem;
    font-size: 2.5rem;
}

.emp-profile-avatarFrame {
    padding: theme-spacing(1px) theme-spacing(4px) theme-spacing(2px);
    display: flex;
    align-items: center;
    justify-content: center;

    .profile-compact & {
        padding: theme-spacing(1px);
        padding-top: 0;
    }
}

.emp-profile-roles {
    margin-top: theme-spacing(2px);
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    flex-wrap: wrap;

    @include theme-breakpoint('up', $md-screen-size) {
        flex-direction: row;
    }
}

.emp-profile-role {
    margin-bottom: 0.3rem;
}