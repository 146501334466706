//** View Document Icon Action **//
#chargebacksViewDocumentIconAction {
    .emp-icon {
        width: 20px;
        height: 20px;
    }

    .emp-disabledIcon {
        opacity: .25;
        cursor: default;
    }
}

//** Verdict Cell **//
#chargebacksVerdictCell {
    display: flex;
    align-items: center;
    justify-content: center;
}

//** Actions Cell **//
#chargebacksActionsCell {
    display: flex;
    align-items: center;
    justify-content: center;
}

//** Edit Chargeback Cell **//
#chargebacksEditChargebacksCell {
    display: flex;
    justify-content: center;
    align-items: center;

    .emp-btn {
        padding: 6px 12px;
        display: flex;
        font-size: 0.75rem;
        min-width: 40px;
    }
}

//** Issuer Docs Cell **//
#chargebacksIssuerDocsCell {
    .emp-documentIconBtn {
        margin: 0;
        padding: 0;
    }

    .emp-disabledIcon {
        opacity: .25;
        cursor: default;
    }

    .emp-actionIcon {
        width: 20px;
        height: 20px;
    }
}