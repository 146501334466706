//** Toggle Lock Action Cell **//
#settingsAGToggleLockActionCell {
    display: flex;
    justify-content: center;
    align-items: center;

    .emp-lockAndUnlockBtn {
        padding: 0;
        font-size: 0.75rem;
        min-width: 70px;
    }

    .emp-lockAndUnlockBtnText {
        margin-left: 5px;
        text-transform: capitalize;
    }
}