//** Mark Alert **//
.emp-alerts-markAlert-modalContainer {
    & .MuiDialog-paperFullWidth {
        width: 500px;
    }
}

#alertsMarkAlert {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 14px;
    row-gap: theme-spacing(1px);

    .emp-inputContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;


        &>label {
            width: 15%;
            margin-right: theme-spacing(1px);
        }

        &>div {
            width: 75%;
        }

        & .MuiOutlinedInput-root {
            padding: 7px;
        }

        & .MuiOutlinedInput-input {
            padding: 0px;
        }
    }


    .emp-selectedFile {
        padding: theme-spacing(1px);
        color: #666;
    }
}