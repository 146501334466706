//** Template Library Grid **//
#templateDataGrid {
    .emp-dataActionsContainer {
        display: flex;
        justify-content: flex-end;
    }

    .emp-dataGrid {
        & #paginate-control-blocks,
        #paginate-entries-dropdown {
            // hides paginate button controls & entries dropdown
            display: none;
        }
    }
}