//** Edit Icon Action **//
#usersAGEditIconAction {

    .emp-disabledContainer {
        display: flex;
    }

    .emp-disabledIcon {
        @include disable-icon;
    }
}

//** Toggle Lock Icon Action **//
#usersAGToggleLockIconAction {
    .emp-disabledContainer {
        display: flex;
    }

    .emp-disabledIcon {
        @include disable-icon;
    }
}

//** Mid Permissions Icon Action **//
#usersAGMidPermissionsIconAction {
    .emp-disabledIcon {
        @include disable-icon;
    }
}

//** Site Permissions Action **//
#usersAGSitePermissionsIconAction {
    .emp-disabledContainer {
        display: flex;
    }

    .emp-disabledIcon {
        @include disable-icon;
    }
}

//** Temp Password Icon Action **//
#usersAGTempPasswordRequestIconAction {
    .emp-disabledContainer {
        display: flex;
    }

    .emp-disabledIcon {
        @include disable-icon;
    }
}

//** Login As Icon Action **//
#usersAGLoginAsIconAction {
    .emp-disabledIcon {
        @include disable-icon;
    }

    .emp-signInIcon {
        color: #2a7ea0;
        font-size: 20px;
    }
}