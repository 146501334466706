//** File DnD **//
.emp-fileDnD-root {
    background-repeat: no-repeat;
    background-position: center 10px;
    min-height: 300px;
    background-size: 230px;
    display: grid;
    place-items: end center;
    padding: theme-spacing(1px);
    border-style: dashed;
    border-width: 2px;
    border-color: #bdbdbd;
    transition: 0.5s;
    width: 100%;

    & .MuiTypography-body1 {
        color: #A09E9E;
    }

    & .MuiTypography-body1:nth-child(1) {
        font-size: 16px;
    }
}

.emp-fileDnD-disabledRoot {
    opacity: 0.5;

    &:hover {
        border-color: #bdbdbd;
        background-color: $white-color;
        background-size: 230px;

        & .MuiTypography-body1 {
            color: #A09E9E;
        }
    }
}

.emp-fileDnD-customFileDnDFeatureContainer {
    padding: theme-spacing(1px);
    border-style: dashed;
    border-width: 2px;
    border-color: #bdbdbd;
    min-height: 400px;
}

.emp-fileDnD-label {
    text-align: center;
}

.emp-fileDnD-fileUploadCriteria {
    margin: 0px;
    font-size: 14px;
}

//** File Upload **//
.emp-fileUpload-templateLibContainer {
    margin-bottom: 10px;

    &>div:first-of-type {
        width: calc(100% - 80px);
    }

    &>button {
        margin-left: 10px;
    }
}

.emp-fileUpload-fileRoot {
    text-align: center;
    min-height: 150px;
}

.emp-fileUpload-loadingContainer {
    margin-top: theme-spacing(1.5px);
    text-align: center;
}