//** Bulk Upload MIDs **//
#merchantSettingsBulkUploadMids {
    .emp-mainContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: theme-spacing(2px);
    }

    .emp-inputContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        &>label {
            width: 30%;
            margin-right: theme-spacing(1px);
        }

        &>div {
            width: 60%;

            &>ul {
                margin: 0;
            }
        }
    }

    .emp-downloadTemplateLink {
        margin-right: theme-spacing(2px);
        font-weight: bold;
        text-decoration: underline;
        cursor: pointer;
    }

    .emp-fileInputHelpText {
        margin-top: theme-spacing(0.5px);
        font-size: 12px;
        color: #666;
        font-style: italic;
    }

    .emp-selectedFile {
        margin-top: theme-spacing(0.5px);
        color: #155C93;
    }
}