//** Delete Webhook Cell **//
#webhooksDeleteWebhookCell {
    display: flex;
    justify-content: center;
    align-items: center;

    .emp-iconBtn {
        width: 18px;
        height: 18px;
        margin: 0;
        padding: 14px;
    }

    .emp-disabledIcon {
        @include disable-icon;
    }
}



//** Actions Webhook Cell **//
#webhooksActionsWebhookCell {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2px;

    .emp-iconContainer {
        padding: 4px;
    }

    .emp-icon {
        font-size: 18px;
        margin: 4px;

        &:hover {
            cursor: pointer;
        }
    }

    .emp-disabledIcon {
        font-size: 18px;
        margin: 4px;
        @include disable-icon;

        &:hover {
            cursor: default;
        }
    }
}