//** Action Cell **//
.emp-ert-actionCell-icon {
    font-size: 1.2rem;
    margin: 4px;

    &:hover {
        cursor: pointer;
    }
}

.emp-ert-actionCell-root {
    padding: 4px;
    display: flex;
    align-items: center,
}