.emp-merchantEdit-root {
    font-size: 12px;
    margin: 10px 15px 0 0;
}

.emp-merchantEdit-label {
    color: #999;
    font-size: 14px;
}

.emp-merchantEdit-leftGrid {
    padding-right: theme-spacing(2px);
    border-right: 1px solid #eeeeee;
    display: block;
}

.emp-merchantEdit-rightGrid {
    padding-left: theme-spacing(2px);
}

.emp-merchantEdit-middleLeftGrid {
    margin-top: theme-spacing(1px)
}

.emp-merchantEdit-innerGrid {
    margin: 3px 0 7px 0;
}

.emp-merchantEdit-lampWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
}

.emp-merchantEdit-lamp {
    width: 8px;
    margin-right: theme-spacing(1px);
}

.emp-merchantEdit-radiosContainer {
    margin-top: theme-spacing(1px);
    margin-bottom: theme-spacing(1px);
    margin-left: theme-spacing(.5px);

    &>label>.MuiRadio-root {
        padding: 2px 5px;
    }

    & .MuiTypography-body1 {
        font-size: 14px;
    }
}

.emp-merchantEdit-merchantValue {
    margin-bottom: 5px;
    font-size: 14px;
}

.emp-merchantEdit-switchBtn {
    & .MuiSwitch-colorSecondary.Mui-disabled {
        color: #bdbdbd;
    }

    & .MuiSwitch-colorSecondary.Mui-disabled+.MuiSwitch-track {
        background-color: #000;
    }
}