//** Alerts Report **//
#reportsAlertsView {
    .emp-grid {
        display: grid;
        gap: 16px;
        grid-template-rows: auto;
        grid-template-columns: 1fr;
        margin-bottom: theme-spacing(2px);

        @include theme-breakpoint('up', $sm-screen-size) {
            grid-template-columns: repeat(3, 1fr);
            gap: 24px;
        }

        @include theme-breakpoint('up', 1370) {
            grid-template-columns: repeat(3, 1fr);
            gap: 32px;
        }
    }

    .emp-gridPieCharts {
        @include theme-breakpoint('up', $sm-screen-size) {
            grid-column: span 3;
        }

        @include theme-breakpoint('up', 1370) {
            grid-column: span 3;

        }

        &>div {
            display: grid;
            gap: 16px;
            grid-template-rows: auto;
            grid-template-columns: 1fr;


            @include theme-breakpoint('up', $sm-screen-size) {
                grid-template-columns: repeat(1, 1fr);
                gap: 24px;
            }

            @include theme-breakpoint('up', 1370) {
                grid-template-columns: repeat(3, 1fr);
                gap: 32px;
            }
        }
    }

    .emp-gridAreaChart {
        @include theme-breakpoint('up', $sm-screen-size) {
            grid-column: span 3;
        }

        @include theme-breakpoint('up', 1370) {
            grid-column: span 3;

        }
    }

    .emp-dateRangePicker {
        width: 200px;
    }
}