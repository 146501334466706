//** Resultant KPI **//
#resultantKpiView {
    .emp-container {
        display: flex;
        align-items: center;
        align-content: center;
        gap: 8px;
        flex-wrap: wrap;

        @include theme-breakpoint('down', $lg-screen-size) {
            justify-content: center;
        }
    }

    .emp-filterByLabel {
        width: 68px; // sets beggining label width for input group
    }

    .emp-inputs {
        min-width: 200px;
        margin: 5px;
    }

    .emp-searchableText {
        margin: 2px 10px;
        font-size: 14px;
    }

    .emp-marginRight {
        margin-right: 5px;
    }
}