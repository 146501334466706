.emp-contextMenu-root {
    padding: 0;
}

.emp-contextMenu-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-width: 200px;
    max-width: 400px;
    overflow: hidden;
}

.emp-contextMenu-itemIcon {
    width: 16px;
    height: 16px;
    margin-right: theme-spacing(1px)
}