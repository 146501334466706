.emp-pieChart-mainContainer {
    &.MuiGrid-grid-md-6 {
        width: 450px;
    }

    & .recharts-pie {
        filter: drop-shadow(3px 3px 2px rgb(0 0 0 / 0.30));
    }
}

.emp-pieChart-header {
    display: flex;
    justify-content: space-between;
    padding: theme-spacing(1px);
    text-align: center;
    height: 50px;
}

.emp-pieChart-pieChartContent {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 5px;
    align-self: top;

    &.MuiCardContent-root {
        margin-bottom: 40px;
    }
}

.emp-pieChart-noDataMessaging {
    font-size: 14px;
    margin-top: 75px;
    min-height: 100px;
}

.emp-pieChart-loadingContainer {
    height: 125px;
    margin-top: 80px;
}

.emp-pieChart-tooltipContainer {
    background-color: $white-color;
    border: 0.25px solid #cccc;
    opacity: .9;
    box-shadow:
        0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%), 0 1px 3px 0 rgb(0 0 0 / 20%);
}

.emp-pieChart-tooltipTableHeader {
    font-weight: 700;
    text-align: left;
    color: white;
    padding: 0px 4px;

}

.emp-pieChart-tooltipTableRow {
    padding: 0px 4px;
}