//** Merchant Node **//
#merchantHierarchyMerchantNode {
    .emp-loadingNode {
        padding: 0.5rem;
        padding-left: 20px;
        border: solid 1px lightgray;
    }

    .emp-hoverGrey {
        &:hover {
            background-color: #f5f5f5;
        }
    }
}

#merchantHierarchyMerchantNodePopover {
    .emp-contextMenuItem {
        padding: 0 8px;
        background: $white-color !important;
        transition: none !important;
    }

    .emp-contextBtn {
        display: block;
        min-width: 200px;
        text-align: left;
        color: $primary-text-color;
        text-transform: capitalize;

        & svg {
            display: inline-block;
            margin-right: 5px;
            color: #3cb4e5;
        }
    }

    .emp-disabledIcon {
        @include disable-icon;
    }
}