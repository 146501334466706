.emp-caseHistoryPopover-gridContainer {
    & .ag-root-wrapper-body {
        max-height: 200px;
    }
}

.emp-caseHistoryPopover-dataGrid {
    & .ag-tooltip {
        white-space: nowrap;
    }

    .ag-center-cols-clipper,
    .ag-center-cols-container {
        min-height: 70px !important;
    }
}