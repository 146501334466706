.emp-accountHistory-searchbarContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    @include theme-breakpoint('up', $md-screen-size) {
        flex-direction: row;
        justify-content: flex-between;
    }

    & .MuiInputBase-input:focus {
        border-color: #a8a8a8;
        border-radius: 4px;
        z-index: 100;
    }
}

.emp-accountHistory-searchbarInput {
    padding: theme-spacing(1px);
    border-radius: 3px;
    text-align: left;
    outline: none;
    border: solid 1px #a8a8a8;
    color: #545454;
}

.emp-accountHistory-lockUnlockBtn {
    width: 75px;
}

.emp-accountHistory-lockUnlockBtnText {
    margin-left: 5px;
    text-transform: capitalize;
}