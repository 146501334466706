.emp-addCommentModal-title {
    font-size: 2.5rem;
    font-weight: 400;
    padding: 0;
    margin: 0;
}

.emp-addCommentModal-subtitle {
    font-weight: 400;
    margin: 0 0 1.5rem 0;
}

.emp-addCommentModal-content {
    width: 100%;
    display: grid;
    text-align: center;
    padding: 20px 24px 8px;
}

.emp-addCommentModal-actions {
    display: grid;
    grid-auto-flow: column;
    place-items: center;
    margin: 1.5rem 30%;
    gap: 1.5rem;
}

.emp-addCommentModal-btn {
    width: 100%;
}

.emp-addCommentModal-circularProgress {
    width: 15px !important;
    height: 15px !important;
    vertical-align: middle;
    color: $white-color;
}