//** CSV MID Upload **//
#merchantSettingsCsvMidUpload {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: theme-spacing(1px);

    .emp-csvBtn {
        text-transform: none;
        color: $white-color;
        background: #777777;
    }

    .emp-submitBtn {
        margin-left: theme-spacing(1px);
    }

    .emp-downloadTempLink {
        font-weight: bold;
        text-decoration: underline;
        cursor: pointer;
    }

    .emp-fileInput {
        display: none;
    }

}