//** Settings **//
#settingsView {
    .emp-lead {
        padding: theme-spacing(2px) 0;
    }

    .emp-ladder {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: space-between;
        flex-direction: row;

        @include theme-breakpoint('up', $md-screen-size) {
            flex-direction: column;
        }

        &>div {
            padding-bottom: theme-spacing(2px);
        }
    }

    .emp-historySection {
        & header {
            position: relative;

            &>span:last-of-type {
                display: inline-block;
                position: absolute;
                right: 0;
            }
        }

        & .reduce-vertical-padding {
            padding: 0 9px;
        }
    }

    .emp-outlinedInputs {
        & .MuiOutlinedInput-input {
            padding: theme-spacing(2px) theme-spacing(1.75px) theme-spacing(2.25px) theme-spacing(1.75px);
        }
    }

    .emp-icon {
        display: flex;
        align-items: center;
    }

    .emp-marginRight {
        margin-right: 5px;
    }
}