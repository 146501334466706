.emp-alertDialog-root {
    &>.MuiDialog-container>.MuiPaper-root {
        min-width: 400px
    }

    & .MuiPaper-rounded {
        border-radius: 5px;
    }

    & .MuiDialogContent-root {
        padding: 20px 24px;
    }
}

.emp-alertDialog-title {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    font-weight: 700;
    color: $white-color;
}

.emp-alertDialog-content {}