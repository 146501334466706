.emp-updateMid-root {
    display: flex;
    flex-direction: column;
}

.emp-updateMid-formContainer {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: theme-spacing(0.5px),
}

.emp-updateMid-actionBtnContainer {
    width: 100%;
    text-align: right;
    margin-top: theme-spacing(2px);

    &>button:nth-child(1) {
        margin-right: theme-spacing(2px);
    }
}

.emp-updateMid-csvUploadContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: theme-spacing(1px);
    margin-bottom: theme-spacing(2px);
    background-color: #f3f3f3;
    width: 100%;

    &>span {
        font-size: 13px;
        font-weight: 600;
        color: #666;
    }
}

.emp-updateMid-background {
    background-color: $white-color;
}

.emp-updateMid-textFieldInput {
    &>div input {
        padding: theme-spacing(1px);
    }

    &>.MuiSelect-select {
        padding: theme-spacing(1px);
    }
}

.emp-updateMid-inputLabel {
    margin-bottom: theme-spacing(0.5px);
    font-size: 14px;
    color: #666;
}

.emp-updateMid-formControl {
    margin-bottom: theme-spacing(2px);
    position: relative;
}

.emp-updateMid-inputHalf {
    width: 48%;
}

.emp-updateMid-radioContainer {
    display: flex;
    flex-direction: column;

    & .MuiTypography-body1 {
        font-size: 14px,
    }
}

.emp-updateMid-formBlock {
    width: 49%;
    min-height: 130px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.emp-updateMid-formBlockFull {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}

.emp-updateMid-lampWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100px;
    font-size: 14px;
}

.emp-updateMid-lamp {
    width: 8px;
    margin-right: theme-spacing(1px);
}

.emp-updateMid-serviceContainer {
    display: flex;
    justify-content: space-between;
}

.emp-updateMid-switchBtn {
    & .MuiSwitch-colorSecondary.Mui-disabled {
        color: #bdbdbd;
    }

    & .MuiSwitch-colorSecondary.Mui-disabled+.MuiSwitch-track {
        background-color: #000;
    }
}

.emp-updateMid-status {
    display: flex;
    justify-content: space-between;
}

.emp-updateMid-deflectors {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
}

.emp-updateMid-inheritMerchant {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    align-items: center;
    font-size: 14px;
}

.emp-updateMid-divider {
    margin: 7px 0px 15px 0px;
}

.emp-updateMid-loadingContainer {
    display: flex;
    position: relative;
}

.emp-updateMid-editRDRBtn {
    padding: 0;
    margin: 0 theme-spacing(.5px);
    height: 22px;
    text-decoration: underline;
    text-transform: capitalize;
}