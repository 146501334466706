//** Case Info **//
#caseQCReviewCaseInfo {
    margin-left: 1px;

    &>div {
        min-height: calc(100vh - 268px);
        max-height: calc(100vh - 268px);
        overflow: auto;
    }

    .emp-cardTitle {
        font-weight: 600;
        font-size: 16px;
    }

    .emp-caseDetailsSection {
        display: flex;
        flex-direction: column;
        margin: 5px;
    }

    .emp-caseDetailsTitle {
        font-weight: 600;
        font-size: 14px;
    }

    .emp-caseDetails {
        font-weight: 400;
        font-size: 12px;
    }

    .emp-divider {
        margin: 5px 0;
    }

}