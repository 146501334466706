//** Pre Chargebacks AG Alerts**//
#preChargebacksNewView {
.emp-accentArea {
    padding: theme-spacing(1px) !important;
    padding-top: theme-spacing(4px) !important;
}

.emp-headerContent {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    @include theme-breakpoint('up', $md-screen-size) {
        flex-direction: row;
        justify-content: space-between;
    }
}

.emp-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 100vw;

    & h5 {
        color: #666;
        font-size: 14px;
        font-weight: 600;
    }

    @include theme-breakpoint('down', $sm-screen-size) {
        margin-top: 10px;
    }
}

.emp-divider {
    margin-top: 10px;
    margin-bottom: 10px;
}}