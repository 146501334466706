.emp-sidebar-drawerOpen {
    width: 200px;
    flex-shrink: 0;
    white-space: nowrap;
    overflow: hidden;
    z-index: 995;
}

.emp-sidebar-drawerClose {
    overflow: hidden;
    width: 47px;
    white-space: nowrap;
    z-index: 995;
}

.emp-sidebar-root {
    overflow-y: auto;
    overflow-x: hidden;
}

.emp-sidebar-merchantContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 15px 15px 7px 11px;
}

.emp-sidebar-merchantText {
    color: $white-color;
    font-size: 11px;
    white-space: break-spaces;
}