.emp-visaCEUndisputedTransactionForm-formRoot {
    margin-top: theme-spacing(1px);

    & .MuiDivider-root {
        background-color: #c1c1c1;
        margin: theme-spacing(1px) 0;
    }

    & .MuiFormControl-root {
        padding: 0 theme-spacing(.5px);
    }

    & .MuiFormLabel-root {
        background-color: $white-color;
    }
}

.emp-visaCEUndisputedTransactionForm-formSectionHeader {
    margin-bottom: theme-spacing(1.5px);
    padding: theme-spacing(.5px) theme-spacing(1px);
    font-size: 14px;
    font-weight: 600;
    background-color: #dfdfdf;
    border-radius: 2px;
}

.emp-visaCEUndisputedTransactionForm-redInfoSection {
    margin-bottom: theme-spacing(.5px);
    padding: 0 theme-spacing(1px);
    font-size: 10px;
    font-style: italic;
    color: #c00;
}

.emp-visaCEUndisputedTransactionForm-formFieldsContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: theme-spacing(.5px);

    @include theme-breakpoint('up', $md-screen-size) {
        flex-direction: row;
    }
}

.emp-visaCEUndisputedTransactionForm-addressContainer {
    position: relative;
    margin: theme-spacing(1.5px) theme-spacing(.5px) 0;
    padding: theme-spacing(1.5px) theme-spacing(1px) theme-spacing(1px);
    border-radius: theme-spacing(.5px);
    border: 1px solid #c1c1c1;
}

.emp-visaCEUndisputedTransactionForm-addressContainerHeader {
    position: absolute;
    top: theme-spacing(-1.5px);
    left: theme-spacing(1.5px);
    background: $white-color;
    font-weight: 600;
}
