.emp-markReviewedModal-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 300px;
    min-height: 235px;

    @include theme-breakpoint('up', $md-screen-size) {
        width: 580px;
        min-height: 225px;
    }
}

.emp-markReviewedModal-dialogTitle {
    display: flex;
    flex-direction: column;
    margin-bottom: -12px;
    color: #595959;
    font-weight: 500;
    font-size: 2rem;

    &>p {
        padding: 0;
        margin: 0;
    }
}

.emp-markReviewedModal-dialogContentText {
    text-align: center;
    max-width: 100%;
    color: #57595E;
    font-size: 1.15rem;
    line-height: 18px;
    margin-bottom: -12px;
    width: 95%;
}

.emp-markReviewedModal-contentText {
    padding: 0;
}

.emp-markReviewedModal-successIcon {
    width: 95px;
    height: 95px;
    border-radius: 50%;
    border: solid .25rem #3bd3ae;
    color: #3bd3ae;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4.75em;
    margin-top: theme-spacing(1px);
}

.emp-markReviewedModal-errorIcon {
    width: 95px;
    height: 95px;
    border-radius: 50%;
    border: solid .25rem #ef9a9a;
    color: #ef9a9a;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4.75em;
    margin-top: theme-spacing(1px);
}