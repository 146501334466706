.emp-visaCEAddUndisputedTransactions-formFieldsContainer {
    display: flex;
    flex-direction: column;

    @include theme-breakpoint('up', $md-screen-size) {
        flex-direction: row;
    }

    & > .MuiFormControl-root {
        padding: 0 theme-spacing(.5px);

        @include theme-breakpoint('down', $sm-screen-size) {
            &:last-of-type {
                margin-bottom: theme-spacing(1.5px);
                padding-bottom: theme-spacing(2px);
                border-bottom: 1px solid #c1c1c1;
            }
        }

        & .Mui-error.MuiFormHelperText-contained {
            margin: 0;
            padding: 2px 8px 4px;
            border-left: 1px solid #e53935;
            border-right: 1px solid #e53935;
            border-bottom: 1px solid #e53935;
            border-radius: 0 0 4px 4px;
        }
    }

    & .MuiFormLabel-root {
        background-color: $white-color;
    }

    & div[id^="popoverSelect-div-root-"] > div {
        height: 39px,
    }
}

.emp-visaCEAddUndisputedTransactions-greyBtn {
    color: #4f4f4f;
    background-color: #D5D5D5;
    padding: 7px theme-spacing(1.5px);
    font-weight: 700;

    &:hover {
        color: #4f4f4f;
        background-color: #D5D5D5;
    }

    svg {
        margin-top: -1px;
        margin-right: theme-spacing(.5px);
    }
}
