//** Select Mid **//
#preChargebacksAGAlertsSelectMid {
    display: flex;
    flex-direction: column;
    row-gap: theme-spacing(1px);

    .emp-label {
        font-weight: 700;
        text-align: left;
        margin-bottom: theme-spacing(.5px);
    }

    .emp-autocomplete {
        min-width: 250px;

        & .MuiOutlinedInput-root {
            padding: 7px;
        }

        & .MuiOutlinedInput-input {
            padding: 0px;
        }
    }
}