//** Edit Invalid Chargeback **//
.emp-invalidChargebacksAG-editInvalidChargeback-modalContainer {
    & .MuiDialog-paperFullWidth {
        width: 650px;
    }
}

#invalidChargebacksAGEditInvalidChargeback {
    .emp-loadingContainer {
        text-align: center;
        margin: theme-spacing(3px);
    }

    .ea-NoInvalidFieldsContainer {
        @extend .emp-loadingContainer;
        font-weight: 600;
    }
}