.emp-ert-caseDetails-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: theme-spacing(2px);
    width: 95%;

    @include theme-breakpoint('up', $sm-screen-size) {
        width: 400px;
    }
}

.emp-ert-caseDetails-headerBanner {
    background: #E0E0E0;
    display: flex;
    justify-content: space-between;
    padding: 12px 18px;
    font-size: 1.2rem;
    color: white;
}

.emp-ert-caseDetails-details {
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;

    @include theme-breakpoint('up', $sm-screen-size) {
        padding: 0 1.5rem;
    }
}

.emp-ert-caseDetails-detailItem {
    display: flex;
    justify-content: space-between;
}