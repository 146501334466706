//** Upload Template File DnD **//
#templateLibraryAGUploadTemplateFileDnD {

    .emp-uploadImage {
        background-repeat: no-repeat;
        background-position: center 10px;
        min-height: 200px;
        background-size: 190px;
        display: grid;
        place-items: end center;
        width: 100%;
    }

    .emp-documentTypeInfoContainer {
        padding: theme-spacing(2px);
    }

    .emp-info {
        margin-left: 5px;
        color: #c00;
        font-weight: 600;
    }

    .emp-autocomplete {
        & .MuiAutocomplete-endAdornment {
            right: 4px;
        }
    }

    .emp-btnsContainer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: theme-spacing(1px);
        margin-bottom: theme-spacing(2px);
    }

    .emp-btn {
        padding: 4px 8px;
        text-transform: capitalize;
        margin: 4px;
    }

    .emp-uploadIcon {
        margin-right: 5px;
        font-size: 20px;
    }

    .emp-circularProgress {
        width: 15px !important;
        height: 15px !important;
        vertical-align: middle;
        color: $white-color;
    }
}