//** Dashboard **//
#dashboardView {
    .emp-grid {
        display: grid;
        gap: 16px;
        grid-template-rows: auto;
        grid-template-columns: 1fr;

        @include theme-breakpoint('up', $sm-screen-size) {
            grid-template-columns: repeat(4, 1fr);
            gap: 24px;
        }

        @include theme-breakpoint('up', 1175) {
            grid-template-columns: repeat(4, 1fr);
            gap: 32px;
        }
    }

    .emp-lineChart {
        @include theme-breakpoint('up', $sm-screen-size) {
            grid-column: span 4;
        }

        @include theme-breakpoint('up', 1175) {
            grid-column: span 3;
        }
    }

    .emp-table {
        min-height: 390px;

        @include theme-breakpoint('up', $sm-screen-size) {
            grid-column: span 4;
        }

        @include theme-breakpoint('up', 1175) {
            grid-column: span 4;
            min-height: 365px;
        }
    }

    .emp-pieChart {
        min-height: 340px;

        @include theme-breakpoint('up', $sm-screen-size) {
            grid-column: span 4;
        }

        @include theme-breakpoint('up', 1175) {
            grid-column: span 1;
        }
    }

    .emp-metric {
        @include theme-breakpoint('up', $sm-screen-size) {
            grid-column: span 2;
        }

        @include theme-breakpoint('up', 1175) {
            grid-column: span 1;
        }
    }

    .emp-marginRight {
        margin-right: 5px;
    }

    .emp-icon {
        display: flex;
        align-items: center;
    }

    .emp-dateRangePicker {
        width: 200px;
    }

    .emp-switchArrowIcon {
        margin-right: 5px;
    }

    .emp-switchArrowBtn {
        min-width: 40px;
        padding: 3px 10px;
        margin-right: 3px;
        color: #263238;
    }
}