.emp-merchantAvatar-root {
    vertical-align: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    overflow: hidden;
}

.emp-merchantAvatar-spaced {
    margin-right: 18px;
}

.emp-merchantAvatar-skeleton {
    min-inline-size: 10rem;
}