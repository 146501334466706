//** Actions Cell **//
#ertAGActionsCell {

    .emp-root {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .emp-iconContainer {
        padding: 0px 4px;
    }

    .emp-icon {
        font-size: 18px;
        margin: 4px;

        &:hover {
            cursor: pointer;
        }
    }

    .emp-disabledIcon {
        font-size: 18px;
        margin: 4px;
    }

    .emp-disabledColor {
        @include disable-icon;

        &:hover {
            cursor: default;
        }
    }
}