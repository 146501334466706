.emp-visaCEMatchingTransactionDetails {
    .emp-visaCEMatchingTransactionDetails-header {
        padding: theme-spacing(1.5px) theme-spacing(2px) theme-spacing(1px) !important;

        > div:first-of-type {
            font-size:14px;
            font-weight: 700;
        }
        > div:last-of-type {
            font-size: 10px;
        }
    }
    .emp-visaCEMatchingTransactionDetails-checkbox-container {
        padding: 0 theme-spacing(2px);

        @include theme-breakpoint('down', $md-screen-size) {
            > * {
                display: block;
            }
        }
    }
}

.emp-visaCEMatchingTransactionDetails-formFieldsContainer {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin-bottom: theme-spacing(.5px);

    @include theme-breakpoint('up', $md-screen-size) {
        flex-direction: row;

        > div:first-of-type {
            margin-right: theme-spacing(.5px);
        }

        > div:last-of-type {
            margin-left: theme-spacing(.5px);
        }

        > div:not(:first-of-type):not(:last-of-type) {
            margin-left: theme-spacing(.5px);
            margin-right: theme-spacing(.5px);
        }
    }
}
