//** Edit Onboarding AG **//
#onboardingEditView {
    .emp-accentAreaContainer {
        margin-top: theme-spacing(1px);
        padding: theme-spacing(1px) !important;

        @include theme-breakpoint('up', $md-screen-size) {
            padding: theme-spacing(1px) !important;
            padding-top: theme-spacing(4px) !important;
        }
    }

    .emp-actionsContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .emp-btn {
        text-transform: capitalize;
    }
}