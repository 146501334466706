//** Flag Cell **//
#caseMgmtFlagCell {

    .emp-actionIcon {
        margin: 0;
        padding: 3px;
    }

    .emp-disabledIcon {
        @include disable-icon;
        cursor: default;
    }
}

//** Assign Cell **//
#caseMgmtAssignCell {
    display: flex;
    justify-content: center;
    align-items: baseline;

    .emp-assigned {
        background: #D9EDF8;
        text-transform: capitalize;
        color: #3d6a91;
        padding: 2px;
        border-radius: 3px;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 130px;
    }

    .emp-disabled {
        background-color: #e3e3e3;
        color: #333;
        cursor: default;
    }

    .emp-disabledIcon {
        @include disable-icon;
        cursor: default;
    }

    .emp-actionIcon {
        margin: 0;
        padding: 3px;
    }
}

//** Actions Cell **//
#caseMgmtActionsCell {
    display: flex;
    align-items: center;
    justify-content: center;
}