//** Case QC Review **//
#caseQcReviewViewCaseView {
    .emp-adminCasesContainer {
        padding: 32px 32px 0 !important;
    }

    .emp-headerContainer {
        padding: 10px 0px 0px 0px;
        position: sticky;
        top: 64px;
        background-color: $white-color;

        &>div:first-child {
            font-size: 14px;
        }
    }

    .emp-headerContent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
        width: 100%;
    }

    .emp-headerText {
        font-weight: 700;
        margin-right: 4px
    }

    .emp-actionBtn {
        padding: theme-spacing(0.4px) theme-spacing(1px);
        font-weight: 700;
        margin: 0px 4px;
        text-transform: capitalize;
    }

    .emp-grayActionBtn {
        padding: theme-spacing(0.4px) theme-spacing(1px);
        font-weight: 700;
        margin: 0px 4px;
        text-transform: capitalize;
    }

    .emp-iconBtn {
        margin-right: 5px;
    }

    .emp-gridContainer {
        display: grid;
        gap: 16px;
        grid-template-rows: auto;
        grid-template-columns: 1fr;
        margin-top: 10px;

        @include theme-breakpoint('up', $sm-screen-size) {
            grid-template-columns: repeat(4, 1fr);
            gap: 24px;
        }
    }

    .emp-caseInfoContainer {
        grid-column: span 4;

        @include theme-breakpoint('up', $md-screen-size) {
            grid-column: span 1;
        }
    }

    .emp-casePreviewContainer {
        grid-column: span 4;
        min-height: calc(100vh - 265px);

        @include theme-breakpoint('up', $md-screen-size) {
            grid-column: span 3;
        }
    }

    .emp-documentIframe {
        display: unset !important;
        width: 100%;
        min-height: calc(100vh - 265px);
        margin-bottom: theme-spacing(1px);
    }

    .emp-footerContainer {
        padding: 0px 0px 10px 0px;
        position: sticky;
        bottom: 0px;
        background-color: $white-color;
    }

    .emp-footerContent {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .emp-pdfLoadingSpinnerContainer {
        padding-top: theme-spacing(4px);
        text-align: center;

        & .MuiCircularProgress-root {
            width: 150px !important;
            height: 150px !important;
        }
    }

    .emp-noMoreQCCases {
        text-align: center;
    }

    .emp-noMoreQCCasesNotification {
        margin: theme-spacing(2px);
        padding: theme-spacing(2px);
        border: 1px solid #ccc;
    }

    .emp-circularProgress {
        width: 15px !important;
        height: 15px !important;
        vertical-align: middle;
        color: #FFFFFF;
    }
}