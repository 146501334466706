.emp-confirmCaseBuilderSummaryStep4-divider {
    margin: 10px 0px;
}

.emp-confirmCaseBuilderSummaryStep4-sectionOneContainer {
    margin-top: 20px;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(1, 1fr);

    @include theme-breakpoint('up', $sm-screen-size) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.emp-confirmCaseBuilderSummaryStep4-sectionTwoContainer {
    margin-top: 40px
}

.emp-confirmCaseBuilderSummaryStep4-casesHeaderContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.emp-confirmCaseBuilderSummaryStep4-exclamationCircleIcon {
    margin-right: 5px;
    color: #B91200;
    font-size: 25px;
}

.emp-confirmCaseBuilderSummaryStep4-casesGridContainer {
    margin-top: 20px;
}

.emp-confirmCaseBuilderSummaryStep4-editLink {
    color: #B91200;
    cursor: pointer;
}