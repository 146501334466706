//** Users AG **//
#usersNewView {
    .emp-accentArea {
        padding: theme-spacing(1px) !important;
        padding-top: theme-spacing(4px) !important;
    }

    .emp-addUserBtnText {
        margin-left: theme-spacing(1px);
    }
}