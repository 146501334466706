//** Alert History **//
#preChargebacksAGAlertsAlertHistory {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .emp-historyContainer {
        display: flex;
        flex-direction: column;
        row-gap: theme-spacing(1px);
    }

    .emp-historyItem {
        display: flex;
        align-items: baseline;
        justify-content: flex-start;
        column-gap: theme-spacing(2px);
    }

    .emp-blueLabel {
        display: inline-block;
        margin: 2px;
        padding: 2px 8px;
        color: $white-color;
        background-color: #5bc0de;
    }
}