.emp-flexAForm-root {
    background-color: #f5f5f5;
    padding: 10px;
}

.emp-flexAForm-container {
    display: grid;
    gap: 0.25rem 1rem;

    @include theme-breakpoint('up', $sm-screen-size) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include theme-breakpoint('up', $md-screen-size) {
        grid-template-columns: repeat(3, 1fr);
    }

    @include theme-breakpoint('up', $lg-screen-size) {
        grid-template-columns: repeat(5, 1fr);
    }

    & .MuiFormGroup-root {
        padding: 7px;
    }

    & .MuiInput-root {
        border-radius: 4px;
    }

    & .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input {
        padding: 0;
    }
}

.emp-flexAForm-field {
    display: grid;
    grid-template-columns: 1fr;
}

.emp-flexAForm-fieldLabel {
    margin-bottom: 5px;
    font-style: normal;
    font-size: 12.8px;
    color: #545454;
    font-weight: 600;
}

.emp-flexAForm-hasAmountSelect {
    grid-template-columns: 1fr 3fr;
}

.emp-flexAForm-fieldSelect {
    background-color: white;
    border: solid 1px #e0e0e0;
    text-align: center;

    & .MuiOutlinedInput-input {
        padding: 5px;
    }

    & .MuiInput-input:focus {
        background-color: $white-color;
    }
}

.emp-flexAForm-fieldSelectItem {
    padding: 0 0.5rem;
}

.emp-flexAForm-fieldInput {
    background-color: white;
    border: solid 1px #e0e0e0;
    
    & .MuiInputBase-input {
        height: 20px;
        padding: 7px;
    }
}

.emp-flexAForm-dateInput {
    background-color: white;
    display: flex;
    justify-content: flex-start;
}

.emp-flexAForm-formBtns {
    grid-column-start: 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    padding: 7px;

    &>button {
        padding: theme-spacing(0.4px) theme-spacing(0.8px);
    }
}

.emp-flexAForm-unknownPlaceholder {
    color: #900;
    text-align: center;
    font-weight: 600;
}

.emp-flexAForm-clearSearchButton {
    background-color: #aaaaaa;
    color: white;

    &:hover {
        background-color: #d5d5d5;
    }
}

.emp-flexAForm-dateRangePicker {
    width: 100%;
}

.emp-flexAForm-fieldAutoComplete {
    min-height: 34px;
    background: $white-color;
    border: solid 1px #e0e0e0;

    & .MuiAutocomplete-inputRoot {
        min-height: 34px;
    }

    & .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
        padding-left: 7px;
        padding-bottom: 3px;
        padding-top: 3px;
    }

    & .MuiAutocomplete-inputRoot[class*='MuiInput-root'] .MuiAutocomplete-input:first-child {
        padding: 5px;
    }
}