//** Dashboard **//
#chargebacksNewView {
    .emp-topContainer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .emp-accentArea {
        padding: theme-spacing(1px) !important;
        padding-top: theme-spacing(4px) !important;
    }

    .emp-caseFilterArea {
        width: 100%;
    }

    .emp-advFilterContent {
        margin-bottom: theme-spacing(2px);
    }

    .emp-advSearchBtn {
        padding: theme-spacing(0.4px) theme-spacing(1px);
        font-weight: 500;
        margin-bottom: theme-spacing(0.5px);
    }

    .emp-advSearchIcon {
        margin-right: 6px;
    }

    .emp-gridWrapperDefault {
        display: flex;
        align-items: flex-start;
        flex-wrap: nowrap;

        @include theme-breakpoint('down', $lg-screen-size) {
            align-items: center;
            flex-wrap: wrap;
        }
    }

    .emp-marginRight {
        margin-right: theme-spacing(1px);
    }

    .emp-filterAlertContainer {
        margin-left: 10px;
    }

    .emp-autoAcceptBtn {
        margin-left: 5px;
        margin-bottom: 4px;
        text-transform: uppercase;
        padding: theme-spacing(0.4px) theme-spacing(1px);
    }

    .emp-helpLink {
        color: '#999 !important';
        font-weight: 600;
        padding-left: theme-spacing(1px);
        font-size: '14px';
    }

}