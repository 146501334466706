.emp-alertsIframe-root {
    margin-top: -8px;
    margin-left: -16px;
    margin-right: -34px;

    @include theme-breakpoint('down', 1280) {
        margin-right: -16px;
    }
}

.emp-alertsIframe-container {
    display: unset !important;
    width: 100%;
    min-height: calc(100vh - 90px);
    border: none;
}