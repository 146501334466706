.emp-caseHistoryModal-modalContainer {
    & .MuiDialog-paperFullWidth {
        width: 700px;
    }
}

.emp-caseHistoryModal-gridContainer {
    & .ag-root-wrapper-body {
        max-height: 275px;
    }
}

.emp-caseHistoryModal-dataGrid {
    & .ag-tooltip {
        white-space: nowrap;
    }
}