.emp-popoverSelect-root {
    background-color: white;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-width: 150px;
}

.emp-popoverSelect-displayOutlined {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 6px 7px;
    line-height: 1.44;
    height: 100%;
    min-width: 140px;
}

.emp-popoverSelect-displayStandard {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.25rem;
    height: 100%;
    min-width: 140px;
}

.emp-popoverSelect-selectedValueText {
    overflow-x: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
}

.emp-popoverSelect-popover {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.emp-popoverSelect-popoverPaper {
    border: solid 1px rgb(204, 204, 204);
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    overflow-y: hidden;
}

.emp-popoverSelect-options {
    list-style: none;
    max-width: 300px;
    padding: 0;

    &>li {
        padding: 4px 8px 4px 4px;
        padding-left: 8px;
    }
    max-height: 275px;
    overflow-y: auto;
    margin-top: 0;
    margin-bottom: 0;
}

.emp-popoverSelect-icon {
    color: rgba(0, 0, 0, 0.54);
}

.emp-popoverSelect-searchTextField {
    padding: 2px 5px;
    margin-top: 5px;
}

.emp-popoverSelect-textFieldLabel {
    position: relative;
    top: 7px;
    left: 9px;
    padding: 0px 5px;
    background-color: $white-color;
    width: fit-content;
    font-size: 10px;
}
