//** Case Management **//
#caseManagementLegacyView {
    .emp-topContainer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .emp-advancedFilterContent {
        margin-bottom: theme-spacing(2px);
    }

    .emp-advSearchBtn {
        padding: theme-spacing(0.4px) theme-spacing(1px);
        font-weight: 500;
        margin-bottom: theme-spacing(0.5px);
    }

    .emp-advSearchIcon {
        margin-right: 6px;
    }

    .emp-tabRoot {
        min-height: 20px;
        min-width: 100px;
        text-transform: capitalize;
        margin-right: theme-spacing(0.3px);
        padding: 10px 12px;
    }

    .emp-activeTab {
        background: $white-color !important;
        border-bottom: none !important;
        opacity: 1 !important;
    }

    .emp-tab {
        background: #fafafa;
        border: solid .5px #a8a8a8;

        &:first-child {
            border-top-left-radius: 4px;
        }

        &:last-child {
            border-top-right-radius: 4px;
        }

        opacity: 0.7;
    }

    .emp-tabLabel {
        font-weight: 700;
        color: #525252;
        font-size: .9rem;
        display: flex;
        align-items: center;
    }

    .emp-filterAlertContainer {
        margin-left: 10px;
    }

    .emp-upgradeAlertContainer {
        border: 1px solid #ebccd1;
        margin: 10px 0px 20px 0px;
        background-color: #f2dede;
        color: #a94442;
        padding: 15px;
        border-radius: 4px;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .emp-upgradeAlertBtn {
        text-transform: capitalize;
        font-size: 12px;
        background-color: #c80000;

        &:hover {
            background-color: #af2222;
        }
    }

    .emp-exitAlertBtn {
        margin: 0;
        padding: 0;
    }

    .emp-autoAcceptBtn {
        margin-left: 5px;
        margin-bottom: 4px;
        text-transform: uppercase;
        padding: theme-spacing(0.4px) theme-spacing(1px);
    }
}