//** Merchant Details **//
#merchantSettingsMerchantDetails {
    .emp-root {
        padding: 0 theme-spacing(1px);
    }

    .emp-headerContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 5px 6px 5px;
    }

    .emp-readOnlyTitleContainer {
        display: flex;
        align-items: center;
        padding: 10px 5px 10px 0px;
        flex-grow: 1;
    }

    .emp-titleContainer {
        @extend .emp-readOnlyTitleContainer;

        &:hover {
            cursor: pointer;
        }
    }

    .emp-titleIcon {
        margin: 0px 10px;
        cursor: pointer;
    }

    .emp-merchantTitle {
        font-weight: 600;
        font-size: 18px;
    }

    .emp-merchantMenuBtn {
        padding: 5px;
        width: 27px;
        margin-left: 2px;
    }

    .emp-merchantMenuIcon {
        font-size: 15px;
    }

    .emp-detailsMainContainer {
        border-top: 1px solid #e9e9e9;
        padding: theme-spacing(2px) 0;
    }

    .emp-loadingContainer {
        min-height: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .emp-merchantLabel {
        font-weight: 600;
    }

    .emp-servicesContainer {
        display: grid;
        grid-column-gap: theme-spacing(1.5px);
        grid-row-gap: theme-spacing(1.5px);
        grid-template-columns: repeat(5, 1fr);

        @include theme-breakpoint('down', 1280) {
            grid-template-columns: repeat(4, 1fr);
        }

        @include theme-breakpoint('down', 950) {
            grid-template-columns: repeat(3, 1fr);
        }

        &>div {
            border: 2px solid #e9e9e9;
            border-radius: 4px;
            padding: theme-spacing(0.75px);
            box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
                0px 1px 1px 0px rgba(0, 0, 0, 0.14),
                0px 1px 3px 0px rgba(0, 0, 0, 0.12);
        }
    }

    .emp-loadingStatusContainer {
        text-align: center;
    }

    .emp-statusContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

#merchantSettingsMerchantDetailsPopover {
    .emp-merchantMenuRoot {
        padding: 0;
    }

    .emp-merchantMenuItem {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
        min-width: 150px;

        &>span {
            font-size: 15px;
            width: 25px;
        }

        &:hover {
            color: $white-color;

            &>span {
                color: $white-color;
            }
        }
    }
}