//** Delete Template **//
#templateLibraryDeleteTemplate {
    padding: theme-spacing(1px);
    text-align: center;

    .emp-dialogActions {
        display: flex;
        justify-content: center;

        &>button {
            text-transform: capitalize;
            font-size: 1.0625rem;
            padding: 6px 26px;
            margin: 4px;
        }
    }

    .emp-dialogContent {
        padding-bottom: theme-spacing(3px);
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .emp-dialogTitle {
        display: flex;
        flex-direction: column;
        margin-bottom: -32px;
        color: #595959;
        font-weight: 500;
        font-size: 2rem;

        &>p {
            padding: 0;
            margin: 0;
        }
    }

    .emp-dialogContentText {
        display: flex;
        justify-content: center;
        max-width: 100%;
        color: #57595E;
        font-size: 1.15rem;
        line-height: 18px;
        margin-bottom: -12px;
        margin-top: 10px;
    }

    .emp-contentText {
        width: 95%;
        padding: 0;
    }

    .emp-cancelBtn {
        background: #AAAAAA;
        color: white;

        &:hover {
            background-color: #D5D5D5;
        }
    }

    .emp-exlamationIcon {
        width: 95px;
        height: 95px;
        border-radius: 50%;
        border: solid .25rem #facea8;
        color: #facea8;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 4.75em;
        margin-top: theme-spacing(2px);
    }
}