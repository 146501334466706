//** MTD Reports **//
#mtdView {
    .emp-headerContent {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        @include theme-breakpoint('up', $md-screen-size) {
            flex-direction: row;
        }
    }

    .emp-header {
        display: flex;
        flex-wrap: wrap;
        padding-top: 10px;
        align-items: center;
        max-width: 100vw;

        & h5 {
            color: #666;
            font-size: 14px;
            font-weight: 600;
        }
    }

    .emp-divider {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .emp-dateInputParent {
        max-width: 140px;
    }

    .emp-dateInput {
        padding: theme-spacing(1px);
        max-width: 140px;
    }

    .emp-pickerMonths {
        max-width: 125px;
    }

    .emp-pickerYears {
        max-width: 100px;
    }

    .emp-disableBtn {
        text-transform: capitalize;
        color: white !important;
    }

    .emp-captions {
        font-style: italic;
        color: #000;
    }

    .emp-tooltipPopper {
        background: #020202;
        color: $white-color;
    }

    .emp-tooltipPopperArrow {
        &::before {
            background: #020202;
        }
    }
}