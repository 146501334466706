.emp-uploadRepresentmentsVC3-rootContainer {
    display: flex;
}

.emp-uploadRepresentmentsVC3-loadingContainer {
    margin: theme-spacing(2px);
    width: 100%;
    display: flex;
    justify-content: center;
}

.emp-uploadRepresentmentsVC3-errorContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: theme-spacing(3px);
}

.emp-uploadRepresentmentsVC3-errorMsgContainer {
    padding: theme-spacing(2px);
    border: 1px solid #c00;
    border-radius: 4px;

    & h3 {
        margin: 0 0 theme-spacing(2px);
        font-size: 14px;
    }
}

.emp-uploadRepresentmentsVC3-errorBtnContainer {
    display: flex;
    justify-content: center;
}

.emp-uploadRepresentmentsVC3-mainContainer {
    display: grid;
    width: 100%;
}

.emp-uploadRepresentmentsVC3-uploadContainer {
    display: flex;
    flex-direction: column;
    row-gap: theme-spacing(1px);
}

.emp-uploadRepresentmentsVC3-caseCommentContainer {
    width: 100%;

    &>p {
        font-size: 14px;
        font-weight: 600;
    }

    & .MuiOutlinedInput-root {
        padding: 7px;
    }
}

.emp-uploadRepresentmentsVC3-isQCReviewContainer {
    display: flex;
    align-items: center;
    width: 100%;
    padding: theme-spacing(0.5px) theme-spacing(2px);
    font-weight: 600;
    color: #333;
    background-color: #e5f6fd;
    border-radius: 2px;

    & .MuiFormControlLabel-label {
        font-weight: 600;
    }

    & .MuiFormControlLabel-root {
        margin-right: theme-spacing(0.5px);
    }
}

.emp-uploadRepresentmentsVC3-isQCReviewHelp {
    margin-bottom: theme-spacing(0.5px);
}

.emp-uploadRepresentmentsVC3-isQCReviewPopover {
    width: 255px;
    padding: theme-spacing(0.5px);
    border: 1px solid #42a5f5;
}

.emp-uploadRepresentmentsVC3-divider {
    margin: 0 theme-spacing(2px);
    border-right-width: medium;

    @include theme-breakpoint('down', 800) {
        margin: theme-spacing(2px) 0;
        border-bottom-width: medium;
    }
}

.emp-uploadRepresentmentsVC3-CEContainer {
    display: flex;
    flex-direction: column;
}

.emp-uploadRepresentmentsVC3-uploadIcon {
    margin-right: 5px;
    font-size: 20px;
}