.emp-passwordChange-rules {
    margin-bottom: theme-spacing(1px);
    font-size: 12px;
    color: #0c365b;
    border-radius: 5px;

    & ul {
        margin: 5px 0 0;
        padding: 0 20px;
    }
}

.emp-passwordChange-inputs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: theme-spacing(1px);

    &>* {
        margin-top: theme-spacing(2px);
    }
}

.emp-passwordChange-errorText {
    margin: 3px 14px 0;
}