//** Case Submission Activity Filters **//
#monitoringCaseSubmissionActivityFilters {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;

    .emp-inputContainer {
        min-width: 185px;
        margin: 2px 4px 2px 0px;
    }

    .emp-dateRangePicker {
        width: 200px;
        margin: 2px 4px 2px 0px;
    }

}