//** ERT AG **//
#ertView {
    .emp-actionsContainer {
        display: flex;
        justify-content: flex-end;
        column-gap: theme-spacing(1px);
    }

    .emp-actionBtns {
        text-wrap: nowrap;
    }

    .emp-accentAreaContainer {
        padding: theme-spacing(1px) !important;

        @include theme-breakpoint('up', $md-screen-size) {
            padding: theme-spacing(1px) !important;
            padding-top: theme-spacing(4px) !important;
        }
    }

    // Located in the folllowing files:
    // - ClientFilter.tsx
    // - AddEditERT.tsx
    .emp-searchableText {
        margin: 2px 10px;
    }
}