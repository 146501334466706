.emp-standardModal-dialogTitle {
    padding: theme-spacing(1.5px);
    background-color: #F1F1F1;
    font-size: 24px;
    font-weight: 600;
    color: #546e7a;
}

.emp-standardModal-errorDialogTitle {
    background-color: red;
    color: #fff;
}

.emp-standardModal-dialogContent {
    padding: 20px !important;
}

.emp-standardModal-dialogActions {
    padding: 20px;
    padding-top: theme-spacing(1.5px);
    justify-content: center;
    align-items: center;
    column-gap: theme-spacing(1px);
}

.emp-standardModal-modalClose {
    position: absolute;
    top: 12px;
    right: 14px;
    cursor: pointer;
    font-size: 15px;
}

.emp-standardModal-circularProgress {
    width: 15px !important;
    height: 15px !important;
    vertical-align: middle;
    color: #FFFFFF;
}