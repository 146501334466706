.emp-uploadCaseBuilderFileDnD-documentTypeInfoContainer {
    padding: theme-spacing(2px);
}

.emp-uploadCaseBuilderFileDnD-uploadIcon {
    background-repeat: no-repeat;
    background-position: center 10px;
    min-height: 200px;
    background-size: 190px;
    display: grid;
    place-items: end center;
    width: 100%;
}

.emp-uploadCaseBuilderFileDnD-info {
    margin-left: 5px;
    color: #c00;
    font-weight: 600;
}

.emp-uploadCaseBuilderFileDnD-btnsContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: theme-spacing(1px);
    margin-bottom: theme-spacing(2px);
}

.emp-uploadCaseBuilderFileDnD-btn {
    padding: 4px;
    text-transform: capitalize;
    margin: 4px;
}