.emp-agDataGrid-noZebraStriping {
    --ag-background-color: $white-color;
    --ag-odd-row-background-color: $white-color;

    .ag-row-odd {
        background-color: $white-color,
    }
}

.emp-agDataGrid-noTopBorder {
    & .ag-root-wrapper {
        border-top: none;
    }

    .ag-pinned-right-header {
        border: none
    }

    .ag-pinned-left-header {
        border: none
    }

    .ag-side-bar-right {
        border-left: none;
    }

    .ag-side-bar-right .ag-tool-panel-wrapper {
        border-right: none;
    }

    .ag-column-select-list {
        border-left: 1px solid #e9e9e9;
        border-right: 1px solid #e9e9e9;
    }
}

.emp-agDataGrid-autoVerticalScroll {
    & .ag-force-vertical-scroll {
        overflow-y: auto !important;
    }
}

.emp-agDataGrid-bottomBarContainer {
    padding: theme-spacing(1px);
    padding-bottom: 0px;
}