//** Actions Cell **//
#qcReviewAGActionsCell {
    display: flex;
    justify-content: center;
    align-items: center;

    .emp-icon {
        width: 18px;
        height: 18px;

        &:hover {
            cursor: pointer;
        }
    }

    .emp-disabledIcon {
        @include disable-icon;

        &:hover {
            cursor: default;
        }
    }
}