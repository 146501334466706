//** Actions Cell **//
#editOnboardingAGActionsCell {
    display: flex;
    justify-content: center;
    align-items: center;

    .emp-icon {
        font-size: 18px;
        margin: 4px;

        &:hover {
            cursor: pointer;
        }
    }

    .emp-iconContainer {
        padding: 0px 4px;
    }

    .emp-disabledIcon {
        @include disable-icon;
        font-size: 18px;
        margin: 4px;

        &:hover {
            cursor: default;
        }
    }

    .emp-validateIcon {
        &:hover {
            cursor: default;
        }
    }
}

//** Parent Merchant Cell **//
#editOnboardingAGParentMerchantCell {}

//** Service Level Cell **//
#editOnboardingAGServiceLevelCell {}

//** Platform Cell **//
#editOnboardingAGPlatformCell {}