.emp-agDataGridActionsBar-root {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: theme-spacing(1px);
    margin-bottom: theme-spacing(1px);
    flex-wrap: wrap;
    column-gap: theme-spacing(.5px);
    row-gap: theme-spacing(.5px);

    @include theme-breakpoint('down', $sm-screen-size) {
        justify-content: center;
        flex-wrap: wrap;
        row-gap: 8px;
    }
}

.emp-agDataGridActionsBar-searchContainer {
    display: flex;
    align-items: center;

    @include theme-breakpoint('down', $xs-screen-size) {
        justify-content: center;
        flex-wrap: wrap;
        row-gap: 8px;
    }
}

.emp-agDataGridActionsBar-searchInput {
    padding: theme-spacing(1px);
    border-radius: 3px;
    text-align: left;
    outline: none;
    border: solid 1px #a8a8a8;
}

.emp-agDataGridActionsBar-filterTagContainer {

    @include theme-breakpoint('down', $xs-screen-size) {
        text-align: center;
    }
}

.emp-agDataGridActionsBar-filterTag {
    margin: 1px 2px;
}

.emp-agDataGridActionsBar-actionBtnsContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: nowrap;
    padding-top: 1px;
    min-width: 310px;
    column-gap: theme-spacing(0.5px);
}

.emp-agDataGridActionsBar-btn {
    min-width: 40px;
    padding: 3px 10px;
}