.emp-merchantMidsDetails-root {
    font-size: 12px;
    margin: 10px 15px 0 0;
}

.emp-merchantMidsDetails-label {
    color: #999;
    font-size: 14px;
}

.emp-merchantMidsDetails-leftGrid {
    padding-right: theme-spacing(2px);
    border-right: 1px solid #eeeeee;
    display: block;
}

.emp-merchantMidsDetails-rightGrid {
    padding-left: theme-spacing(2px);
}

.emp-merchantMidsDetails-middleLeftGrid {
    margin-top: theme-spacing(1px);
}

.emp-merchantMidsDetails-innerLeftGrid {
    margin: 3px 0 7px 0;
}

.emp-merchantMidsDetails-lampWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
}

.emp-merchantMidsDetails-lamp {
    margin-right: theme-spacing(1px);
}

.emp-merchantMidsDetails-radiosContainer {
    margin-top: theme-spacing(1px);
    margin-bottom: theme-spacing(2px);

    &>label>.MuiRadio-root {
        padding: 2px 5px;
    }

    & .MuiTypography-body1 {
        font-size: 14px;
    }
}

.emp-merchantMidsDetails-merchantValue {
    margin: 5px 0 10px 0;
    font-size: 14px;
}

.emp-merchantMidsDetails-switchBtn {
    & .MuiSwitch-colorSecondary.Mui-disabled {
        color: #bdbdbd;
    }

    & .MuiSwitch-colorSecondary.Mui-disabled+.MuiSwitch-track {
        background-color: #000;
    }
}

.emp-merchantMidsDetails-innerGrid {
    margin: 3px 0 7px 0;
}

.emp-merchantMidsDetails-midHistoryHeader {
    margin: theme-spacing(1px) 0px;
    color: #666;
    font-size: 14px;
    font-weight: 600;
}