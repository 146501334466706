//** Add Edit MID Form **//
#merchantSettingsAddEditMidForm {
    .emp-container {
        display: flex;
        flex-direction: column;
        row-gap: 12px;

        @include theme-breakpoint('up', $md-screen-size) {

            flex-direction: row;
            column-gap: theme-spacing(2px);
        }
    }

    .emp-sectionContainerLeft {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: theme-spacing(1px);
        column-gap: theme-spacing(1px);
        align-content: flex-start;
    }

    .emp-sectionContainerRight {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .emp-csvUploadContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: theme-spacing(1px);
        margin-bottom: theme-spacing(1px);
        background-color: #f3f3f3;
        width: 100%;

        &>span {
            font-weight: 600;
            color: #666;
        }
    }

    .emp-label {
        margin-bottom: theme-spacing(0.75px);
        font-weight: 600;
    }

    .emp-errorText {}

    .emp-inputHalfWidth {
        flex-basis: 49%;

        @include theme-breakpoint('down', $sm-screen-size) {
            flex-grow: 1;
        }

    }

    .emp-inputHalfWidthContainer {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
    }


    .emp-switchBtn {
        & .MuiSwitch-colorSecondary.Mui-disabled {
            color: #bdbdbd;
        }

        & .MuiSwitch-colorSecondary.Mui-disabled+.MuiSwitch-track {
            background-color: #000;
        }
    }

    .emp-overallStatusContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }


    .emp-mainStatusContainer {
        width: 100%;

        &>:last-child {
            margin-bottom: 0px;
        }
    }

    .emp-inheritAlertContainer {
        text-align: center;
        font-weight: 600;
        background-color: #fff6e9;
        padding: 2px 4px;
        border-radius: 3px;
        margin: theme-spacing(.75px)
    }

    .emp-serviceLevelContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .emp-serviceStatusContainer {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-bottom: theme-spacing(1.5px);
    }

    .emp-statusLabel {
        font-weight: 600;
    }

    .emp-statusContainer {
        display: flex;
        align-items: center;
    }

    .emp-dividerHeader {
        margin: theme-spacing(1px) 0;
    }

    .emp-divider {
        width: 100%;
    }

    .emp-editRDRBtn {
        padding: 0;
        margin: 0 theme-spacing(0.5pc);
        height: 22px;
        text-decoration: underline;
        text-transform: capitalize;
    }
}