.emp-uploadCaseBuilderDocumentsStep3-typography {
    font-weight: 600;
}

.emp-uploadCaseBuilderDocumentsStep3-basicUploadContainer {
    display: grid;
    margin-top: 10px;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;

    @include theme-breakpoint('up', $md-screen-size) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.emp-uploadCaseBuilderDocumentsStep3-templateLibraryContainer {
    display: flex;
    height: max-content;

    &>div:first-of-type {
        min-width: 325px;
        width: 100%;
    }

    &>button {
        margin-left: 10px;
    }
}

.emp-uploadCaseBuilderDocumentsStep3-uploadTemplatesContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
}

.emp-uploadCaseBuilderDocumentsStep3-templatesTagContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    background-color: #e0e0e0;
    width: fit-content;
    padding: 3px 5px;
}

.emp-uploadCaseBuilderDocumentsStep3-timesIcon {
    margin-left: 5px;
    color: rgba(38, 50, 56, 0.26);

    &:hover {
        color: rgba(38, 50, 56, 0.4);
        cursor: pointer;
    }
}

.emp-uploadCaseBuilderDocumentsStep3-searchableSelectText {
    margin: 2px 8px;
}

.emp-uploadCaseBuilderDocumentsStep3-divider {
    margin: 10px 0px;
}

.emp-uploadCaseBuilderDocumentsStep3-documentUploadContainer {
    display: grid;
    margin-top: 20px;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;

    @include theme-breakpoint('up', $md-screen-size) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.emp-uploadCaseBuilderDocumentsStep3-documentTypesContainer {
    margin-top: 10px;
}

.emp-uploadCaseBuilderDocumentsStep3-documentType {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0px;
}

.emp-uploadCaseBuilderDocumentsStep3-documentTypeTitleName {
    display: flex;
    align-items: center;
}

.emp-uploadCaseBuilderDocumentsStep3-documentTypeFileName {
    display: flex;
    align-items: flex-start;

    &>span {
        margin: 0px 5px;
        font-weight: 600;
    }
}

.emp-uploadCaseBuilderDocumentsStep3-lampIcon {
    margin-right: 5px;
}

.emp-uploadCaseBuilderDocumentsStep3-verticalGripIcon {
    margin-right: 10px;
    color: 'grey',
}

.emp-uploadCaseBuilderDocumentsStep3-loadingContainer {
    height: 125px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.emp-uploadCaseBuilderDocumentsStep3-noDocTypesFoundTypography {
    margin-top: 10px;
    color: #B91200;
}