//** Add Edit Merchant Form **//
#merchantSettingsAddEditMerchantForm {
    .emp-container {
        display: flex;
        flex-direction: column;
        row-gap: 12px;

        @include theme-breakpoint('up', $md-screen-size) {
            flex-direction: row;
            column-gap: theme-spacing(2px);
        }
    }

    .emp-sectionContainerLeft {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .emp-sectionContainerRight {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    .emp-header {
        text-align: left;
        margin-bottom: theme-spacing(1px);

        &>span {
            font-weight: 600;

        }
    }

    .emp-label {
        margin-bottom: theme-spacing(0.75px);
        font-weight: 600;
    }

    .emp-control {
        margin-bottom: theme-spacing(1px);
    }

    .emp-errorText {}

    .emp-inputHalfWidth {
        width: 49%;
    }

    .emp-inputHalfWidthContainer {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
    }

    .emp-switchBtn {
        & .MuiSwitch-colorSecondary.Mui-disabled {
            color: #bdbdbd;
        }

        & .MuiSwitch-colorSecondary.Mui-disabled+.MuiSwitch-track {
            background-color: #000;
        }
    }

    .emp-overallStatusContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .emp-mainStatusContainer {
        width: 100%;

        &>:last-child {
            margin-bottom: 0px !important;
        }
    }

    .emp-serviceLevelContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .emp-serviceStatusContainer {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-bottom: theme-spacing(1.5px);
    }

    .emp-statusLabel {
        font-weight: 600;
    }

    .emp-statusContainer {
        display: flex;
        align-items: center;
    }

    .emp-divider {
        margin: theme-spacing(1px) 0;
    }

    .emp-editRDRBtn {
        padding: 0;
        margin: theme-spacing(0.5px);
        height: 22px;
        text-decoration: underline;
        text-transform: capitalize;
    }
}