//** Merchant Login Activity **//
#monitoringMerchantLoginActivity {
    .emp-root {
        margin-top: 50px;
    }

    .emp-headerTitle {
        color: #666;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: theme-spacing(1px);
    }

    .emp-dataActionsContainer {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-column-gap: theme-spacing(1px);
        grid-row-gap: theme-spacing(1px);
        margin-bottom: theme-spacing(1px);
        grid-template-rows: auto;

        @include theme-breakpoint('up', $md-screen-size) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    .emp-filtersContainer {
        display: flex;
        justify-content: flex-start;
        flex-wrap: nowrap;
    }

    .emp-exportsContainer {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-wrap: nowrap;
    }

    .emp-dataActionBtn {
        min-width: 0;
        padding: 3px 10px;
        margin-left: 5px;
        margin-bottom: 2px;
    }

    .emp-reportsContainer {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-column-gap: theme-spacing(1px);
        grid-row-gap: theme-spacing(2px);
        grid-template-rows: auto;

        @include theme-breakpoint('up', $md-screen-size) {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}