.emp-advancedForm-root {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: theme-spacing(3px);
    row-gap: theme-spacing(1.5px);
}

.emp-advancedForm-fieldContainer {
    display: flex;
    flex-direction: column;
}

.emp-onlyOneFieldContainer {
    width: 100%;
}

.emp-advancedForm-typography {
    text-align: left;
}

.emp-advancedForm-textField {
    margin-top: 5px;
    min-width: 290px;

    & .MuiInputBase-input {
        padding: 7px;
    }
}

.emp-advancedForm-selectField {
    @extend .emp-advancedForm-textField;
}

.emp-advancedForm-divCheckbox {
    font-weight: 600;
    display: flex;
    align-items: center;
}