.emp-ert-addEditErt-fullWidthForm {
    width: 100%;
}

.emp-ert-addEditErt-inputContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: theme-spacing(1px);
    margin-bottom: theme-spacing(1px);

    &>label {
        width: 15%;
        margin-right: theme-spacing(1px);
    }
}

.emp-ert-addEditErt-inputWrapper {
    width: 75%;
}

.emp-ert-addEditErt-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 550px;
    min-width: 550px;

    @include theme-breakpoint('down', $lg-screen-size) {
        width: 95%;
    }
}

.emp-ert-addEditErt-headerBanner {
    background: #E0E0E0;
    display: flex;
    justify-content: space-between;
    padding: 12px 18px;
    margin-bottom: theme-spacing(2px);
    font-size: 1.2rem;
    color: #505050;

    &>span svg {
        margin-right: theme-spacing(1px);
    }
}

.emp-ert-addEditErt-btnGrp {
    display: flex;
    justify-content: center;
    margin-top: theme-spacing(2px);
    margin-bottom: theme-spacing(2px);
}

.emp-ert-addEditErt-btn {
    border-radius: 3px;
    padding: 4px;
    text-transform: capitalize;
    margin: 4px;

    & svg:not([class^="MuiCircularProgress"]) {
        margin-left: theme-spacing(1px);
    }
}

.emp-ert-addEditErt-circularProgress {
    width: 15px !important;
    height: 15px !important;
    vertical-align: middle;
}