//** Merchant Mids Grid Details **//
#merchantSettingsMerchantMidsGridDetails {
    .emp-root {
        padding: 15px;
        width: 350px;
        user-select: text;
    }

    .emp-noSelectedRowRoot {
        padding: 15px;
        text-align: center;
        font-weight: 600;
        width: 350px;
        user-select: text;
    }

    .emp-headerContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .emp-header {
        font-weight: 600;
        font-size: 18px;
    }

    .emp-iconContainer {
        display: flex;
        align-items: center;
        gap: 4px;
    }

    .emp-disabledIcon {
        @include disable-icon;
    }

    .emp-detailContainer {
        display: flex;
        margin: 5px 0px;
    }

    .emp-label {
        min-width: 165px;
        text-align: left;
        margin-right: theme-spacing(0.5px);
        font-weight: 600,
    }

    .emp-statusContainer {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .emp-divider {
        margin: theme-spacing(1px) 0;
    }
}