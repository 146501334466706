//** Flag Cell **//
#reconciliationReportAGFlagCell {
    .emp-actionIcon {
        width: 18px;
        height: 18px;
        margin-bottom: 5px;
    }

    .emp-disabledIcon {
        @include disable-icon;
        cursor: default;
    }
}

//** Actions Cell **//
#reconciliationReportAGActionsCell {
    .emp-tooltipPopper {
        background: #020202;
        color: $white-color;
    }

    .emp-tooltipPopperArrow {
        &::before {
            background: #020202;
        }
    }
}


//** Percent Value Cell **//
#reconciliationReportAGPercentValueCell {}