//** Test Webhook **//
#webhooksTestWebhook {
    .emp-info {
        margin-bottom: 10px;
    }

    .emp-requestContainer {
        text-align: left;
        border: 1px solid rgba(0, 0, 0, 0.10);
        padding: 5px;
        margin: 10px 0px 5px 0px;
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.03);
        max-width: 1000px;
        word-break: break-all;
    }

    .emp-responseContainer {
        text-align: left;
        border: 1px solid rgba(0, 0, 0, 0.10);
        padding: 5px;
        margin-top: 10px;
        border-radius: 4px;
    }

    .emp-typographyLabel {
        font-weight: 600
    }

    .emp-btnGrp {
        display: flex;
        justify-content: center;
    }

    .emp-btn {
        text-transform: capitalize;

        & svg:not([class^="MuiCircularProgress"]) {
            margin-left: theme-spacing(1px);
        }
    }

    .emp-circularProgress {
        width: 15px !important;
        height: 15px !important;
        vertical-align: middle;
        color: $white-color;
    }
}