.emp-pagination-root {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: theme-spacing(1px);
    flex-wrap: wrap-reverse;
    flex-direction: row;

    @include theme-breakpoint('up', $md-screen-size) {
        justify-content: center;
        flex-direction: column;
    }

    @include theme-breakpoint('up', 1700) {
        justify-content: space-between;
        flex-direction: row;
    }
}

.emp-pagination-paginationControl {
    display: flex;
    align-items: center;
    color: #464646;
    font-size: 15px;
    margin-top: 20px;
    padding-right: 20px;

    &>p {
        margin: 0px;
    }

    @include theme-breakpoint('up', $md-screen-size) {
        padding-right: 0;
        padding-left: 0;
    }

    @include theme-breakpoint('up', 1700) {
        padding-right: 10px;
        padding-left: 10px;
    }
}

.emp-pagination-showingResults {
    color: #464646;
    font-size: 15px;
    margin-top: 20px;
    padding-left: 20px;

    &>p {
        margin: 0;
    }

    @include theme-breakpoint('up', $md-screen-size) {
        padding-right: 0;
        padding-left: 0;
    }

    @include theme-breakpoint('up', 1700) {
        padding-right: 10px;
        padding-left: 10px;
    }
}

.emp-pagination-activeIcon {
    font-size: 11px;

    &:hover {
        cursor: pointer;
    }
}

.emp-pagination-inactiveIcon {
    font-size: 11px;
    color: #464646;
}