//** Chargebacks **//
#chargebacksView {

    .emp-topContainer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .emp-caseFilterArea {
        width: 100%;
    }

    .emp-advancedFilterContent {
        margin-bottom: theme-spacing(2px);
    }

    .emp-advSearchBtn {
        padding: theme-spacing(0.4px) theme-spacing(1px);
        font-weight: 500;
        margin-bottom: theme-spacing(0.5px);
    }

    .emp-accentArea {
        margin-right: theme-spacing(2px);
    }

    .emp-advSearchIcon {
        margin-right: 6px;
    }

    .emp-gridWrapperDefault {
        display: flex;
        align-items: flex-start;
        flex-wrap: nowrap;

        @include theme-breakpoint('down', $lg-screen-size) {
            align-items: center;
            flex-wrap: wrap;
        }
    }

    .emp-marginRight {
        margin-right: theme-spacing(1px);
    }

    .emp-filterAlertContainer {
        margin-left: 10px;
    }

    .emp-searchbar {
        margin-top: 10px;
    }

    .emp-autoAcceptBtn {
        margin-left: 5px;
        margin-bottom: 4px;
        text-transform: uppercase;
        padding: theme-spacing(0.4px) theme-spacing(1px);
    }

    .emp-helpLink {
        color: #999 !important;
        font-weight: 600;
        padding-left: theme-spacing(1px);
        font-size: 14px;
    }

    .emp-alertBannerContainer {
        border: 1px solid #e9e9e9;
        background-color: #fff;
        padding: theme-spacing(1px);
        border-radius: 4px;
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: theme-spacing(1px);

        @include theme-breakpoint('up', $md-screen-size) {
            margin-left: 15px;
        }
    }

    .emp-alertBannerText {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        column-gap: theme-spacing(.5px);
    }

    .emp-alertBannerActions {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        column-gap: theme-spacing(1px);
    }

    .emp-alertBannerBtn {
        text-transform: capitalize;
        font-size: 12px;
        background-color: #ff0000;
        white-space: nowrap;

        &:hover {
            background-color: #e20000;
        }
    }

    .emp-alertBannerClose {
        position: relative;
        cursor: pointer;
        font-size: 15px;
        color: #546e7a;
    }
}