.emp-placeholder-pGraph-graph {
    height: 100%;
}

.emp-placeholder-pGraph-header {
    text-align: center;
    border-bottom: 1px solid #e5e5e5;
}

.emp-placeholder-pGraph-pieChartContent {
    display: flex;
    justify-content: center;
}