.emp-filterAlert-root {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.emp-filterAlert-text {
    font-size: 14px;
    color: #A94442;
    margin-left: 10px;
}