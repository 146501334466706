:root {
    --amplify-primary-color: #42a5f5;
    --amplify-primary-tint: #42a5f5;
    --amplify-primary-shade: #42a5f5;
}

amplify-authenticator {
    --padding: 0 40px 35px;
    --container-align: top;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

//** VARIABLES **//
// Styles:
$auth-font-family: "Source Sans Pro", Arial, "-apple-system", BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, Arial, sans-serif;

// Colors:
$white-color: #fff;
$black-color: #000;
$primary-text-color: #263238;

// Screen Sizes:
$xs-screen-size: 0;
$sm-screen-size: 600;
$md-screen-size: 900;
$lg-screen-size: 1200;
$xl-screen-size: 1536;

//** FUNCTIONS **//
@function theme-spacing($value) {
    @return $value * 8;
}

//** MIXINS **//
@mixin theme-breakpoint($direction, $screen) {

    // $direction = 'up' | 'down'
    @if $direction =='up' {
        @media (min-width:#{$screen}px) {
            @content;
        }
    }

    @else {
        @media (max-width:#{$screen}px) {
            @content;
        }
    }
}

@mixin disable-icon {
    opacity: 0.5;
}

//** CONTEXT **//
@import './context/AuthedUser/styles.scss';

//** LAYOUTS **//
@import './layouts/MainLayout/styles.scss';

//** VIEWS **//
@import './views/_placeholders/styles.scss';
@import './views/styles.scss';
@import './views/AddEditMerchant/styles.scss';   
@import './views/AddEditMid/styles.scss';
@import './views/AddEditUser/styles.scss';
@import './views/AdminReview/styles.scss';
@import './views/AdminReviewAGGrid/styles.scss';
@import './views/Alerts/styles.scss';
@import './views/AlertsAGGrid/styles.scss';
@import './views/AlertsIFrame/styles.scss';
@import './views/AlertsReport/styles.scss';
@import './views/APIDocumentation/styles.scss';
@import './views/CaseAdminReview/styles.scss';
@import './views/CaseManagement/styles.scss';
@import './views/CaseManagementAGGrid/styles.scss';
@import './views/CaseQCReview/styles.scss';
@import './views/CaseUpdater/styles.scss';
@import './views/CaseUpdaterAGGrid/styles.scss';
@import './views/Chargebacks/styles.scss';
@import './views/ChargebacksAGGrid/styles.scss';
@import './views/Dashboard/styles.scss';
@import './views/Downloads/styles.scss';
@import './views/ERT/styles.scss';
@import './views/ERTAGGrid/styles.scss';
@import './views/Help/styles.scss';
@import './views/InvalidChargebacks/styles.scss';
@import './views/InvalidChargebacksAGGrid/styles.scss';
@import './views/MerchantHierarchy/styles.scss';
@import './views/MerchantSettings/styles.scss';
@import './views/MidHealth/styles.scss';
@import './views/MidHealthAGGrid/styles.scss';
@import './views/Monitoring/styles.scss';
@import './views/MTDReports/styles.scss';
@import './views/Onboarding/styles.scss';
@import './views/OnboardingAGGrid/styles.scss';
@import './views/PreChargebacksAGGrid/styles.scss';
@import './views/PreChargebacksIFrame/styles.scss';
@import './views/QCReview/styles.scss';
@import './views/QCReviewAGGrid/styles.scss';
@import './views/ReconciliationReport/styles.scss';
@import './views/ReconciliationReportAGGrid/styles.scss';
@import './views/Reports/styles.scss';
@import './views/ResultantKpi/styles.scss';
@import './views/Settings/styles.scss';
@import './views/SettingsAGGrid/styles.scss';
@import './views/TemplateLibrary/styles.scss';
@import './views/TemplateLibraryAGGrid/styles.scss';
@import './views/Users/styles.scss';
@import './views/UsersAGGrid/styles.scss';
@import './views/Webhooks/styles.scss';

//** COMPONENTS **//
@import './components/AccentArea/styles.scss';
@import './components/AccentAreaModal/styles.scss';
@import './components/AccountHistory/styles.scss';
@import './components/AccountSetup/styles.scss';
@import './components/AddCommentModal/styles.scss';
@import './components/AdvancedFilters/styles.scss';
@import './components/AdvancedForm/styles.scss';
@import './components/AGDataGrid/styles.scss';
@import './components/AGDataGridActionsBar/styles.scss';
@import './components/AGDataGridCellEditors/styles.scss';
@import './components/AGDataTree/styles.scss';
@import './components/Alert/styles.scss';
@import './components/AlertDialog/styles.scss';
@import './components/AlertsAutoRefundModal/styles.scss';
@import './components/AlertSnackbar/styles.scss';
@import './components/AreaChart/styles.scss';
@import './components/AssignUser/styles.scss';
@import './components/BarChart/styles.scss';
@import './components/BulkClose/styles.scss';
@import './components/CaseBuilder/styles.scss';
@import './components/CaseBuilderFormBuilder/styles.scss';
@import './components/CaseFilter/styles.scss';
@import './components/CaseHistoryModal/styles.scss';
@import './components/CaseHistoryPopover/styles.scss';
@import './components/CaseMenu/styles.scss';
@import './components/CompellingEvidenceLauncher/styles.scss';
@import './components/CompellingEvidenceLauncherNew/styles.scss';
@import './components/ConfirmActionModal/styles.scss';
@import './components/ConfirmCaseBuilderSummaryStep4/styles.scss';
@import './components/ContextMenu/styles.scss';
@import './components/CreditCardProcessorType/styles.scss';
@import './components/DataGrid/styles.scss';
@import './components//DisputesDeflectorBanner/styles.scss';
@import './components/DnrActionIcon/styles.scss';
@import './components/DnrModal/styles.scss';
@import './components/Ellipsify/styles.scss';
@import './components/ERTNotificationAlert/styles.scss';
@import './components/Field/styles.scss';
@import './components/FileUpload/styles.scss';
@import './components/FileUploadNew/styles.scss';
@import './components/FilterAlert/styles.scss';
@import './components/FlagCaseModal/styles.scss';
@import './components/FlagReportModal/styles.scss';
@import './components/Footer/styles.scss';
@import './components/GenericsSelect/styles.scss';
@import './components/Icon/styles.scss';
@import './components/Icons/styles.scss';
@import './components/IdleUserBoundary/styles.scss';
@import './components/InputCaseBuilderDetailsStep1/styles.scss';
@import './components/InputCaseBuilderDetailsStep2/styles.scss';
@import './components/Lamp/styles.scss';
@import './components/LineBarChart/styles.scss';
@import './components/LineChart/styles.scss';
@import './components/LoadingIndicator/styles.scss';
@import './components/Logo/styles.scss';
@import './components/MainLayoutModal/styles.scss';
@import './components/MarkReviewedModal/styles.scss';
@import './components/MerchantAvatar/styles.scss';
@import './components/MerchantEdit/styles.scss';
@import './components/MerchantMIDSDetails/styles.scss';
@import './components/MerchantSwitcher/styles.scss';
@import './components/MessageModal/styles.scss';
@import './components/Metric/styles.scss';
@import './components/MidPermissions/styles.scss';
@import './components/ModalArea/styles.scss';
@import './components/MUIDataGrid/styles.scss';
@import './components/MultiBarChart/styles.scss';
@import './components/Paginate/styles.scss';
@import './components/Pagination/styles.scss';
@import './components/PaginationAGGrid/styles.scss';
@import './components/PaginationGrid/styles.scss';
@import './components/PasswordChange/styles.scss';
@import './components/Picker/styles.scss';
@import './components/PieChart/styles.scss';
@import './components/PopoverSelect/styles.scss';
@import './components/PresetDateRange/styles.scss';
@import './components/PresetDateRangePicker/styles.scss';
@import './components/Profile/styles.scss';
@import './components/RevertDnrModal/styles.scss';
@import './components/SearchableSelect/styles.scss';
@import './components/Searchbar/styles.scss';
@import './components/SelectedRowWarningModal/styles.scss';
@import './components/Sidebar/styles.scss';
@import './components/SidebarNav/styles.scss';
@import './components/StandardModal/styles.scss';
@import './components/StandardPopover/styles.scss';
@import './components/SwitchList/styles.scss';
@import './components/TopBar/styles.scss';
@import './components/UpdateEmail/styles.scss';
@import './components/UpdateMerchant/styles.scss';
@import './components/UpdateMid/styles.scss';
@import './components/UpdateUser/styles.scss';
@import './components/UploadCaseBuilderDocumentsStep3/styles.scss';
@import './components/UploadCaseUpdater/styles.scss';
@import './components/UploadIcon/styles.scss';
@import './components/UploadOnboarding/styles.scss';
@import './components/UploadRepresentments/styles.scss';
@import './components/UploadRepresentmentsVC3/styles.scss';
@import './components/VerdictType/styles.scss';
@import './components/VerifiRDRConfig/styles.scss';
@import './components/VisaCE/styles.scss';