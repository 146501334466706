//** Review Cell **//
.emp-adminReview-reviewCell-actionsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.emp-adminReview-reviewCell-iconBtn {
    margin: 0;
    padding: 0;
}

.emp-adminReview-reviewCell-actionIcon {
    width: 20px;
    height: 20px;
}

.emp-adminReview-reviewCell-disabledIcon {
    @include disable-icon;
    cursor: default;
}
