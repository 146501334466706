//** Onboarding AG **//
#onboardingView {

    .emp-accentAreaContainer {
        padding: theme-spacing(1px) !important;

        @include theme-breakpoint('up', $md-screen-size) {
            padding: theme-spacing(1px) !important;
            padding-top: theme-spacing(4px) !important;
        }
    }

    .emp-actionsContainer {
        display: flex;
        justify-content: flex-end;
    }

    .emp-onboardingUploadBtn {
        text-transform: capitalize;
    }

    .emp-filtersContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: theme-spacing(1.5px);
        row-gap: theme-spacing(1.5px);
    }

    .emp-cssOutlinedInput {
        padding: theme-spacing(1px);
        border-radius: 3px;
        text-align: left;
        outline: none;
        border: solid 1px #a8a8a8;
        color: #545454;
    }

    .emp-dateRangePicker {
        width: 200px;
    }
}