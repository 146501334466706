//** Reconciliation Report **//
#reconciliationReportView {
    .emp-advSearchBtn {
        padding: theme-spacing(0.4px) theme-spacing(1px);
        font-weight: 500;
        margin-bottom: theme-spacing(0.5px);
        margin-right: 4px;
    }

    .emp-advSearchIcon {
        margin-right: 6px;
    }

    .emp-advFilterContent {
        margin-bottom: theme-spacing(2px);
    }
}