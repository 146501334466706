.emp-verdictType-root {}

.emp-verdictType-small {
    width: 20px;
    height: 20px;
}

.emp-verdictType-medium {
    width: 24px;
    height: 24px;
}

.emp-verdictType-large {
    width: 32px;
    height: 32px;
}

.emp-verdictType-container {
    display: flex;
    justify-content: center;
    align-items: center;
}