//** Actions Cell **//
#templateLibraryAGActionsCell {
    .emp-iconContainer {
        margin-top: theme-spacing(1px);
    }

    .emp-icon {
        font-size: 18px;
        margin: 4px;

        &:hover {
            cursor: pointer;
        }
    }

    .emp-disabledIcon {
        font-size: 18px;
        margin: 4px;
        @include disable-icon;

        &:hover {
            cursor: default;
        }
    }
}