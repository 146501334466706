.emp-visaCETopSection-root {
    border-bottom: 1px solid #ccc;
    padding: 0 0 theme-spacing(1px);
    margin-bottom: theme-spacing(1px);

    & .MuiInputLabel-root {
        background-color: $white-color;
    }
}

.emp-visaCETopSection-summary {
    margin-bottom: theme-spacing(1px);
    padding: theme-spacing(1px);
    font-size: 11px;
    background: #eee;

    .emp-visaCETopSection-summary-header {
        margin-bottom: theme-spacing(1px);
        padding-left: theme-spacing(1px);
        font-size: 14px;
        font-weight: 700;
        color: #333;
    }
}
.emp-visaCETopSection-form-container {
    .emp-visaCETopSection-form-container-header {
        padding: theme-spacing(1.5px) theme-spacing(2px);

        > div:first-of-type {
            font-size:14px;
            font-weight: 700;
        }
        > div:last-of-type {
            font-size: 10px;
        }
    }
}

.emp-visaCETopSection-formFieldsContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0;

    @include theme-breakpoint('up', $md-screen-size) {
        flex-direction: row;

        > div:first-of-type {
            margin-right: theme-spacing(.5px);
        }

        > div:last-of-type {
            margin-left: theme-spacing(.5px);
        }

        > div:not(:first-of-type):not(:last-of-type) {
            margin-left: theme-spacing(.5px);
            margin-right: theme-spacing(.5px);
        }
    }

    & > div {
        width: 100%;
    }
}

.emp-visaCETopSection-gridContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    @include theme-breakpoint('up', $md-screen-size) {
        flex-direction: row;

        & > div {
            padding: 0 0 theme-spacing(.5px);
        }
    }

    & > div {
        width: 100%;
        padding: 0 theme-spacing(1px) theme-spacing(.5px)
    }
}

.emp-visaCETopSection-descriptionContainer {
    padding: 0;
}

.emp-visaCESummary-top-section {
    padding: 0 theme-spacing(1px);
    margin-bottom: theme-spacing(2.5px);
}
