.emp-caseBuilderFormBuilder-root {
    display: flex;
    flex-wrap: wrap;
    margin-top: theme-spacing(2px);

    &.renderOneSectionRoot {
        margin: 0px;

        & hr {
            margin: 5px 0;
            height: 2px;
        }
    }
}

.emp-caseBuilderFormBuilder-groupContainer {
    flex: 0 0 calc(50% - 4px);
    padding: theme-spacing(1px);
    border: 1px solid #e3e3e3;
    border-radius: 5px;

    &:not(.renderOneSectionGroup) {
        margin-top: theme-spacing(1px);
    }

    &:nth-child(odd) {
        margin-right: theme-spacing(0.5px);
    }

    &:nth-child(even) {
        margin-left: theme-spacing(0.5px);
    }

    @include theme-breakpoint('down', $md-screen-size) {
        flex: 0 0 100%;
        margin: 0;
    }

    &.renderOneSectionGroup {
        flex: 0 0 100%;
        padding: 0;
        border: none;
        border-radius: 0;
    }
}

.emp-caseBuilderFormBuilder-groupContainerInner {
    display: flex;
    flex-wrap: wrap;

    & .fieldWidth1 {
        flex: 0 0 100%;
        padding: 0 theme-spacing(0.5px);
    }

    & .fieldWidth2 {
        flex: 0 0 50%;
        padding: 0 theme-spacing(0.5px);
    }

    & .requiredWithNoValue .MuiOutlinedInput-notchedOutline {
        border-color: #ef9a9a;
    }

    & .requiredLabelIndicator {
        position: absolute;
        top: -5px;

        &:after {
            position: relative;
            padding-right: 8px;
            top: 3px;
            right: -3px;
            display: inline-block;
            content: '•';
            font-size: 30px;
            color: #f00;
            background-color: $white-color;
        }
    }

    & .errorContainer {
        position: relative;
        margin: -theme-spacing(0.5px) 0 theme-spacing(0.5px);
        padding: theme-spacing(0.5px) theme-spacing(1.5px);
        color: #c00;
        background-color: #fee;
        border-radius: 0 0 theme-spacing(1px) theme-spacing(1px);

        & svg {
            position: absolute;
            top: theme-spacing(1px);
            margin-right: theme-spacing(0.5px);
        }

        & ul {
            margin: 0;
            padding: 0 0 0 40px;
            font-size: 12px;
        }
    }

    & .invalidFieldTypeContainer {
        background-color: #ffc;
        color: #039;
        margin: theme-spacing(1px) 0 0;
        padding: theme-spacing(0.5px) theme-spacing(1.5px);
        font-size: 12px;
        line-height: 12px;
        min-height: 38px;
        border-radius: theme-spacing(1px);
    }

    & .Mui-required {
        position: absolute !important;
        top: -5px;
    }

    & .MuiFormLabel-root {
        background-color: $white-color;

        & span.MuiFormLabel-asterisk {
            color: transparent;

            &:after {
                position: relative;
                padding-right: 0px;
                top: 3px;
                right: 5px;
                display: inline-block;
                font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
                content: '•';
                font-size: 23px;
                color: #f00;
                background-color: $white-color;
            }
        }
    }

    & .selectLoadingContainer {
        margin-top: theme-spacing(1px);
        margin-left: theme-spacing(2px);
    }
}

.emp-caseBuilderFormBuilder-groupHeaderWithIconContainer {
    margin-bottom: theme-spacing(0.5px);

    & svg {
        position: relative;
        top: -2px;
        margin-right: theme-spacing(0.5px);
    }
}

.emp-caseBuilderFormBuilder-groupHeader {
    margin-bottom: theme-spacing(0.5px);
    margin-left: theme-spacing(1px);
    font-size: 20px;
    color: #616161;
}

.emp-caseBuilderFormBuilder-textFieldBox {
    & .MuiOutlinedInput-input {
        padding: 0px;
    }

    & .MuiSelect-select {
        padding: 8.5px 7px;
    }

    & .MuiOutlinedInput-root {
        padding: 8.5px 7px;
    }
}