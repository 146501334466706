//** Case Mgmt Grid AG **//
#caseMgmtGridAG {
    .emp-cardTypeIcon {
        margin-top: 5px;
    }

    .emp-verdictIcon {
        width: 100%;
        height: 100%;
    }

    .emp-caseMenuIcon {
        margin-bottom: 5px;
    }

    .emp-dataGridContainer {
        margin-top: theme-spacing(1px);
    }

    .emp-actionsContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: theme-spacing(1px);
        row-gap: theme-spacing(1px);
        flex-wrap: wrap;

        @include theme-breakpoint('down', 825) {
            display: block;
        }
    }

    .emp-actionsLeftContainer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        column-gap: theme-spacing(.5px);
        row-gap: theme-spacing(.5px);

        @include theme-breakpoint('down', 825) {
            display: block;
            margin-bottom: theme-spacing(1px);
        }
    }

    .emp-actionsRightContainer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        column-gap: theme-spacing(.5px);
        row-gap: theme-spacing(.5px);

    }

    .emp-tabsContainer {
        & .MuiTabs-root {
            min-height: 0;
        }

        .emp-tabRoot {
            min-height: 20px;
            min-width: 100px;
            text-transform: capitalize;
            margin-right: theme-spacing(0.3px);
            padding: 10px 12px;
        }

        .emp-activeTab {
            background: $white-color !important;
            border-bottom: none !important;
            opacity: 1 !important;
        }

        .emp-tab {
            background: #fafafa;
            border: solid .5px #a8a8a8;
            opacity: 0.7;

            &:first-child {
                border-top-left-radius: 4px;
            }

            &:last-child {
                border-top-right-radius: 4px;
            }
        }

        .emp-tabLabel {
            font-weight: 700;
            color: #525252;
            font-size: .9rem;
            display: flex;
            align-items: center;
        }
    }


}