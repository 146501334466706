.emp-uploadCaseUpdater-modalClose {
    position: absolute;
    top: 14px;
    right: 14px;
    cursor: pointer;
}

.emp-uploadCaseUpdater-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.emp-uploadCaseUpdater-fileDnD {
    width: 100%;
    margin-bottom: theme-spacing(1.5px);
}

.emp-uploadCaseUpdater-uploadIcon {
    margin-right: 5px;
    font-size: 20px;
}

.emp-uploadCaseUpdater-btnContainer {
    margin-top: 20px;
    margin-bottom: 10px;
}

.emp-uploadCaseUpdater-btn {
    text-transform: capitalize;
    margin: 0px 5px;
    text-align: center;
}