.emp-popoverRangeSelect-content {
    margin: theme-spacing(1px) 0;
}

.emp-popoverRangeSelect-criteriaContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: theme-spacing(1px);
}

.emp-popoverRangeSelect-btnContainer {
    margin-top: theme-spacing(1px);
    text-align: center;

    .emp-popoverRangeSelect-btn {
        min-width: 40px;
        padding: 3px 10px;
    }
}