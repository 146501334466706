//** Webhooks Info **//
#webhooksWebhooksInfo {
    border: 1px solid rgba(0, 0, 0, 0.10);
    padding: 5px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.03);
    min-width: 300px;

    .emp-typographyInfoHeader {
        text-align: left;
    }

    .emp-typographyHeader {
        font-weight: 600,
    }

}

//** Add Edit Webhook **//
#webhooksAddEditWebhook {
    .emp-info {
        margin-bottom: 10px;
    }

    .emp-typography {
        text-align: left;
    }

    .emp-fieldContainer {
        min-width: 300px;
        margin-top: 5px;

        & .MuiInputAdornment-root {
            margin-right: 7px;
        }

        & .MuiTypography-root {
            background: #e0e0e0;
            color: $primary-text-color;
            padding: 6px 7px;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            margin-right: 0;
        }

        & .MuiOutlinedInput-input {
            padding: 0px;
        }

        & .MuiOutlinedInput-root {
            padding: 7px;
            padding-left: 0;
        }
    }

    .emp-errorText {
        margin: 3px 14px 0;
    }

}