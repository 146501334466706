.emp-visaCESummary-container {
    .emp-visaCESummary-main {
        display: flex;
        margin-bottom: theme-spacing(2px);

        > div {
            flex: 1 0 auto;

            > div:first-of-type {
                margin: theme-spacing(.5px);
                padding: theme-spacing(.5px);
                text-align: center;
                border-style: solid;
                border-width: 1px 1px 0 1px;
                border-color: red;
            }

            > div:last-of-type {
                display: flex;

                > div {
                    flex: 1 0 auto;

                    > div:first-of-type {
                        padding: theme-spacing(2px) theme-spacing(.5px);
                        text-align: center;
                        background-color: #f3f3f3;

                        > div:first-of-type {
                            font-weight: 700;
                        }
                    }
                    > div:last-of-type {
                        padding: theme-spacing(.5px);

                        > div {
                            display: flex;
                            padding: theme-spacing(1px) 0 0;
                            font-size: 11px;

                            > div:first-of-type {
                                padding-right: theme-spacing(.5px);
                                min-width: 90px;
                                max-width: 95px;
                                font-weight: 700;
                                text-align: right;
                                word-break: break-word;
                            }

                            > div:last-of-type {
                                min-width: 150px;
                                max-width: 150px;
                                word-break: break-all;
                            }
                        }
                    }
                }
            }
        }
    }
}
