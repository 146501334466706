.emp-compellingEvidenceLauncher-root {
    margin: 0 theme-spacing(3.5px);
}

.emp-compellingEvidenceLauncher-paragraph {
    margin: 0 0 theme-spacing(1px);
    text-align: left;
    font-Size: 14px;
}

.emp-compellingEvidenceLauncher-submitBtn {
    margin: 0 0 theme-spacing(1.5px);
}