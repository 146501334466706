.emp-muiDataGrid-tableContainer {
    & .MuiTableCell-stickyHeader {
        box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.4);
    }
}

.emp-muiDataGrid-tablePaginationContainer {
    margin-top: theme-spacing(1px);
    display: flex;
    justify-content: flex-end;
}