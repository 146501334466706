.emp-ert-archive-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: theme-spacing(2px);
    width: 95%;

    @include theme-breakpoint('up', $sm-screen-size) {
        width: 400px;
    }
}

.emp-ert-archive-headerBanner {
    display: flex;
    justify-content: space-between;
    padding: 12px 18px;
    font-size: 1.2rem;
    color: white;
    background: #c4c4c4;
}