.emp-ert-filterErt-filterSortContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding-top: 8px;
    padding-left: 8px;
    padding-right: 16px;

    &>label {
        font-size: .90rem;
        color: #797979;
        font-weight: 600;
        margin-left: 8px;
        margin-right: 8px;
    }


    @include theme-breakpoint('up', $md-screen-size) {
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
    }
}

.emp-ert-filterErt-inputContainer {
    width: 185px;
    margin: 4px;
    height: 36px;
}

.emp-ert-filterErt-dateRangePicker {
    width: 200px;
}