.emp-dnrActionIcon-actionContainer {
    display: flex;
    justify-content: center;
    align-items: baseline;
    column-gap: theme-spacing(1.5px);
}

.emp-dnrActionIcon-root {
    cursor: pointer;
}

.emp-dnrActionIcon-disabled {
    cursor: default;
}

.emp-dnrActionIcon-disabledIcon {
    @include disable-icon;
    cursor: default;
}