//** Template Library Grid AG **//
#templateLibraryGridAG {
    .emp-dataGridContainer {
        & .ag-root-wrapper-body {
            height: 275px;
        }
    }

    .emp-dataActionsContainer {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
    }
}