.emp-view-heading {
    text-transform: capitalize;
    font-size: 24px;
    font-weight: 600;
}

.emp-view-gridTwo {
    display: grid;
    grid-template-columns: 1fr;
    gap: theme-spacing(1px);

    @include theme-breakpoint('up', $sm-screen-size) {
        grid-template-columns: 1fr 1fr;
        gap: 24px;
    }

    @include theme-breakpoint('up', $md-screen-size) {
        grid-template-columns: 3fr 1fr;
    }

    @include theme-breakpoint('up', $lg-screen-size) {
        grid-template-columns: 3fr 1fr;
        gap: 32px;
    }

    place-items: stretch;
    align-items: center;
    padding: theme-spacing(1px) 0;
}

.emp-view-gridThree {
    display: grid;
    grid-template-columns: 1fr;
    gap: theme-spacing(1px);

    @include theme-breakpoint('up', $md-screen-size) {
        grid-template-columns: auto 3fr 1fr;
    }

    place-items: stretch;
    align-items: center;
    padding: theme-spacing(1px) 0;
}