//** Resultant KPI Area Chart **//
#resultantKpiAreaChart {

    & .recharts-layer>.recharts-layer>path:nth-child(2) {
        filter: drop-shadow(5px -1px 2px rgb(0 0 0 / 0.30));
    }

    & .recharts-area-dots {
        filter: drop-shadow(5px -1px 2px rgb(0 0 0 / 0.30));
    }


    .emp-tooltipContainer {
        background-color: $white-color;
        border: 0.25px solid #cccc;
        opacity: .9;
        box-shadow:
            0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%), 0 1px 3px 0 rgb(0 0 0 / 20%);
    }

    .emp-tooltipTableHeader {
        font-weight: 700;
        text-align: left;
        color: white;
        padding: 0px 4px;
    }

    .emp-tooltipTableRow {
        border-top: 1px solid #b3b3b3;
        padding: 0px 4px;
    }

    .emp-loadingContainer {
        height: 200px;
        text-align: center;
        margin-top: 80px;
    }
}