.emp-caseMenu-menuIconBtn {
    padding: 5px;
    width: 27px;
}

.emp-caseMenu-menuIcon {
    font-size: 15px;
}

.emp-caseMenu-menuRoot {
    padding: 0;
}

.emp-caseMenu-menuItem {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    min-width: 150px;
}

.emp-caseMenu-menuItemIcon {
    width: 16px;
    height: 16px;
    margin-right: theme-spacing(1px);
}