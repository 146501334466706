.emp-submitRepresentmentModal-dialogContent {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.emp-submitRepresentmentModal-image {
    width: 100%;
}

.emp-submitRepresentmentModal-title {
    font-weight: 600;
    font-size: 2rem;
    text-align: center;

}

.emp-submitRepresentmentModal-content {
    text-align: center;
    color: #57595E;
    font-size: 1.15rem;

    &>div {
        margin-top: theme-spacing(1.5px)
    }
}

.emp-submitRepresentmentModal-dialogActions {
    padding: 20px;
    padding-top: theme-spacing(1.5px);
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: theme-spacing(1px);

    &>button {
        text-transform: capitalize;
        font-size: 1.0625rem;
        padding: 6px 26px;
    }
}

.emp-submitRepresentmentModal-circularProgress {
    width: 15px !important;
    height: 15px !important;
    vertical-align: middle;
    color: $white-color;
}