.emp-standardFooter-root {
    position: fixed;
    bottom: 0;
    left: 0;
    background: #131418;
    color: #a1a1a1;
    width: 100%;
    margin: 0;
    z-index: 996;
    padding: 3px 20px;
    font-size: .8rem;
    border-top: 1px solid #616161;
}

.emp-standardFooter-link {
    color: $white-color;

    &:hover {
        color: $white-color !important;
        text-decoration: none;
    }

    &:visited {
        color: $white-color;
    }
}