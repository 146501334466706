.emp-agDataGridActionsColumnFilter-root {
    padding: 0;
}

.emp-agDataGridActionsColumnFilter-btn {
    overflow: hidden;
    min-width: 200px;
    height: 30px;
    background-color: #e2f4fd;
    color: #18709c;
    text-transform: capitalize;
    padding: 6px 8px;
    margin-bottom: 1px;

    &:hover {
        background-color: #d3ecf9;
        font-weight: 600,
    }
}

.emp-agDataGridActionsColumnFilter-hiddenBtn {
    color: #ccc;

    &:hover {
        background-color: $white-color;
        font-weight: 600;
    }
}

.emp-agDataGridActionsColumnFilter-resetBtn {
    display: flex;
    justify-content: center;
    overflow: hidden;
    min-width: 200px;
    height: 30px;
    background-color: $white-color;
    color: #4f4f4f;
    text-transform: capitalize;
    text-align: center;
    padding: 6px 8px;

    &:hover {
        font-weight: 600,
    }
}