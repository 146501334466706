.emp-lineBarChart-root {
    border: none;

    & .recharts-line {
        filter: drop-shadow(5px -1px 2px rgb(0 0 0 / 0.30));
    }

    & .recharts-bar {
        filter: drop-shadow(4px -1px 2px rgb(0 0 0 / 0.30));
    }
}

.emp-lineBarChart-header {
    display: flex;
    justify-content: space-between;
    height: 50px;
    padding: theme-spacing(1px);
    text-align: center;
}

.emp-lineBarChart-content {
    display: flex;
    justify-content: center;
    padding: 5px;

    &.MuiCardContent-root {
        margin-bottom: 15px;
    }
}

.emp-lineBarChart-loadingContainer {
    height: 125px;
    margin-top: 80px;
}

.emp-lineBarChart-tooltipContainer {
    background-color: $white-color;
    border: 0.25px solid #cccc;
    opacity: .9;
    box-shadow:
        0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%), 0 1px 3px 0 rgb(0 0 0 / 20%);
}

.emp-lineBarChart-tooltipTableHeader {
    font-weight: 700;
    text-align: left;
    color: white;
    padding: 0px 4px;
}

.emp-lineBarChart-tooltipTableRow {
    border-top: 1px solid #b3b3b3;
    padding: 0px 4px;
}