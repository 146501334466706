//** Merchant Tree Header **//
#merchantSettingsMerchantTreeHeader {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: nowrap;
    width: 100%;
    margin: 0;
    padding: 6px 16px 8px 16px;
    font-size: 13px;


    .emp-searchTextField {
        & .MuiInput-underline:before {
            border-bottom: 1px solid #84878E;
        }
    }

    .emp-searchIcon {
        color: #263238;
        font-size: 14px;
        margin-left: 4px;
    }

    .emp-searchContainer {
        display: flex;
        align-items: center;
        width: inherit;
    }

    .emp-resetSearchIconContainer {
        padding-left: 8px;
    }

    .emp-resetSearchIcon {
        padding: 5px;
        width: 27px;
    }

    .emp-previousMerchantContainer {
        font-size: 12px;
        height: 15px;
    }
}