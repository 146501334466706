@font-face {
    font-family: icomoon;
    src: url(./fonts/icomoon.eot?hnmunz);
    src: url(./fonts/icomoon.eot?hnmunz#iefix) format('embedded-opentype'),
        url(./fonts/icomoon.ttf?hnmunz) format('truetype'),
        url(./fonts/icomoon.woff?hnmunz) format('woff'),
        url(./fonts/icomoon.svg?hnmunz#icomoon) format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: icomoon;
    src: url(./fonts/icomoon.eot?iuppaa);
    src: url(./fonts/icomoon.eot?iuppaa#iefix) format('embedded-opentype'),
        url(./fonts/icomoon.ttf?iuppaa) format('truetype'),
        url(./fonts/icomoon.woff?iuppaa) format('woff'),
        url(./fonts/icomoon.svg?iuppaa#icomoon) format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: icomoon !important;
    speak: none;
    font-style: normal;
    font-weight: 500;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-home:before {
    content: '\e957';
    font-size: 25px;
}
.icon-coin:before {
    content: '\e934';
    font-size: 25px;
}
.icon-code:before {
    content: '\e91b';
    font-size: 25px;
}
.icon-briefcase:before {
    content: '\e9e0';
    font-size: 25px;
}
.icon-money-check-alt:before {
    content: '\e929';
    font-size: 25px;
}
.icon-coin:before {
    content: '\e934';
    font-size: 25px;
}
.icon-bell:before {
    content: '\e905';
    font-size: 25px;
}
.icon-chart:before {
    content: '\e952';
    font-size: 25px;
}
.icon-exclamation-circle:before {
    content: '\e925';
    font-size: 25px;
}
.icon-users:before {
    content: '\e91f';
    font-size: 25px;
}
.icon-cog:before {
    content: '\e9ad';
    font-size: 25px;
}
.icon-life-ring:before {
    content: '\e9a5';
    font-size: 25px;
}
.icon-credit-card:before {
    content: '\e929';
    font-size: 25px;
}
.icon-calendar:before {
    content: '\e915';
    font-size: 25px;
}
.icon-consumer-single:before {
    content: '\e924';
    font-size: 25px;
}
.icon-consumer-single-style:before {
    content: '\e924';
    font-size: 20px;
    font-weight: bold;
}
.icon-notification:before {
    content: '\e9c4';
    font-size: 25px;
}
.icon-clock:before {
    content: '\e919';
    font-size: 25px;
}
.icon-star:before {
    content: '\e9a2';
    font-size: 18px;
    font-weight: 800;
}
.icon-clipboard:before {
    content: '\e964';
    font-size: 18px;
    font-weight: 800;
}
.icon-wheel:before {
    content: '\e94a';
    font-size: 18px;
    font-weight: 800;
}
.icon-cogs:before {
    content: '\e94a';
    font-size: 25px;
}
.icon-check:before {
    content: '\e95d';
    font-size: 18px;
    font-weight: 800;
}
.icon-ban:before {
    content: '\e96f';
    font-size: 18px;
    font-weight: 800;
}
.icon-clock-o:before {
    content: '\e962';
    font-size: 18px;
    font-weight: 800;
}
.icon-question:before {
    content: '\e987';
    font-size: 25px;
}
.icon-bell:before {
    content: '\e905';
    font-size: 25px;
}
.icon-revert-Dnr:before {
    content: '\e9cd';
    font-size: 21px;
}
.icon-revert-Dnr-disabled:before {
    content: '\e9cd';
    font-size: 21px;
    color: #bdbdbd;
}
.icon-Dnr:before {
    content: '\e9cc';
    font-size: 21px;
}
.icon-upload:before {
    content: '\e9ca';
    font-size: 21px;
}
.icon-upload-disabled:before {
    content: '\e9ca';
    font-size: 21px;
    color: #bdbdbd;
}
.icon-bars:before {
    content: '\e9bc';
    color: #afafaf;
    font-size: 29px;
}
.icon-document:before {
    content: '\e9ce';
    color: #75797b;
    font-size: 30px;
}
.icon-document-small:before {
    content: '\e9ce';
    color: #75797b;
    font-size: 24px;
}
.icon-toggle-unlock:before {
    content: '\e9d6';
    font-size: 24px;
}
.icon-toggle-lock:before {
    content: '\e9d4';
    font-size: 24px;
}
.icon-unflagged:before {
    content: '\e9e4';
    color: #75797b;
    font-size: 21px;
}
.icon-flagged:before {
    content: '\e9e4';
    color: #c80000;
    font-size: 21px;
}
.icon-assign:before {
    content: '\e9bd';
    color: #75797b;
    font-size: 20px;
}
.icon-cross:before {
    content: '\e9dd';
    color: #bdbdbd;
    font-size: 24px;
}
.icon-edit:before {
    content: '\e9d7';
    font-size: 24px;
}
.icon-mid-permission:before {
    content: '\e9d2';
    font-size: 24px;
}
.icon-site-permission:before {
    content: '\e9d5';
    font-size: 24px;
}
.icon-temp-password:before {
    content: '\e9d3';
    font-size: 24px;
}
.icon-trash-can:before {
    content: '\e9cf';
    color: #c80000;
    font-size: 30px;
}
.icon-building:before {
    content: '\e912';
    color: #ffffff;
    font-size: 27px;
}
.icon-case-builder:before {
    content: '\e9cb';
    color: #f89743;
    font-size: 23px;
}
.icon-case-builder-disabled:before {
    content: '\e9cb';
    font-size: 23px;
    color: #bdbdbd;
}
