//** Icon **//
.emp-icons-icon-root {}

//** Flag Icon **//
.emp-icons-flagIcon-root {}

//** Assign User Icon **//
.emp-icons-assignUserIcon-root {}

//** Upload Icon **//
.emp-icons-uploadIcon-container {
    font-size: 25px;
}

.emp-icons-uploadIcon-disabledIcon {
    cursor: default;
}

.emp-icons-uploadIcon-disabledColor {
    @include disable-icon;
    cursor: default;
}