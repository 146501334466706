//** Bulk Upload ERTs **//
#ertAGBulkUploadErts {
    #ertAGBulkUploadErtsStep1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        row-gap: theme-spacing(1.5px);

        .emp-inputContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            &>label {
                width: 30%;
                margin-right: theme-spacing(1px);
            }

            &>div {
                width: 60%;

                &>ul {
                    margin: 0;
                }
            }
        }

        .emp-fileInputHelpText {
            margin-top: theme-spacing(0.5px);
            font-size: 12px;
            color: #666;
            font-style: italic;
        }

        .emp-selectedFile {
            margin-top: theme-spacing(0.5px);
            color: #155C93;
        }
    }

    #ertAGBulkUploadErtsStep2 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        font-size: 14px;
        row-gap: theme-spacing(1.5px);

        .emp-actionsContainer {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            column-gap: theme-spacing(1px);
        }
    }

}

.emp-ertAG-bulkUploadErts-alertContainer {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    row-gap: theme-spacing(1px);

    div:first-child {
        font-weight: 600;
        color: #263238;
    }

    div:last-child {
        max-height: 175px;
        overflow-y: auto;
        border: 1px solid #e9e9e9;
        border-radius: 4px;
        padding: theme-spacing(0.5px);

        &>ul {
            margin: 0;
            padding-left: 20px;
        }
    }
}

#ertAGLoadFormatModal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    row-gap: theme-spacing(1.5px);

    .emp-inputContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;


        &>label {
            width: 10%;
            margin-right: theme-spacing(1px);
        }

        &>div {
            width: 40%;
        }

        & .MuiOutlinedInput-root {
            padding: 7px;
        }

        & .MuiOutlinedInput-input {
            padding: 0px;
        }
    }
}

#ertAGSaveFormatModal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    row-gap: theme-spacing(1.5px);

    .emp-inputContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;


        &>label {
            width: 20%;
            margin-right: theme-spacing(1px);
        }

        &>div {
            width: 70%;
        }

        & .MuiOutlinedInput-root {
            padding: 7px;
        }

        & .MuiOutlinedInput-input {
            padding: 0px;
        }
    }
}

#ertAGMapColumnsTable {
    width: 100%;

    .emp-gridHeaderContainer {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: theme-spacing(1.5px);
        text-align: center;
        align-items: center;
        font-weight: 600;
        padding: theme-spacing(1px);
        border-bottom: 1px solid #e9e9e9;
    }

    .emp-gridBodyContainer {
        max-height: 375px;
        overflow-y: auto;
    }

    .emp-gridRowContainer {
        @extend .emp-gridHeaderContainer;
        font-weight: 500;
    }

    .emp-zebraStriping {
        background-color: hsla(0, 0%, 80%, 0.1);
    }
}