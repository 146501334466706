.emp-paginationGrid-root {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 10px;

    @include theme-breakpoint('down', 740) {
        justify-content: center;
    }
}

.emp-paginationGrid-infoContainer {
    margin-top: 10px;
    display: flex;
    align-items: center;
    padding: 0px 2px 0px 6px;
}

.emp-paginationGrid-resultsDisplay {
    padding: 0px 18px;
    display: flex;
    align-items: center;
}

.emp-paginationGrid-sizeDisplay {
    display: flex;
    align-items: center;
    padding: 0px 18px;

    &>select {
        width: 53px;
        margin-left: 4px;
        margin-right: 4px;
        padding: 4px;
        border: 1px solid #dcdcdc;
        text-align: center;
    }
}

.emp-paginationGrid-resultsText {
    margin: 0px 4px;
    font-weight: 700;
}

.emp-paginationGrid-resultsTextLast {
    margin-left: 4px;
    font-weight: 700;
}

.emp-paginationGrid-navContainer {
    margin-top: 10px;
    padding: 0px 18px;
    display: flex;
    align-items: center;

    @include theme-breakpoint('down', $sm-screen-size) {
        margin-top: 10px;
    }
}

.emp-paginationGrid-navBackwardControls {
    display: flex;
    align-items: center;
    padding-right: 4px;
    border-right: 1px solid #eeeeee;
}

.emp-paginationGrid-navDisplayContainer {
    display: flex;
    align-items: center;
    padding: 0px 10px;
}

.emp-paginationGrid-navForwardControls {
    display: flex;
    align-items: center;
    padding: 0px 4px;
    border-left: 1px solid #eeeeee;
    border-right: 1px solid #eeeeee;
}


.emp-paginationGrid-navForwardControlsNoRefreshBtn {
    padding: 0px 0px 0px 4px;
    border-right: none;
}

.emp-paginationGrid-navRefreshContainer {
    padding-left: 4px;
}

.emp-paginationGrid-activeIcon {
    font-size: 16px;
    color: $primary-text-color;
    margin: 0px 6px;

    &:hover {
        cursor: pointer;
    }
}

.emp-paginationGrid-inactiveIcon {
    font-size: 16px;
    color: $primary-text-color;
    @include disable-icon;
    margin: 0px 6px;
}

.emp-paginationGrid-tooltipContainer {
    margin-top: 2px;
}

.emp-paginationGrid-input {
    font-weight: 700;
    margin: 0px 6px;
    min-width: 25px;
    max-width: 100px;
    text-align: center;
    padding: 4px;
    border: 1px solid #dcdcdc;
}

.emp-paginationGrid-boldText {
    font-weight: 700;
}