//** Reconciliation Report AG **//
#reconciliationReportNewView {
    .emp-accentArea {
        padding: theme-spacing(1px) !important;
        padding-top: theme-spacing(4px) !important;
    }

    .emp-advSearchBtn {
        padding: theme-spacing(0.4px) theme-spacing(1px);
        font-weight: 500;
        margin-bottom: theme-spacing(0.5px);
    }

    .emp-advSearchIcon {
        margin-right: 6px;
    }

    .emp-advFilterContent {
        margin-bottom: theme-spacing(2px);
    }
}