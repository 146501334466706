.emp-ert-actionBtnWrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;

    & div>button {
        text-transform: capitalize;
    }

    @include theme-breakpoint('down', $lg-screen-size) {
        align-items: center;
    }
}

.emp-ert-accentAreaActionBtn {
    background-color: #3ED4AF;
    color: $white-color;
    margin-left: 6px;
}

.emp-ert-addErtIcon {
    margin-right: 6px;
}