.emp-presetDateRangePicker-container {
    border-bottom: 1px solid #eff2f7;

    //* sidebar
    & .rdrDefinedRangesWrapper {
        width: 120px;
        font-size: 11px;
    }

    & .rdrStaticRangeLabel {
        padding: 5px 10px;
    }

    & .rdrInputRange {
        padding: 5px 7px;
    }

    & .rdrInputRanges {
        padding: 5px 0;
    }

    //* topbar
    & .rdrDateDisplay {
        margin: 3px;
    }

    & .rdrDateDisplayItem input {
        height: 1.5em;
    }

    //* calendar
    & .rdrCalendarWrapper {
        font-size: 11px;
    }

    & .rdrMonthName {
        padding: 0.25em 0.833em;
    }

    & .rdrWeekDay {
        line-height: 1.5em;
    }

    & .rdrMonthAndYearWrapper {
        height: 30px;
        padding-top: 5px;
    }

    & .rdrMonths {
        justify-content: center;
    }

    & .rdrMonth {
        padding: 0 0.833em 0.2em 0.833em;
        width: 20em;
    }

    & .rdrDay {
        height: 2.5em;
    }

}

.emp-presetDateRangePicker-actionBtnGrp {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 5px;
}

.emp-presetDateRangePicker-actionBtn {
    width: 15%;
    margin: 0 theme-spacing(1px);
}