//** Change Password **//
#changePassword {
    .emp-container {
        font-family: $auth-font-family;
    }

    .emp-resetContainer {
        background: $white-color;
        width: 446px;
        padding: 10px 40px 20px;
    }

    .emp-inputs {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &>* {
            margin-bottom: 10px;
            font-family: $auth-font-family;
        }

        & .MuiInputBase-input {
            font-size: 20px;
            padding: 4px 12px;
            font-family: $auth-font-family;
            color: #535759;
        }
    }

    .emp-btn {
        padding: 1px 10px;
        min-width: 153px;
        font-weight: 600;
        border-radius: 0px 0px 5px 5px;
        font-size: 24px;
        font-family: $auth-font-family;
        text-transform: capitalize;
        box-shadow: none;
    }

    .emp-policyMessaging {
        width: 446px;
        padding: 15px;
        background: #D9EDF7;
        color: #31708F;
        font-size: 14px;

        & h3 {
            margin: 0;
            font-size: 14px;
            padding: 0;
            font-weight: 600;
        }

        & ul {
            margin: 0;
            padding: 0px 20px;
        }
    }

    .emp-spacer {
        height: 20px;
        background: $white-color;
        width: 446px;
    }

    .emp-banner {
        width: 446px;
        padding: 15px;
        background: #D9EDF7;
        color: #31708F;
        text-align: center;
        font-size: 14px;
    }

    .emp-circularProgress {
        width: 24px !important;
        height: 24px !important;
        vertical-align: middle;
        color: $white-color;
        margin: 9px 0px;
    }

    .emp-validPassword {
        color: #50C878;
    }

    .emp-notValidPassword {
        color: #31708F;
    }

    .emp-textField {
        margin-bottom: 15px;

        & .MuiFormHelperText-root.Mui-error {
            color: #C80000;
        }
    }

    .emp-textInput {
        font-size: 14px;
        color: #57595f;
        cursor: default;
    }
}