.emp-modalArea-root {
    padding-bottom: theme-spacing(2px);
    text-align: center;

    & .MuiDialog-paperWidthSm {
        min-width: 300px;
    }
}

.emp-modalArea-dialogContent {
    padding-bottom: theme-spacing(3px);
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
}

.emp-modalArea-defaultHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 3px solid #eeeeee;
}

.emp-modalArea-greyHeader {
    background: #E0E0E0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #505050;
}

.emp-modalArea-default {}

.emp-modalArea-success {
    background: #66bb6a;
    color: $white-color;
}

.emp-modalArea-warning {
    background: #ffa726;
    color: $white-color;
}

.emp-modalArea-error {
    background: #ef5350;
    color: $white-color;
}

.emp-modalArea-info {
    background: #42a5f5;
    color: $white-color;
}