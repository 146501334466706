//** View Document Icon Action **//
#chargebacksAGViewDocumentIconAction {
    .emp-loadingIcon {
        margin-bottom: 5px;
    }

    .emp-documentIconThemeBtn {
        margin-bottom: 5px;
        padding: 1px;
    }

    .emp-documentIconBtn {
        margin: 0px;
        padding: 0px;
    }

    .emp-actionIcon {
        width: 18px;
        height: 18px;
        margin-bottom: 5px;
    }

    .emp-disabledIcon {
        opacity: .25;
        cursor: default;
    }

}

//** Verdict Cell **//
#chargebacksAGVerdictCell {
    display: flex;
    align-items: center;
    justify-content: center;
}

//** Actions Cell **//
#chargebacksAGActionsCell {
    display: flex;
    align-items: center;
    justify-content: center;
}

//** Edit Chargeback Cell **//
#chargebacksAGEditChargebacksCell {
    display: flex;
    justify-content: center;
    align-items: center;

    .emp-btn {
        padding: 0;
        margin-top: 2px;
        display: flex;
        font-size: 0.75rem;
        min-width: 40px;
    }
}

//** Issuer Docs Cell **//
#chargebacksAGIssuerDocsCell {
    .emp-documentIconThemeBtn {
        margin-bottom: 5px;
        padding: 1px;
    }

    .emp-documentIconBtn {
        margin: 0;
        padding: 0;
    }

    .emp-actionIcon {
        width: 18px;
        height: 18px;
        margin-bottom: 5px;
    }

    .emp-disabledIcon {
        opacity: .25;
        cursor: default;
    }
}