.emp-agDataGridActionsFilter-container {
    padding: theme-spacing(1px);
}

.emp-agDataGridActionsFilter-fieldsContainer {
    display: grid;
    grid-column-gap: theme-spacing(1.5px);
    grid-row-gap: theme-spacing(1.5px);
    padding: theme-spacing(1px);

    @include theme-breakpoint('down', $sm-screen-size) {
        grid-template-columns: repeat(2, 1fr) !important;
    }
}

.emp-agDataGridActionsFilter-label {
    font-weight: 600;
}

.emp-agDataGridActionsFilter-textInputField {
    & .MuiInputBase-input {
        padding: 7px;
        width: 150px;
    }
}

.emp-agDataGridActionsFilter-btnGrp {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: theme-spacing(1px);
    column-gap: theme-spacing(2px);
}