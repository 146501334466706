.emp-caseFilter-root {
    width: 100%;
}

.emp-caseFilter-header {
    background-color: $white-color;

    & th {
        font-weight: bold;
    }
}

.emp-caseFilter-listItem {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.emp-caseFilter-table-row {
    cursor: pointer;
    transition: ease all .3s;

    &:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, 0.04);
    }

    & td {
        border-bottom: none;
    }

    &:hover {
        transition: ease all .3s;
        background-color: #B0BED9 !important;
    }
}

.emp-caseFilter-selectedRow {
    background-color: #B0BED9 !important;

}

.emp-caseFilter-actions {
    padding: theme-spacing(2px);
    display: flex;
    justify-content: center;

    &>button {
        text-transform: capitalize;
        margin-left: 10px;
    }
}

.emp-caseFilter-dayInput {
    width: 2.5rem;
    margin-right: 5px;
    padding: 3px 3px;
    text-align: center;
    border-radius: 3px;
    outline: none;
    border: solid 1px #a8a8a8;
    color: #545454;
}