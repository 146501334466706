.emp-dataGridActions-root {
    display: inline-block;
    position: sticky;
    right: 0;
    background: $white-color;
}

.emp-dataGridActions-popover {
    margin-left: -50px;
    margin-top: theme-spacing(1px);
}

.emp-dataGridActions-btnGrp {
    padding-left: theme-spacing(1px);
    padding-right: theme-spacing(1px);
    border-radius: 4px;
    padding: 3px 10px;
    margin: theme-spacing(0.2px);
}

.emp-dataGridActions-hideBtn {
    overflow: hidden;
    min-width: 200px !important;
    height: 30px;
    background-color: #e2f4fd;
    border: 1px solid rgba(0, 0, 0, 0.23);
    color: #18709c;
    justify-content: flex-start;
    text-transform: capitalize;
    border-radius: 2px;
    padding: 6px 12px;

    &:hover {
        background-color: #d3ecf9;
        border: 1px solid rgba(0, 0, 0, 0.23);
    }

    & svg {
        display: none;
    }
}

.emp-dataGridActions-resetBtn {
    overflow: hidden;
    min-width: 200px;
    height: 30px;
    background-color: $white-color;
    border: 1px solid rgba(0, 0, 0, 0.23);
    color: #4f4f4f;
    text-transform: capitalize;
    border-radius: 2px;
    padding: 6px 12px;

    & svg {
        display: none;
    }
}

.emp-dataGridActions-hidden {
    color: #ccc;

    &:hover {
        color: $primary-text-color;
        background-color: $white-color;
    }
}

.emp-dataGridActions-circularProgressContainer {
    display: flex;
    position: relative;
}

.emp-dataGridActions-switchArrowIcon {
    margin-right: 5px;
}