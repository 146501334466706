//** Filter Alerts **//
#alertsFilterAlerts {
    .emp-filterSortContainer {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;

        padding-right: 16px;

        &>label {
            font-size: .90rem;
            color: #797979;
            font-weight: 600;
            margin-right: 8px;
        }

        @include theme-breakpoint('down', $lg-screen-size) {
            justify-content: center;
        }
    }

    .emp-inputContainer {
        width: 185px;
        margin: 4px;
    }

    .emp-dateRangePicker {
        width: 200px;
    }
}