.emp-paginate-root {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: theme-spacing(1px);
    flex-wrap: wrap-reverse;

    @include theme-breakpoint('down', 740) {
        flex-wrap: wrap;
        justify-content: flex-end;
    }

}

.emp-paginate-paginateControlsContainer {
    position: sticky;
    right: 0;
    padding-right: 20px;
    padding-left: 20px;

    @include theme-breakpoint('down', 740) {
        order: 1;
    }
}

.emp-paginate-entriesContainer {
    width: 150px;
    color: #464646;
    font-size: 15px;
    padding-left: 20px;

    @include theme-breakpoint('down', 740) {
        order: 2;
        width: 100%;
    }
}

.emp-paginate-paginationControl {
    display: flex;
    align-items: center;
}

.emp-paginate-paginationText {
    margin: 0px 4px;
}

.emp-paginate-showingResults {
    color: #464646;
    margin-top: 20px;
    padding-left: 20px;

    &>p {
        margin: 0;
    }
}

.emp-paginate-entriesDropdown {
    display: flex;
    align-items: center;

    &>select {
        width: 65px;
        margin-left: 2px;
        margin-right: 2px;
        padding: 4px;
        border: 1px solid #dcdcdc;
    }
}

.emp-paginate-btnGrp {
    &>li:not(:first-child):not(.dummy) {
        color: #337ab7;
    }

    &>li:first-child li:last-child {
        padding: 4px 8px;
    }

    & .Mui-disabled a {
        color: rgba(0, 0, 0, .25);
    }

    & .MuiButtonGroup-groupedContainedHorizontal {
        border: 1px solid #dcdcdc;
    }

    & .MuiButtonGroup-groupedContainedHorizontal:not(:last-child) {
        border-right: .5px solid #bdbdbd;
    }

    & .MuiButtonGroup-groupedContainedHorizontal:not(:first-child) {
        border-left: .5px solid #bdbdbd;
    }
}

.emp-paginate-activePageBtn {
    background: #848484 !important;
    color: $white-color !important;

    & a {
        color: $white-color;
    }
}

.emp-paginate-btnGrpContained {
    padding: 0;
    box-shadow: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
}