//** Edit Invalid Chargeback Cell **//
#invalidChargebacksAGEditInvalidCBCell {
    display: flex;
    justify-content: center;
    align-items: center;

    .emp-editBtn {
        padding: 0;
        margin-top: 2px;
        display: flex;
        font-size: 0.75rem;
        min-width: 40px;
    }

}