.emp-dataGridSlimMTD-dataGrid {
    width: 100%;
    overflow-x: auto;
    margin-top: 25px;
    height: 100%;
    min-height: 126;
}

.emp-dataGridSlimMTD-tableContainer {
    box-shadow: inset -10px 0 14px -10px rgba(0, 0, 0, 0.5);
    overflow: initial;

    & .MuiTableCell-root {
        padding: 0px 5px 0px 0px;
    }

    @include theme-breakpoint('up', $md-screen-size) {
        box-shadow: none;
    }
}

.emp-dataGridSlimMTD-tableHeader {
    text-align: left;
    background-color: $white-color;
}

.emp-dataGridSlimMTD-headerFooterTitle {
    font-weight: 600;
    font-size: 12px;
    color: #000;
}

.emp-dataGridSlimMTD-columns {
    font-size: 12px;
    white-space: nowrap;
}

.emp-dataGridSlimMTD-icon {
    width: 25px;
    margin-top: 3px;
    margin-right: 3px;
    vertical-align: sub;
}

.emp-dataGridSlimMTD-tooltipPopper {
    background: #020202;
    color: $white-color;
}

.emp-dataGridSlimMTD-tooltipPopperArrow {
    &::before {
        background: #020202;
    }
}

.emp-dataGridSlimMTD-loadingContainer {
    margin-top: theme-spacing(1px);
    text-align: center;
    width: 100%;
}

.emp-dataGridSlimMTD-showingResults {
    color: #464646;
    margin-top: 20px;
    padding-left: 20px;

    &>p {
        margin: 0;
    }
}