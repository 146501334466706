//** Edit Chargeback **//
.emp-chargebacksAG-editChargeback-modalContainer {
    & .MuiDialog-paperFullWidth {
        width: 650px;
    }
}

#chargebacksAGEditChargeback {
    .emp-loadingContainer {
        text-align: center;
        margin: theme-spacing(3px);
    }

    .emp-mainContainer {
        display: flex;
        justify-content: center;
        column-gap: theme-spacing(3px);
    }

    .emp-mainContainerColumn {
        display: flex;
        flex-direction: column;
        row-gap: theme-spacing(1.5px);
        width: 100%;
    }

    .emp-typography {
        text-align: left;
    }

    .emp-fieldContainer {
        margin-top: 5px;

        & .MuiInputBase-input {
            padding: 7px;
        }
    }
}