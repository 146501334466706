.emp-picker-root {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    white-space: nowrap;
    overflow: hidden;
}

.emp-picker-label {
    font-weight: 500,
}

.emp-picker-picker {
    margin: 0 theme-spacing(1px);
    height: 32px;
    flex: 3;

    & .MuiInput-input:focus {
        background-color: $white-color;
    }
}

.emp-picker-dateInput {
    width: 100%;
    margin: 0 theme-spacing(1px);
    height: 32px;
    flex: 3,
}