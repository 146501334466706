//** Complete Alert **//
#preChargebacksAGAlertsCompleteAlert {
    .emp-inputContainer {
        display: flex;
        align-items: baseline;
        justify-content: center;
        width: 100%;
        margin-top: theme-spacing(1px);
        margin-bottom: theme-spacing(1px);

        &>label {
            width: 15%;
            margin-right: theme-spacing(1px);
        }
    }

    .emp-modal {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 390px;
        min-width: 390px;
    }

    .emp-headerBanner {
        background: #de0e0e;
        display: flex;
        justify-content: space-between;
        padding: 12px 18px;
        margin-bottom: theme-spacing(2px);
        font-size: 1.2rem;
        font-weight: 600;
        color: $white-color;
    }

    .emp-btnGrp {
        display: flex;
        justify-content: center;
        margin-top: theme-spacing(2px);
        margin-bottom: theme-spacing(2px);
    }

    .emp-closeBtn {
        width: 30px;
        min-width: initial;
        height: 30px;
        color: $white-color;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, .5);
    }

    .emp-btn {
        border-radius: 3px;
        padding: theme-spacing(.5px) theme-spacing(1px);
        text-transform: capitalize;
        margin: 4px;
    }

    .emp-completeBtn {
        border-radius: 3px;
        padding: theme-spacing(.5px) theme-spacing(1px);
        text-transform: capitalize;
        margin: 4px;
        color: $white-color;
        background-color: #b90808
    }
}