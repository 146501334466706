//** merchantTree **//
#merchantSettingsMerchantTree {

    .emp-dataContainer {
        // Do not remove. It's a hackish fix for an issue with the AG Grid tree view component
        // and the auto height we do. Maybe something they'll fix in an upcoming version? Who knows...
        max-height: 1px;

        @include theme-breakpoint('down', 960) {
            max-height: unset;
        }
    }

    .emp-agGrid {
        & .ag-header {
            border-bottom: none;
        }
    }

    .emp-zoomPlusIcon {
        color: #666;
        margin-left: theme-spacing(0.5px);

        &:hover {
            cursor: pointer;
        }
    }

    .emp-merchantWithChildren {
        font-weight: 600;
    }

}