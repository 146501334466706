.emp-notFound-root {
    height: 100vh;
}

.emp-notFound-header {
    min-height: 10vh;
    padding: theme-spacing(4px);
    display: flex;
    justify-content: center;
}

.emp-notFound-gridContainer {
    min-height: 90vh;
    width: 70vw;
    margin: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.emp-notFound-title {
    text-align: center;
}

.emp-notFound-logoLink {
    text-decoration: none;
}