//** Reset Password **//
#resetPassword {
    .emp-container {
        font-family: $auth-font-family;
    }

    .emp-resetContainer {
        background: $white-color;
        width: 446px;
        padding: 10px 40px 60px;
    }

    .emp-inputs {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &>* {
            margin-bottom: 10px;
            font-family: $auth-font-family;
        }

        & .MuiInputBase-input {
            font-size: 20px;
            padding: 4px 12px;
            font-family: $auth-font-family;
            color: #535759;
        }
    }

    .emp-btn {
        padding: 1px 10px;
        min-width: 153px;
        font-weight: 600;
        border-radius: 0px 0px 5px 5px;
        font-size: 24px;
        font-family: $auth-font-family;
        text-transform: capitalize;
        box-shadow: none;
    }

    .emp-circularProgress {
        width: 24px !important;
        height: 24px !important;
        vertical-align: middle;
        color: $white-color;
        margin: 9px 0px;
    }

    .emp-link {
        color: #c1c1c1;
        font-size: 11px;
        text-decoration: none;
    }

    .emp-textField {
        margin-bottom: 15px;
    }

    .emp-textInput {
        font-size: 14px;
        color: #57595f;
        cursor: default;
    }
}