.emp-multiBarChart-loadingContainer {
    height: 125px;
    text-align: center;
    margin-top: 150px;
}

.emp-multiBarChart-tooltipContainer {
    background-color: $white-color;
    border: 0.25px solid #cccc;
    opacity: .9;
    box-shadow:
        0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%), 0 1px 3px 0 rgb(0 0 0 / 20%);
}

.emp-multiBarChart-tooltipTableHeader {
    font-weight: 700;
    text-align: left;
    color: white;
    padding: 0px 4px;
}

.emp-multiBarChart-tooltipTableRow {
    border-top: 1px solid #b3b3b3;
    padding: 0px 4px;
}