.emp-agDataGridSelectCellEditor-root {
    max-width: 300px;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    margin: 5px 0;
}

.emp-agDataGridSelectCellEditor-searchField {
    padding: 0 5px;

}

.emp-agDataGridSelectCellEditor-defaultText {
    padding: 4px 8px;

}

.emp-agDataGridSelectCellEditor-loadingContainer {
    padding-left: 12px;
    margin: 5px 0;
}

.emp-agDataGridSelectCellEditor-options {
    list-style: none;
    max-width: 300px;
    padding: 0;
    font-size: 14px;

    &>li {
        padding: 4px 8px;
    }

    max-height: 275px;
    overflow-y: auto;
    margin-top: 0;
    margin-bottom: 0;
}

.emp-agDataGridSelectCellEditor-minSearchCharText {
    @extend .emp-agDataGridSelectCellEditor-defaultText;
}

.emp-agDataGridSelectCellEditor-noResultsText {
    @extend .emp-agDataGridSelectCellEditor-defaultText;
}

.emp-agDataGridSelectCellEditor-paginationRoot {
    .emp-agDataGridSelectCellEditor-paginationContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: theme-spacing(3px);
        margin: theme-spacing(.75px) theme-spacing(4px);
    }

    .emp-agDataGridSelectCellEditor-pageSize {
        &>select {
            width: 50px;
            padding: theme-spacing(.5px);
            border: 1px solid #dcdcdc;
            text-align: center;
        }
    }

    .emp-agDataGridSelectCellEditor-pageInfo {
        display: flex;
        align-items: center;
        column-gap: theme-spacing(1.5px);


        .emp-agDataGridSelectCellEditor-pageResultsText {
            &>span {
                font-weight: 700;
            }
        }

        .emp-agDataGridSelectCellEditor-pageNav {
            display: flex;
            align-items: center;
            column-gap: theme-spacing(2px);

            &>div {
                font-size: 16px;
            }

            .emp-agDataGridSelectCellEditor-iconActive {
                &:hover {
                    cursor: pointer;
                }
            }

            .emp-agDataGridSelectCellEditor-iconInactive {
                opacity: 0.5;
            }
        }
    }
}