//** Issuer Docs **//
#chargebacksIssuerDocs {
    .emp-issuerDocsContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        max-width: 1000px;
    }

    .emp-issuerDocBtn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 5px;
        padding: 5px;

        &:hover {
            background-color: #e2f4fd;
        }
    }

    .emp-issuerDocIcon {
        width: 20px;
        height: 20px;
        margin-top: 5px;
    }
}