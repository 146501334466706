//** Upload Template Library **//
#templateLibraryUploadTemplate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .emp-loadingIndicator {
        min-height: 300px;
        display: flex;
        justify-content: center;
        padding-top: 74px;
    }

    .emp-notSelfServiceContainer {
        width: 90%;
        display: flex;
        flex-direction: column;
        column-gap: theme-spacing(1.5px);
        row-gap: theme-spacing(1.5px);
        justify-content: center;
        align-items: center;
    }

    .emp-fileInfo {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: theme-spacing(.75px);
        row-gap: theme-spacing(.75px);
    }

    .emp-fileDnDNotSelfService {
        width: 100%;
    }

    .emp-fileDnD {
        width: 90%;
    }

    .emp-uploadIcon {
        margin-right: 5px;
        font-size: 20px;
    }

    .emp-circularProgress {
        width: 15px !important;
        height: 15px !important;
        vertical-align: middle;
        color: $white-color;
    }
}