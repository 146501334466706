//** Case Reporting By Cycle Grid **//
#monitoringCaseReportingByCycleGrid {
    & .ag-root-wrapper-body {
        height: 325px;
    }

    & .ag-ltr .ag-cell {
        text-align: center;
        border-right: 1px solid #ccc;
    }
}