//** Alerts Auto Refund Dialog **//
#preChargebacksAGAlertsAutoRefundDialog {
    .emp-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 24px;
        font-size: 15px;
        font-weight: 700;
        color: #666;
        border-bottom: 1px solid #e3e3e3;

        & button {
            float: right;

            & svg {
                color: #ccc;
            }
        }
    }

    .emp-content {
        width: 100%;
    }
}