.emp-bulkClose-root {
    display: flex;
    flex-direction: column;
    row-gap: theme-spacing(2px);
}

.emp-bulkClose-label {
    font-weight: 600;
    margin-bottom: 6px;
}

.emp-bulkClose-criteriaContainer {

    .emp-bulkClose-selectors {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 8px;
    }
}

.emp-bulkClose-appliedCriteriaContainer {

    .emp-bulkClose-badges {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        column-gap: theme-spacing(1.5px);
        min-height: 40px;
    }
}


.emp-bulkClose-actionsContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: solid 1px lightgray;
    padding-top: 20px;

    .emp-bulkClose-statusText {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        color: #666;
        font-size: 12px;
    }

    .emp-bulkClose-jobSubmittedMessage {
        color: #666;
        font-size: 14px;
    }
}


.emp-bulkClose-badgeRoot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: theme-spacing(1.5px);
    border-radius: 25px;
    padding: theme-spacing(.75px) theme-spacing(1.5px);
    border: solid 1px rgb(98, 194, 234);


    .emp-bulkClose-badgeText {

        &>span {
            font-weight: 600;
        }
    }

    .emp-bulkClose-badgeCloseIcon {
        cursor: pointer;
    }
}