#caseMgmtAGNewToolAlert {
    .emp-dialogTitle {
        padding: 0px;
        font-size: 24px;
        font-weight: 600;
        color: #546e7a;
    }

    .emp-modalHeader {
        text-align: center;
    }

    .emp-modalClose {
        position: absolute;
        top: 7px;
        right: 9px;
        cursor: pointer;
        font-size: 15px;
        color: #546e7a;
    }

    .emp-dialogContent {
        padding: 20px !important;
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        min-height: 130px;
    }

    .emp-modalTitle {
        text-align: center;
        color: #012834;
        font-size: 18px;
        font-weight: 700;
    }

    .emp-modalMessage {
        text-align: center;
        color: #616161;
        font-size: 14px;
    }

    .emp-dialogActions {
        padding: 20px;
        padding-top: theme-spacing(1.5px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        column-gap: theme-spacing(1px);
        row-gap: theme-spacing(1.5px);
    }

    .emp-btnsContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: theme-spacing(1px);
    }

    .emp-button {
        box-sizing: content-box;
    }

    .emp-primaryBtn {
        background-color: #ff0000;
        text-transform: none;

        &:hover {
            background-color: #e20000;
        }
    }

    .emp-stepper {
        margin-left: 0px !important;
        background-color: transparent;

        & .MuiMobileStepper-dotActive {
            background-color: #ff0000;
        }

        & .MuiMobileStepper-dots {
            column-gap: theme-spacing(1px);
        }
    }
}

.emp-caseMgmtAGNewToolAlert-modalContainer {
    & .MuiDialog-paperFullWidth {
        width: 550px;
    }
}