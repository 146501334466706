//** Flag Cell **//
#reconciliationReportFlagCell {
    .emp-actionIcon {
        padding: 3px;
        margin: 0;
    }

    .emp-disabledIcon {
        @include disable-icon;
        cursor: default;
    }
}

//** Actions Cell **//
#reconciliationReportActionsCell {
    .emp-tooltipPopper {
        background: #020202;
        color: $white-color;
    }

    .emp-tooltipPopperArrow {
        &::before {
            background: #020202;
        }
    }
}

//** Percent Value Cell **//
#reconciliationReportPercentValueCell {}