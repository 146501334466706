//** Filter Alerts **//
#alertsAGFilterAlerts {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0px 5px;

    @include theme-breakpoint('down', 1715) {
        justify-content: center;
    }


    .emp-filterLabel {
        font-size: .90rem;
        color: #797979;
        font-weight: 600;
        margin-right: 8px;
    }

    .emp-filterSortContainer {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        flex-wrap: wrap;

        @include theme-breakpoint('down', 1715) {
            justify-content: center;
        }
    }

    .emp-inputContainer {
        min-width: 185px;
        margin: 2px;
    }

    .emp-dateRangePicker {
        width: 200px;
        margin: 2px;

    }
}