//** Ert Actions **//
#ertAGErtActions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    column-gap: theme-spacing(1.5px);
    row-gap: theme-spacing(1.5px);

    @include theme-breakpoint('down', 865) {
        justify-content: center;
        flex-wrap: wrap;
    }

    .emp-filtersContainer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: theme-spacing(0.5px);
        row-gap: theme-spacing(0.5px);
        flex: 1;

        @include theme-breakpoint('down', 865) {
            justify-content: center;
        }

        &>label {
            font-size: .90rem;
            color: #797979;
            font-weight: 600;
            margin-left: theme-spacing(1px);
            margin-right: theme-spacing(1px);
        }
    }

    .emp-inputContainer {
        width: 150px;
        min-width: 150px;
        max-width: 185px;
        flex: 1;
    }

    .emp-resetBtn {
        font-size: 14px;
        margin-left: -3px;
        padding: 5px;
    }

    .emp-dateRangePicker {
        width: 185px;
        min-width: 185px;
        max-width: 200px;
        flex: 1;
    }
}