.emp-sortComponent-root {
    display: flex;
    justify-content: center;
    align-items: center;

    & > p {
        margin-right: 8px;
        padding: 0;
    }
}
.emp-sortComponent-sortIcon {
    width: 15px;
    height: 15px;
    margin-right: 0;
}
